import React, { useCallback, useEffect, useState, useRef } from "react";
import { CardContainer, RowContainer } from "../global/containers";
import { FloatingActionButton } from "../global/button";
import { FormInput, FormSelect } from "../global/form";
import { InputAdornment } from "@mui/material";
import { Add, Search } from "@mui/icons-material";
import {
	CustomDataGrid,
	CustomDataGridActionsDropdownMenu,
} from "../global/customTable";
import AddNewStationModal from "../modals/addNewStationModal";
import AddNewSwapStationForm from "../forms/addNewSwapStationForm";
import EditStationModal from "../modals/editStationModal";
import ConfirmationModal from "../modals/confirmationModal";
import { useNavigate } from "react-router-dom";
import {
	deleteSwapStation,
	listSwapStation,
	addSwapStation,
} from "../../services/swapStation";
import { swappingStationMetrics } from "../../services/metricsService";
import { Typography } from "@mui/material";
import theme from "../../theme/theme";

export default function SwapListCard({
	title = "All Swap Stations",
	height = "75vh",
	width = "100%",
	showTimeRange = true,
	showAddButton = true,
	showSearch = true,
	customColumns,
	onRowClick,
}) {
	const navigator = useNavigate();
	const searchRef = useRef();
	const [addModalOpen, setAddModalOpen] = useState(false);
	const [editModalOpen, setEditModalOpen] = useState(false);
	const [stationToEdit, setStationToEdit] = useState(null);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [stationToDelete, setStationToDelete] = useState(null);

	const [isLoading, setIsLoading] = useState(false);
	const [swapStationsData, setSwapStationsData] = useState({});
	const [filteredSwapStations, setFilteredSwapStations] = useState([]);
	const [timeRange, setTimeRange] = useState("month");

	const toggleModalAddModal = useCallback(() => {
		setAddModalOpen((prevState) => !prevState);
	}, []);

	const toggleModalEditModal = useCallback(() => {
		setEditModalOpen((prevState) => !prevState);
	}, []);

	const swapListColumns = customColumns || [
		{
			field: "name",
			headerName: "Swap Station Name",
			minWidth: 200,
			flex: 1,
		},
		{
			field: "total_batteries",
			headerName: "Battery Inventory Count",
			minWidth: 200,
			flex: 1,
			valueFormatter: (value) => `${value} batteries`,
		},
		{
			field: "batteries_swapped",
			headerName: "Swap Count",
			minWidth: 150,
			flex: 1,
			valueFormatter: (value) => `${value} swaps`,
		},
		{
			field: "revenue",
			headerName: `${
				timeRange.charAt(0).toUpperCase() + timeRange.slice(1)
			}'s Revenue`,
			minWidth: 150,
			flex: 1,
			valueFormatter: (value) => `${value} KES`,
		},

		{
			field: "actions",
			headerName: "Actions",
			width: 100,
			renderCell: (params) => (
				<CustomDataGridActionsDropdownMenu
					width="100px"
					menuOptions={[
						{
							label: "View",
							onClickHandler: () =>
								navigator(`/swap-station/${params.row.id}`, {
									state: { swapStation: params.row },
								}),
						},
						{
							label: "Edit",
							onClickHandler: () => editStation(params.row),
						},
						{
							label: "Delete",
							onClickHandler: () => handleDelete(params.row),
						},
					]}
				/>
			),
		},
	];

	const fetchSwapStationList = useCallback(async function (range) {
		setIsLoading(true);
		try {
			const res = await swappingStationMetrics(range);
			setSwapStationsData(res);
			setFilteredSwapStations(res.stations);
		} catch (error) {
			console.error("Error fetching swap stations:", error);
			setSwapStationsData({});
			setFilteredSwapStations([]);
		} finally {
			setIsLoading(false);
		}
	}, []);

	useEffect(() => {
		fetchSwapStationList(timeRange);
	}, [fetchSwapStationList, timeRange]);

	const editStation = useCallback((data) => {
		setStationToEdit(data);
		setEditModalOpen(true);
	}, []);

	const handleDelete = useCallback((swapStation) => {
		setStationToDelete(swapStation);
		setDeleteModalOpen(true);
	}, []);

	const confirmDelete = useCallback(() => {
		if (stationToDelete?.id) {
			deleteSwapStation(stationToDelete.id)
				.then(() => {
					setFilteredSwapStations((prevList) =>
						prevList.filter(
							(station) => station.id !== stationToDelete.id
						)
					);
					setDeleteModalOpen(false);
					setStationToDelete(null);
				})
				.catch((err) => {
					console.error("Error deleting swap station:", err);
				});
		}
	}, [stationToDelete]);

	const handleEditSuccess = useCallback(
		(updatedStation) => {
			setFilteredSwapStations((prevList) =>
				prevList.map((station) =>
					station.id === updatedStation.id ? updatedStation : station
				)
			);
			fetchSwapStationList(timeRange);
		},
		[fetchSwapStationList, timeRange]
	);

	const handleAddSuccess = useCallback(() => {
		fetchSwapStationList(timeRange);
	}, [fetchSwapStationList, timeRange]);

	const filter = useCallback(
		(ev) => {
			const param = ev.target.value.toLowerCase();
			if (!param) {
				setFilteredSwapStations(swapStationsData.stations);
				return;
			}
			const filters = (station) =>
				station.name.toLowerCase().includes(param) ||
				station.batteries_swapped.toString().includes(param) ||
				station.revenue.toString().includes(param) ||
				station.total_batteries.toString().includes(param);

			const formattedData = swapStationsData.stations.filter(filters);
			setFilteredSwapStations(formattedData);
		},
		[swapStationsData]
	);

	const handleTimeRangeChange = useCallback(
		(event) => {
			const newTimeRange = event.target.value;
			setTimeRange(newTimeRange);
			fetchSwapStationList(newTimeRange);
		},
		[fetchSwapStationList]
	);

	return (
		<CardContainer
			title={title}
			type="actionable"
			hasActionableComponent={true}
			height={height}
			width={width}
			actionableComponent={
				<RowContainer
					width={{ xs: "90%", sm: "70%", lg: "50%" }}
					padding="0px"
					marginBottom="0px"
					justifyContent="flex-end"
					alignItems="center"
					extraSX={{
						gap: "16px",
						marginRight: "16px",
					}}
				>
					{showTimeRange && (
						<RowContainer
							width="auto"
							padding="0px"
							marginBottom="0px"
							justifyContent="flex-end"
							alignItems="center"
							extraSX={{
								gap: "8px",
							}}
						>
							<Typography
								variant="body2"
								sx={{
									color: theme.palette.secondary.main,
									fontWeight: 600,
									fontSize: "0.875rem",
									whiteSpace: "nowrap",
								}}
							>
								Select Time Range:
							</Typography>
							<FormSelect
								value={timeRange}
								onChange={handleTimeRangeChange}
								options={["day", "week", "month"]}
								width="120px"
								placeholder="Select time range"
							/>
						</RowContainer>
					)}
					{showAddButton && (
						<FloatingActionButton
							icon={<Add color="secondary" />}
							onclick={toggleModalAddModal}
							width="auto"
						/>
					)}
					{showSearch && (
						<FormInput
							ref={searchRef}
							placeholder={"Search Swap Station"}
							type="text"
							width={{
								xs:
									showTimeRange && showAddButton
										? "calc(100% - 176px)"
										: "100%",
								md:
									showTimeRange && showAddButton
										? "calc(80% - 176px)"
										: "80%",
							}}
							backgroundColor="#FFFFFF"
							endAdornment={
								<InputAdornment position="end">
									<Search color="#737373" />
								</InputAdornment>
							}
							extraSX={{
								marginRight: "16px",
							}}
							onChange={filter}
						/>
					)}
				</RowContainer>
			}
		>
			<CustomDataGrid
				isLoading={isLoading}
				columns={swapListColumns}
				rows={filteredSwapStations}
				enableCheckboxSelection={false}
				isEmptyMessage="There are no swap stations to show."
				width="100%"
				height="100%"
				onRowClick={onRowClick}
			/>
			<AddNewStationModal
				toggleModal={toggleModalAddModal}
				modalOpen={addModalOpen}
				FormComponent={AddNewSwapStationForm}
				addStationService={addSwapStation}
				successMessageText="Swap Station added successfully!"
				title="Add New Swap Station"
				actionButtonText="Add Swap Station"
				onSuccess={handleAddSuccess}
			/>
			<EditStationModal
				modalOpen={editModalOpen}
				toggleModal={toggleModalEditModal}
				station={stationToEdit}
				onSuccess={handleEditSuccess}
				type="swap"
			/>
			<ConfirmationModal
				modalOpen={deleteModalOpen}
				toggleModal={() => setDeleteModalOpen(false)}
				onConfirmation={confirmDelete}
				itemType="Swap Station"
				itemName={stationToDelete?.name ?? "Unknown Station"}
				confirmType="delete"
			/>
		</CardContainer>
	);
}
