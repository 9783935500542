import React, { useCallback, useEffect, useState } from "react";
import { CardContainer, RowContainer } from "../global/containers";
import { FloatingActionButton } from "../global/button";
import { Add } from "@mui/icons-material";
import { SearchInput } from "../global/form";
import {
	CustomDataGrid,
	CustomDataGridActionsDropdownMenu,
} from "../global/customTable";
import AddNewBatteryAssetModal from "../modals/addNewBatteryAssetModal";
import { useNavigate } from "react-router-dom";
import { deleteBattery, killBatterySMS, listBatteries } from "../../services/batteryService";
import { handleErrorResponse } from "../../util/util";
import EditBatteryAssetInfoModal from "../modals/editBatteryAssetInfoModal";
import ConfirmationModal from "../modals/confirmationModal";

export default function BatteryListCard() {
	const navigator = useNavigate();

	const [AddmodalOpen, setAddModalOpen] = useState(false);
	const [editmodalOpen, setEditmodalOpen] = useState(false);
	const [batteryToEdit, setBatteryToEdit] = useState();

	const [batteryList, setBatteryList] = useState([]);
	const [filteredBatteryList, setFilteredBatteryList] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [disableModalOpen, setDisableModalOpen] = useState(false);
	const [batteryToDelete, setBatteryToDelete] = useState();
	const [batteryToDisable, setBatteryToDisable] = useState();

	const STATUS = {
		chg: "Charging",
		dch: "Discharging",
		idd: "Idle",
		del: "Deleted",
	};

  const batteryListColumns = [
    {
      field: "batterySerial",
      headerName: "Battery Serial No",
      width: 100,
      flex: 1,
    },
    {
      field: "chargePercentage",
      headerName: "Charge Percentage",
      minWidth: 70,
      flex: 0.5,
    },
    {
      field: "cycles",
      headerName: "Cycles",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "currentStatus",
      headerName: "Current Status",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <CustomDataGridActionsDropdownMenu
          menuOptions={[
            {
              label: "View",
              onClickHandler: () => navigator(`/batteries/${params.row.batterySerial}`),
            },
            {
              label: "Edit",
              onClickHandler: () => editBatteryInfo(params.row)
            },
            {
              label: "Delete",
              onClickHandler: () => handleDeleteClick(params.row)
            },
            {
              label: "Disable",
              onClickHandler: () => handleBatteryDisable(params.row)
            },
          ]}
        />
      ),
    },
  ];

	const toggleAddModal = useCallback(
		async function () {
			setAddModalOpen(!AddmodalOpen);
		},
		[AddmodalOpen]
	);

	const toggleEditModal = useCallback(() => {
		setEditmodalOpen(!editmodalOpen);
	}, [editmodalOpen]);

  const fetchBatteryList = useCallback(async function () {
    setIsLoading(true);
    listBatteries()
      .then((res) => {
        const formatedData = res.map((batteryItem)=>{
          return {
            id: batteryItem.id,
            batterySerial: batteryItem.bat_sn,
            chargePercentage: batteryItem.data.pct,
            cycles: batteryItem.data.cycles ?? 0,
            currentStatus: STATUS[batteryItem.state],
            ...batteryItem
          }
        })
        setBatteryList(formatedData)
        setFilteredBatteryList(formatedData)
      })
      .catch(() => setBatteryList([]))
      .finally(() => setIsLoading(false))
  }, []);

	const handleBatteryDisable = (battery) => {
		setBatteryToDisable(battery)
		setDisableModalOpen(true)
	}

  const disableBattery = () => {
    killBatterySMS(batteryToDisable?.id)
			.finally(() => setDisableModalOpen(false))
  }

	useEffect(() => {
		if (editmodalOpen || AddmodalOpen) {
			return;
		}
		fetchBatteryList();
	}, [AddmodalOpen, editmodalOpen, fetchBatteryList, toggleAddModal]);

	const handleDelete = () => {
		deleteBattery(batteryToDelete.id)
			.then((res) => {
				const filteredList = batteryList.filter(
					(value) => value.id !== batteryToDelete?.id
				);
				setBatteryList(filteredList);
				setFilteredBatteryList(filteredList);
				setDeleteModalOpen(false);
			})
			.catch((err) => {
				console.log(err);
				handleErrorResponse(err);
			});
	};

	const handleDeleteClick = (battery) => {
		setBatteryToDelete(battery);
		setDeleteModalOpen(true);
	};

	const editBatteryInfo = (data) => {
		setBatteryToEdit(data);
		toggleEditModal();
	};

	const filter = (ev) => {
		const param = ev.target.value.toLowerCase();
		if (!param) {
			setFilteredBatteryList(batteryList);
			return;
		}
		const filters = (batteryItem) =>
			batteryItem.batterySerial.includes(param) ||
			batteryItem.currentStatus.toLowerCase().includes(param) ||
			batteryItem.cycles.toString().includes(param) ||
			batteryItem.chargePercentage.toString().includes(param);
		const formattedData = batteryList.filter(filters);
		setFilteredBatteryList(formattedData);
	};

	return (
		<>
			<CardContainer
				title="All Battery Assets"
				type="actionable"
				hasActionableComponent={true}
				height="75vh"
				width="100%"
				childrenContainerWidth="98%"
				actionableComponent={
					<RowContainer
						width={{ xs: "80%", sm: "50%", lg: "30%" }}
						padding="0px"
						marginBottom="0px"
					>
						<FloatingActionButton
							icon={<Add color="secondary" />}
							onclick={() => toggleAddModal()}
						/>
						<SearchInput
							placeholder={"Search"}
							type="text"
							width={{ xs: "100%", md: "80%" }}
							backgroundColor="#FFFFFF"
							useRef
							filter={filter}
						/>
					</RowContainer>
				}
			>
				<CustomDataGrid
					isLoading={isLoading}
					columns={batteryListColumns}
					rows={filteredBatteryList}
					height="100%"
					width="100%"
					isEmptyMessage="There are no battery assets to show"
				/>

				<AddNewBatteryAssetModal
					toggleModal={toggleAddModal}
					modalOpen={AddmodalOpen}
				/>
				<EditBatteryAssetInfoModal
					modalOpen={editmodalOpen}
					toggleModal={toggleEditModal}
					battery={batteryToEdit}
				/>
			</CardContainer>
			<ConfirmationModal
				modalOpen={deleteModalOpen}
				toggleModal={() => setDeleteModalOpen(false)}
				itemType={"Battery"}
				itemName={batteryToDelete?.batterySerial}
				onConfirmation={handleDelete}
				confirmType={"delete"}
			/>

			<ConfirmationModal
				modalOpen={disableModalOpen}
				toggleModal={() => setDisableModalOpen(false)}
				itemType={"Battery"}
				itemName={batteryToDisable?.batterySerial}
				onConfirmation={disableBattery}
				confirmType={"disable"}
			/>
		</>
	);
}
