import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { ColumnContainer } from '../global/containers'
import { AutoCompleteInput, FormInput } from '../global/form'

function AddNewBatteryAssetForm({batteryData, task}, ref) {
    const telRef = useRef();
    const batModel = useRef();
    const batType = useRef();
    const batterySNRef = useRef();
    const [isError, setIsError] = useState(false);
    const [serialError, setSerialError] = useState("");

    useImperativeHandle(ref, () => ({getData}))

    const getData = () => {
        let selectedBatteryModel = null;
        let selectedBatteryType = null;

        try {
            if (batModel?.current?.getState()) {
                selectedBatteryModel = batModelOptions.find(option => option?.label === batModel?.current?.getState());
            }
            if (batType?.current?.getState()) {
                selectedBatteryType = batTypeOptions.find(option => option?.label === batType?.current?.getState());
            }
        } catch (error) {
            setIsError(true)
        }

        if (!selectedBatteryModel || !selectedBatteryType) {
            setIsError(true)
            return null;
        }

        const batSNValue = batterySNRef?.current?.getState() ?? "";
        if(batSNValue) {
            if (batSNValue.includes(" ")) {
                setSerialError("Serial number cannot contain spaces.")
                return null;
            } else if(batSNValue.length < 6) {
                setSerialError("Serial number must be at least 6 characters long.")
                return null;
            } else if(batSNValue.length > 16) {
                setSerialError("Serial number cannot be longer than 16 characters.")
                return null;
            } else if(!/^[a-zA-Z0-9]+$/.test(batSNValue)) {
                setSerialError("Serial number can only contain letters and numbers.")
                return null;
            } else if(batSNValue[0] === "0") {
                setSerialError("Serial number cannot start with a zero.")
                return null;
            }
        }

        setIsError(false)
        setSerialError("")

        return {
            ...batteryData,
            tel: telRef.current?.getState() ?? "",
            bat_sn: task !== 'edit' ? batterySNRef?.current?.getState() ?? "" : batteryData?.bat_sn ?? "",
            battery_model: selectedBatteryModel?.value ?? 0,
            battery_model_name: selectedBatteryModel?.label ?? "",
            battery_type: selectedBatteryType?.value ?? 0,
            battery_type_name: selectedBatteryType?.label ?? ""
        }
    }

    const batModelOptions = [
        {
            value: 1,
            label: 'G3'
        },
        {
            value: 2,
            label: 'G5'
        }
    ]

    const batTypeOptions = [
        {
            value: 1,
            label: 'Lead-Acid'
        },
        {
            value: 2,
            label: 'Li-Ion'
        }
    ]


    return (
        <ColumnContainer
            justifyContent='start'
            padding='0px'
        >
            {task !== 'edit' && (
                <FormInput
                    ref={batterySNRef}
                    label='Battery Serial Number*'
                    name={'batteryVin'}
                    placeholder={"Enter Battery Serial Number"}
                    type="text"
                    required={true}
                    value={batteryData?.bat_sn ?? ''}
                    error={serialError || (isError && !batterySNRef.current?.getState() ? "This field is required." : "")}
                />
            )}
            <AutoCompleteInput
                ref={batModel}
                required={true}
                label='Battery Model*'
                placeholder={'Enter Battery Model'}
                options={batModelOptions.map((value) => value)}
                value={batModelOptions.find((option) => option.value === batteryData?.battery_model)?.label ?? ''}
                error={isError && !batModel.current?.getState() ? "This field is required." : ""}
            />
            <AutoCompleteInput
                ref={batType}
                required={true}
                label='Battery Type*'
                placeholder={'Enter Battery Type'}
                options={batTypeOptions.map((value) => value)}
                value={batTypeOptions.find((option) => option.value === batteryData?.battery_type)?.label ?? ''}
                error={isError && !batType.current?.getState() ? "This field is required." : ""}
            />
            <FormInput
                ref={telRef}
                label='Telephone Number*'
                name={'inpectDate'}
                placeholder={"Enter Telephone number"}
                type="number"
                required={true}
                value={batteryData?.tel ?? ''}
                error={isError && !telRef.current?.getState() ? "This field is required." : ""}
            />
        </ColumnContainer>
    )
}

export default forwardRef(AddNewBatteryAssetForm)