import React from 'react'
import { ColumnContainer } from '../components/global/containers'
import BikeAssetStatistics from '../components/cards/bikeAssetStatistics'
import TopBar from '../components/global/topBar'
import BikeListCard from '../components/cards/bikeListCard'
import BackButton from '../components/global/backButton'

export default function MotorBikeListPage() {
  return (
    <ColumnContainer>
      <TopBar>
      <BackButton/>
      </TopBar>
      <BikeAssetStatistics />
      <BikeListCard/>
    </ColumnContainer>
  )
}
