import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { FormInput, FormSelect } from '../global/form';
import { ColumnContainer, RowContainer } from '../global/containers';

const USER_ROLES = {
    Admin: 0,
    Manager: 16
}

function AddUserForm({  User },ref) {

    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const userTypeRef = useRef();
    const addressRef = useRef();
    const phoneRef = useRef();
    const emailRef = useRef();

    const [errors, setErrors] = useState();

    const getData = () =>{
        if(!validate()){
            return null;
        }
        return {
            ...User,
            first_name: firstNameRef.current.getState(),
            last_name: lastNameRef.current.getState(),
            username: emailRef.current.getState(),
            phone_number: phoneRef.current.getState(),
            address: addressRef.current.getState(),
            email_address:emailRef.current.getState(),
            user_type: USER_ROLES[userTypeRef.current.getState()]
        }
    }

    const validate = () => {
        const errs = {};
        const phoneRegx = /^\+254(7|1)\d{8}$/;

        if (!firstNameRef.current?.getState()) {
            errs.firstName = "Fist Name is required";
        }
        if (!lastNameRef.current?.getState()) {
            errs.lastName = "Last name is required";
        }
        if (!phoneRef.current?.getState()) {
            errs.Phone = "Phone Number is required";
        } else if (!phoneRegx.test(phoneRef.current?.getState())) {
            errs.Phone = "In valid Kenyan phone number";
        }
        if (!emailRef.current?.getState()) {
            errs.emails = "Email is required";
        }
        if (!userTypeRef.current?.getState()) {
            errs.userType = "User type is required";
        }
        if (!addressRef.current?.getState()) {
            errs.address = "Address is required";
        }
        setErrors(errs)
        return Object.keys(errs).length === 0;
    }

    useImperativeHandle(ref, () => ({getData}))

    return (
        <ColumnContainer
            justifyContent='start'
            padding='0px'
            alignItems="flex-start"
        >
            <RowContainer
                justifyContent='space-between'
                marginBottom='0px'
                padding='10px 0px'
            >
                <FormInput
                    ref={firstNameRef}
                    required={true}
                    label='First Name *'
                    width={'49%'}
                    placeholder={"Enter First Name"}
                    type="text"
                    value={User?.first_name}
                    error={errors?.firstName}
                />
                <FormInput
                    ref={lastNameRef}
                    required={true}
                    label='Last Name *'
                    width={'49%'}
                    placeholder={"Enter Last Name"}
                    type="text"
                    value={User?.last_name}
                    error={errors?.lastName}
                />
            </RowContainer>
            <RowContainer
                justifyContent='space-between'
                marginBottom='0px'
                padding='10px 0px'
            >
                <FormInput
                    ref={phoneRef}
                    required={true}
                    label='Phone Number *'
                    width={'49%'}
                    placeholder='Enter Phone Number'
                    value={User?.phone_number}
                    error={errors?.Phone}
                />
                <FormInput
                    ref={emailRef}
                    required={true}
                    label='Email Address *'
                    width={'49%'}
                    placeholder={"Enter Email Address"}
                    type="text"
                    value={User?.email_address}
                    error={errors?.emails}
                />
            </RowContainer>
            <RowContainer
                justifyContent='space-between'
                marginBottom='0px'
                padding='10px 0px'
            >
                <FormSelect
                    ref={userTypeRef}
                    required={true}
                    label='User Type *'
                    width={'49%'}
                    placeholder='Select User Type'
                    options={['Admin', 'Manager']}
                    value={User?.user_type} 
                    error={errors?.userType}
                />
                <FormInput
                    ref={addressRef}
                    required={true}
                    label='Address *'
                    width={'49%'}
                    placeholder={"Enter Address"}
                    type="text"
                    value={User?.address}
                    error={errors?.address}
                />
            </RowContainer>
        </ColumnContainer>
    )
}

export default forwardRef(AddUserForm)
