import React, {
	forwardRef,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import { ColumnContainer, RowContainer } from "../global/containers";
import { FormInput, WorkingScheduleInput } from "../global/form";
import { CustomText } from "../global/text";

function AddNewChargeStationForm({ station }, ref) {
	const nameRef = useRef();
	const addressRef = useRef();
	const lonRef = useRef();
	const latRef = useRef();
	const capacityRef = useRef();
	const scheduleRef = useRef();

	const [errors, setErrors] = useState({});

	useImperativeHandle(ref, () => ({ getData }));

	const validate = () => {
		const newErrors = {};
		if (!nameRef.current?.getState()) {
			newErrors.name = "Station Name is required.";
		}
		if (!addressRef.current?.getState()) {
			newErrors.address = "Address is required.";
		}
		if (!lonRef.current?.getState()) {
			newErrors.lon = "Longitude is required.";
		} else if (isNaN(parseFloat(lonRef.current.getState()))) {
			newErrors.lon = "Longitude must be a valid number.";
		}
		if (!latRef.current?.getState()) {
			newErrors.lat = "Latitude is required.";
		} else if (isNaN(parseFloat(latRef.current.getState()))) {
			newErrors.lat = "Latitude must be a valid number.";
		}
		if (!capacityRef.current?.getState()) {
			newErrors.capacity = "Capacity is required.";
		} else if (
			isNaN(parseInt(capacityRef.current.getState())) ||
			parseInt(capacityRef.current.getState()) <= 0
		) {
			newErrors.capacity = "Capacity must be a positive integer.";
		}
		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const getData = () => {
		if (validate()) {
			return {
				...station,
				name: nameRef.current?.getState() || "",
				address: addressRef.current?.getState() || "",
				lon: parseFloat(lonRef.current?.getState() || "0"),
				lat: parseFloat(latRef.current?.getState() || "0"),
				capacity: parseInt(capacityRef.current?.getState() || "0"),
				working_hours: scheduleRef.current?.getScheduleData() || {},
			};
		}
		return null;
	};

	return (
		<ColumnContainer alignItems="start" padding="0px">
			<RowContainer
				justifyContent="space-between"
				marginBottom="0px"
				padding="10px 0px"
			>
				<FormInput
					label="Station Name *"
					ref={nameRef}
					required={true}
					width={"47%"}
					placeholder={"Enter Station Name"}
					type="text"
					value={station?.name ?? ""}
					error={errors.name}
				/>
				<FormInput
					ref={addressRef}
					required={true}
					label="Address *"
					width={"47%"}
					placeholder={"Enter Address"}
					type="text"
					value={station?.address ?? ""}
					error={errors.address}
				/>
			</RowContainer>

			<RowContainer
				justifyContent="space-between"
				marginBottom="0px"
				padding="10px 0px"
			>
				<FormInput
					ref={latRef}
					required={true}
					label="Latitude *"
					width={"47%"}
					placeholder={"Enter Latitude"}
					type="number"
					value={station?.lat ?? ""}
					error={errors.lat}
				/>
				<FormInput
					ref={lonRef}
					required={true}
					label="Longitude *"
					width={"47%"}
					placeholder={"Enter Longitude"}
					type="number"
					value={station?.lon ?? ""}
					error={errors.lon}
				/>
			</RowContainer>

			<RowContainer
				justifyContent="space-between"
				marginBottom="0px"
				padding="10px 0px"
			>
				<FormInput
					ref={capacityRef}
					required={true}
					label="Capacity *"
					width={"47%"}
					placeholder={"Enter Station Capacity"}
					type="number"
					value={station?.capacity ?? ""}
					error={errors.capacity}
					min={1}
				/>
				{/* You can add another input field here if needed */}
			</RowContainer>

			<CustomText
				variant="h5"
				fontWeight="700"
				textAlign="start"
				color="secondary"
				text="Operating Hours:"
			/>
			<WorkingScheduleInput
				ref={scheduleRef}
				data={station?.working_hours || {}}
			/>
		</ColumnContainer>
	);
}

export default forwardRef(AddNewChargeStationForm);
