import React, { useEffect, useRef, useState } from "react";
import { CardContainer } from "../global/containers";
import { PieChartItem } from "../global/charts";
import enzi_theme from "../../theme/theme";

export default function BatteryHealthCard() {
  const cardContainerRef = useRef(null);

  const [cardContainerWidth, setCardContainerWidth] = useState(undefined);

  useEffect(() => {
    if (cardContainerRef !== null && cardContainerRef.current !== null) {
      const containerWidth = cardContainerRef.current.getContainerWidth();
      if (!isNaN(containerWidth)) {
        setCardContainerWidth(containerWidth * 0.8);
      }
    }
  }, [cardContainerRef]);

  return (
    <CardContainer
      ref={cardContainerRef}
      title="Battery Health"
      width={{ xs: "100%", lg: "40%" }}
      height={400}
      type="actionable"
      actionableText="See more"
      childrenContainerWidth={"96%"}
      extraSX={{ margin: "0 0" }}
    >
      <PieChartItem
        height={300}
        width={cardContainerWidth}
        dataSeries={[
          { id: 0, value: 105, label: "Healthy" },
          { id: 1, value: 78, label: "Good" },
          {
            id: 2,
            value: 94,
            label: "Okay",
            color: enzi_theme.palette.background.paper,
          },
          {
            id: 3,
            value: 54,
            label: "Service",
            color: enzi_theme.palette.warning.light,
          },
          {
            id: 4,
            value: 32,
            label: "Retire",
            color: enzi_theme.palette.error.light,
          },
        ]}
      />
    </CardContainer>
  );
}
