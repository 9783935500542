import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { ColumnContainer, RowContainer } from '../global/containers'
import { AutoCompleteInput, FormInput } from '../global/form'
import { listAvailableElectricVehicles } from '../../services/electricVehicle'
function AddRiderForm({ rider }, ref) {

    const firstNameRef = useRef()
    const lastNameRef = useRef()
    const phoneN0Ref = useRef()
    const emailRef = useRef()
    const addressRef = useRef()
    const electricVehicleRef = useRef()

    const [vehicleList, setVehicleList] = useState([])
    const [errors, setErrors] = useState({});


    const validate = () => {
        const errs = {};
        const phoneRegx = /^\+254(7|1)\d{8}$/;
        phoneRegx.test("+254712345678")

        if (!firstNameRef.current?.getState()) {
            errs.firstName = "Fist Name is required";
        }
        if (!lastNameRef.current?.getState()) {
            errs.lastName = "Last name is required";
        }
        if (!phoneN0Ref.current?.getState()) {
            errs.Phone = "Phone Number is required";
        } else if (!phoneRegx.test(phoneN0Ref.current?.getState())) {
            errs.Phone = "In valid Kenyan phone number";
        }
        if (!emailRef.current?.getState()) {
            errs.emails = "Email is required";
        }
        if (!addressRef.current?.getState()) {
            errs.adddres = "Address is required";
        }
        setErrors(errs)
        return Object.keys(errs).length === 0;
    }

    const getData = () => {
        if (!validate()) {
            return null;
        }
        return {
            ...rider,
            first_name: firstNameRef.current.getState(),
            last_name: lastNameRef.current.getState(),
            username: emailRef.current.getState(),
            phone_number: phoneN0Ref.current.getState(),
            address: addressRef.current.getState(),
            email_address: emailRef.current.getState(),
            electric_vehicle: vehicleList.filter((value) => value?.registration_number === electricVehicleRef.current?.getState())
        }
    }

    useImperativeHandle(ref, () => ({ getData }))

    const getElectricalVehicles = useCallback(async function () {
        listAvailableElectricVehicles()
            .then(res => {
                setVehicleList(res)
            })
            .catch(err => {
                console.log(err);

            })
    }, [])

    useEffect(() => {
        getElectricalVehicles()
    }, [])

    return (
        <ColumnContainer
            alignItems="start"
            justifyContent='start'
            padding='0px'
            marginBottom='30px'
        >
            <RowContainer
                justifyContent='space-between'
                marginBottom='0px'
                padding='10px 0px'
            >
                <FormInput
                    ref={firstNameRef}
                    required={true}
                    label='First Name *'
                    width={'49%'}
                    placeholder={"Enter First Name"}
                    type="text"
                    value={rider?.first_name}
                    error={errors.firstName}
                />
                <FormInput
                    ref={lastNameRef}
                    required={true}
                    label='Last name *'
                    width={'49%'}
                    placeholder={"Enter Last Name"}
                    type="text"
                    value={rider?.last_name}
                    error={errors.lastName}
                />
            </RowContainer>
            <FormInput
                ref={emailRef}
                required={true}
                label='Email *'
                placeholder={"Enter Email"}
                type="email"
                value={rider?.email_address}
                error={errors.emails}
            />
            <RowContainer
                justifyContent='space-between'
                marginBottom='0px'
                padding='10px 0px'
            >
                <FormInput
                    ref={phoneN0Ref}
                    required={true}
                    label='Phone Number *'
                    width={'49%'}
                    placeholder={"Enter PhoneNumber (+254...)"}
                    type="text"
                    value={rider?.phone_number}
                    error={errors.Phone}
                />

                <FormInput
                    ref={addressRef}
                    required={true}
                    label='Address *'
                    width={'49%'}
                    placeholder={"Enter Address"}
                    type="text"
                    value={rider?.address}
                    error={errors.adddres}
                />
            </RowContainer>
            <AutoCompleteInput
                width={"100%"}
                ref={electricVehicleRef}
                required={true}
                label='Electric Vehicle'
                placeholder={'Enter Electric Vehicle Registration'}
                options={vehicleList.map((value) => value?.registration_number)}
                value={rider?.bike_reg}
            />

        </ColumnContainer>
    )
}

export default forwardRef(AddRiderForm)