import React, { useCallback, useState } from 'react'
import { RowContainer } from './containers'
import { CustomDropdownButton } from './button'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Person } from '@mui/icons-material';
import { DE_AUTHENTICATE_USER } from '../../state/slices/auth';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Drawer, IconButton, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SidebarModule from '../modules/sidebar';

export default function TopBar({
  children,
  marginBottom = '10px'
}) {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const isMobile = useMediaQuery('(min-width:1200px)');


  const toggleSidebar = useCallback(
    async function () {
      console.log(sidebarOpen);
      setSidebarOpen(!sidebarOpen);
    },
    [sidebarOpen]
  );

  const handleLogOut = useCallback(
    function () {
      dispatch(DE_AUTHENTICATE_USER());
      navigate("/");
    },
    [dispatch, navigate]
  );


  return (
    <RowContainer justifyContent="space-between" alignItems='flex-start' marginBottom={marginBottom}>
      <RowContainer padding='0px' alignItems='flex-start' width='fit-content' marginBottom='0px'>
        {!isMobile && 
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={toggleSidebar}
          edge="start"
        >
          <MenuIcon style={{fontSize: '3rem'}} />
        </IconButton>}
        {children}
      </RowContainer>
      <CustomDropdownButton options={[{ title: 'Log Out', action: handleLogOut }]}>
        <RowContainer padding="4px 0px" marginBottom="0px">
          <Person color="secondary" />
          <KeyboardArrowDownIcon color="secondary" />
        </RowContainer>
      </CustomDropdownButton>
      <Drawer open={sidebarOpen}
        onClose={toggleSidebar}
        PaperProps={{ sx: { width: {xs: '80%',md: '34%', lg: '20%'} } }}
      >
        <SidebarModule width='100%' />
      </Drawer>
    </RowContainer>
  )
}
