import React, { useCallback, useEffect, useState } from "react";
import { RowContainer } from "./global/containers";
import { Box, Typography } from "@mui/material";
import enzi_theme from "../theme/theme";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomText } from "./global/text";
import { useDispatch } from "react-redux";
import { DE_AUTHENTICATE_USER } from "../state/slices/auth";

export default function SidebarItem({
  data = {
    label: "SidebarItem",
    uri: "/dashboard",
  },
}) {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  let currentLocation = useLocation();

  function handleNavigate() {
    navigate(data.uri);
  }

  useEffect(() => {
    currentLocation !== undefined && currentLocation.pathname === data.uri
      ? setIsActive(true)
      : setIsActive(false);
  }, [currentLocation, data.uri]);

  return (
    <Box
      onClick={handleNavigate}
      // component={RowContainer}
      width="100%"
      height="auto"
      justifyContent="center"
      alignItems="center"
      marginBottom="10px"
      borderRadius={'5px'}
      sx={{
        backgroundColor: isActive
          ? enzi_theme.palette.common.white
          : enzi_theme.palette.background.paper,
        "#sidebar-label": {
          color: enzi_theme.palette.secondary.main,
        },
        ":hover": {
          backgroundColor: enzi_theme.palette.background.default,
          "#sidebar-label": {
            color: enzi_theme.palette.grey[600],
          },
          "cursor": "pointer"
        },
      }}
    >
      <Typography
        id="sidebar-label"
        variant="body1"
        color="secondary"
        fontWeight={700}
        width={"100%"}
        sx={{
          padding: "10px",
        }}
      >
        {data.label}
      </Typography>
    </Box>
  );
}

export function SidebarLogoutItem() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogOut = useCallback(
    function () {
      dispatch(DE_AUTHENTICATE_USER());
      navigate("/");
    },
    [dispatch, navigate]
  );

  return (
    <Box onClick={handleLogOut} sx={{ width: "100%" }}>
      <RowContainer
        width="auto"
        height="auto"
        justifyContent="center"
        alignItems="center"
        marginBottom="0"
        extraSX={{
          margin: "auto",
          backgroundColor: enzi_theme.palette.error.main,
          "#sidebar-label": {
            color: enzi_theme.palette.common.white,
          },
          ":hover": {
            backgroundColor: enzi_theme.palette.common.white,
            "#sidebar-label": {
              color: enzi_theme.palette.error.main,
            },
          },
        }}
      >
        <CustomText
          id="sidebar-label"
          variant="body1"
          color="white"
          fontWeight={700}
          width={"auto"}
          text="Logout"
          textAlign="center"
          extraSX={{
            margin: "auto",
            padding: "10px",
          }}
        />
      </RowContainer>
    </Box>
  );
}
