import React from 'react'
import { ColumnContainer } from '../components/global/containers'
import StaffManagmentList from '../components/cards/staffManagmentList'
import TopBar from '../components/global/topBar'
import BackButton from '../components/global/backButton'

export default function StaffManagement() {
  return (
    <ColumnContainer>
        <TopBar>
        <BackButton/>
      </TopBar>
        <StaffManagmentList />
    </ColumnContainer>
  )
}
