import {
	CHARGE_STATION,
	CHARGE_STATION_DETAILS,
	CHARGE_STATION_ADD,
	CHARGE_STATION_DEL,
	CHARGE_STATION_EDT,
	CHARGE_STATION_List,
	CHARGE_STATION_ADD_USER,
	CHARGE_STATION_EDT_USER,
	CHARGE_STATION_OPERATIONS,
	CHARGE_STATION_DEL_USER,
	GLOBAL_DISPATCH_OPERATIONS,
} from "../constants/url.constants";
import { issueSuccessMessage } from "../util/util";
import { serviceAPI } from "./service";

export const getChargeStation = async (id) => {
	return serviceAPI
		.get(CHARGE_STATION + `${id}`)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

export const getChargeStationDetails = async (id) => {
	return serviceAPI
		.get(CHARGE_STATION_DETAILS, { params: { station_id: id } })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

export const listChargeStation = async () => {
	return serviceAPI
		.get(CHARGE_STATION_List)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

export const listDispatchOperation = async (id) => {
	return serviceAPI
		.get(CHARGE_STATION_OPERATIONS, { params: { station_id: id } })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

export const deleteChargeStation = async (id) => {
	return serviceAPI
		.delete(CHARGE_STATION_DEL + `${id}`)
		.then((response) => {
			issueSuccessMessage('Charge station deleted successfully');
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

export const addChargeStation = async (data) => {
	return serviceAPI
		.post(CHARGE_STATION_ADD, data)
		.then((response) => {
			issueSuccessMessage('Charge station added successfully');
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

export const editChargeStation = async (data, id) => {
	return serviceAPI
		.put(CHARGE_STATION_EDT + `${id}`, data)
		.then((response) => {
			issueSuccessMessage('Charge station edited successfully');
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

export const addChargeStationUser = async (data) => {
	return serviceAPI
		.post(CHARGE_STATION_ADD_USER, data)
		.then((response) => {
			issueSuccessMessage('Charge station user added successfully');
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

export const editChargeStationUser = async (data, id) => {
	return serviceAPI
		.put(CHARGE_STATION_EDT_USER + `${id}`, data)
		.then((response) => {
			issueSuccessMessage('Charge station user edited successfully');
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

export const deleteChargeStationUser = async (id) => {
	return serviceAPI
		.delete(CHARGE_STATION_DEL_USER, { data: { user_id: id } }) // Pass id in the request body
		.then((response) => {
			issueSuccessMessage('Charge station user deleted successfully');
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

export const getGlobalDispatchOperations = async () => {
	return serviceAPI
		.get(GLOBAL_DISPATCH_OPERATIONS)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
}
