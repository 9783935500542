import React, { useEffect, useState } from "react";
import { RowContainer } from "../global/containers";
import StatItem from "../global/statItem";
import { swappingStationMetrics } from "../../services/metricsService";

export default function SwapStationStatistics() {
	const [loading, setLoading] = useState(false);
	const [activeBatteries, setActiveBatteries] = useState("_");
	const [dailySwapAvg, setDailySwapAvg] = useState("_");
	const [completedSwaps, setCompletedSwaps] = useState("_");
	const [revenue, setRevenue] = useState("_");

	const cards = [
		{ title: "Batteries in Swap Stations", value: activeBatteries },
		{ title: "Daily Battery Swap Average", value: dailySwapAvg },
		{ title: "Swap Operations Completed", value: completedSwaps },
		{ title: "Avg Swap Station Revenue", value: revenue },
	];

	useEffect(() => {
		setLoading(true);
		swappingStationMetrics().then((response) => {
			setActiveBatteries(response?.station_inventory_count);
			setDailySwapAvg(response?.daily_swap_avg);
			setCompletedSwaps(response?.total_batteries_swapped);
			setRevenue(response?.average_station_revenue);
		});
		setLoading(false);
	}, []);

	return (
		<RowContainer
			justifyContent="space-between"
			width="100%"
			extraSX={{ gap: 1, flexWrap: { xs: "wrap", lg: "nowrap" } }}
		>
			{cards.map((card, index) => (
				<StatItem
					key={index}
					width="25%"
					title={card.title}
					loading={loading}
					value={card.value}
				/>
			))}
		</RowContainer>
	);
}
