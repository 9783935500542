import React from "react";
import { ColumnContainer } from "../components/global/containers";
import enzi_theme from "../theme/theme";
import ModalContainerExample from "../components/modals/modalContainer.example";
import BatteryInformationCard from "../components/cards/batteryInformationCard";
import LastInspectionCard from "../components/cards/lastInspectionCard";
import RiderInfoCard from "../components/cards/riderInfoCard";
import BatteryDeploymentHistoryCard from "../components/cards/batteryDeploymentHistoryCard";
import PaymentInfoCard from "../components/cards/paymentInfoCard";
import PastRiderTransactionsCard from "../components/cards/pastRiderTransactionsCard";
import PairedBatteryInformationCard from "../components/cards/pairedBatteryInformationCard";
import RecentBikeTripcards from "../components/cards/recentBikeTripcards";
import BatteryEfficiencyCard from "../components/cards/batteryEfficiencyCard";
import BatteryPowerExpenditurecard from "../components/cards/batteryPowerExpenditurecard";

export default function Docs() {
  return (
    <ColumnContainer
      width="100vw"
      extraSX={{
        backgroundColor: enzi_theme.palette.background.default,
        margin: 0,
        paddingTop: "40px",
      }}
    >
      <BatteryInformationCard/>
      <LastInspectionCard/>
      <RiderInfoCard/>
      <BatteryDeploymentHistoryCard data={[]}/>
      <PaymentInfoCard/>
      <PastRiderTransactionsCard/>
      <ModalContainerExample />
      <PairedBatteryInformationCard/>
      <RecentBikeTripcards/>
      <BatteryEfficiencyCard/>
      <BatteryPowerExpenditurecard/>
    </ColumnContainer>
  );
}
