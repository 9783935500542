import React, {
	forwardRef,
	useCallback,
	useImperativeHandle,
	useState,
	useEffect,
} from "react";
import {
	Autocomplete,
	FormControl,
	Grid,
	InputAdornment,
	MenuItem,
	Select,
	Switch,
	TextField,
	FormHelperText,
	Typography,
	Box,
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format, parse } from "date-fns";
import { CustomText } from "./text";
import { Search } from "@mui/icons-material";

export const FormInput = forwardRef(function UI(
	{
		placeholder,
		type = "text",
		value = "",
		endAdornment = undefined,
		required,
		width = "100%",
		label = "",
		rows = 1,
		multiline = false,
		disabled = false,
		error = "", // Add error prop
		onChange, // Add this line
	},
	ref
) {
	const [inputState, setInputState] = useState(value);

	const handleUpdateState = useCallback(function (newValue) {
		setInputState(newValue);
	}, []);

	const handleReturnState = useCallback(
		function () {
			return inputState;
		},
		[inputState]
	);

	const handleInputChange = useCallback(
		function (ev) {
			setInputState(ev.target.value);
			if (onChange) {
				onChange(ev); // Call the parent's onChange function
			}
		},
		[onChange]
	);

	useImperativeHandle(ref, () => ({
		setState: handleUpdateState,
		getState: handleReturnState,
	}));

	return (
		<FormControl sx={{ width: width, marginBottom: "10px" }}>
			{label !== "" && (
				<CustomText
					variant="body1"
					text={label}
					color="secondary"
					fontWeight="700"
				/>
			)}
			<TextField
				variant="outlined"
				rows={rows}
				placeholder={placeholder}
				type={type}
				multiline={multiline}
				required={required}
				InputProps={{
					endAdornment: endAdornment,
				}}
				disabled={disabled}
				value={inputState}
				onChange={handleInputChange}
				error={Boolean(error)} // Set error state
				helperText={error} // Display error message
				sx={{
					"& .MuiOutlinedInput-root": {
						backgroundColor: "#EFEFEF",
						border: "none",
						"& fieldset": {
							border: "none",
						},
						"&:hover fieldset": {
							border: "none",
						},
						"&.Mui-focused fieldset": {
							border: "none",
						},
					},
				}}
			/>
		</FormControl>
	);
});

export const SearchInput = function ({
	placeholder,
	type = "text",
	required,
	width = "100%",
	label = "",
	rows = 1,
	multiline = false,
	filter,
}) {
	return (
		<FormControl sx={{ width: width, marginBottom: "10px" }}>
			{label !== "" && (
				<CustomText
					variant="body1"
					text={label}
					color="secondary"
					fontWeight="700"
				/>
			)}
			<TextField
				variant="outlined"
				rows={rows}
				placeholder={placeholder}
				type={type}
				multiline={multiline}
				required={required}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<Search color="#737373" />
						</InputAdornment>
					),
				}}
				onChange={filter}
				sx={{
					"& .MuiOutlinedInput-root": {
						backgroundColor: "#EFEFEF", // Set the background color to grey
						border: "none", // Remove the border
						"& fieldset": {
							border: "none", // Ensure the outline border is also removed
						},
						"&:hover fieldset": {
							border: "none", // Remove border on hover as well
						},
						"&.Mui-focused fieldset": {
							border: "none", // Remove border when the input is focused
						},
					},
				}}
			/>
		</FormControl>
	);
};

export const FormSelect = forwardRef(function UI(
	{
		required,
		value,
		width = "100%",
		label = "",
		placeholder,
		options = [],
		error = "",
		onChange,
	},
	ref
) {
	const [inputValue, setInputValue] = useState(value)
	const handleInputChange = useCallback(
		function (ev) {
			const newValue = ev.target.value;
			setInputValue(newValue);
			if (onChange) {
				onChange(ev);
			}
			setInputValue(ev.target.value)
		},
		[onChange,inputValue]
	);

	useImperativeHandle(ref, () => ({
		getState: () => inputValue,
	}));

	return (
		<FormControl
			sx={{ width: width, marginBottom: "10px" }}
			error={Boolean(error)}
		>
			{label !== "" && (
				<CustomText
					variant="body1"
					text={label}
					color="secondary"
					fontWeight="700"
				/>
			)}
			<Select
				displayEmpty
				value={inputValue}
				onChange={handleInputChange}
				sx={{
					boxShadow: "none",
					backgroundColor: "#EFEFEF",
					".MuiOutlinedInput-notchedOutline": {
						border: 0,
					},
					"&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
						{
							border: 0,
						},
					"&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
						{
							border: 0,
						},
				}}
			>
				<MenuItem disabled>
					<em>{placeholder}</em>
				</MenuItem>
				{options.map((option, index) => (
					<MenuItem key={index} value={option}>
						{option}
					</MenuItem>
				))}
			</Select>
			{error && <FormHelperText>{error}</FormHelperText>}
		</FormControl>
	);
});

export const AutoCompleteInput = forwardRef(function UI(
	{
		required,
		value,
		width = "100%",
		label = "",
		placeholder,
		error = "",
		options = [],
	},
	ref /*{ getState: () => {}, setState: (newValue) => {} }*/
) {
	const [inputState, setInputState] = useState(value);

	const handleUpdateState = useCallback(function (newValue) {
		setInputState(newValue);
	}, []);

	const handleReturnState = useCallback(
		function () {
			return inputState;
		},
		[inputState]
	);

	const handleInputChange = useCallback(function (ev, value) {
		setInputState(value);
	}, []);

	useImperativeHandle(ref, () => ({
		setState: handleUpdateState,
		getState: handleReturnState,
	}));

	return (
		<FormControl sx={{ width: width, marginBottom: "10px" }}>
			{label !== "" && (
				<CustomText
					variant="body1"
					text={label}
					color="secondary"
					fontWeight="700"
				/>
			)}
			<Autocomplete
				disablePortal={false}
				options={options}
				value={inputState}
				onInputChange={handleInputChange}
				renderInput={(params) => (
					<TextField
						{...params}
						placeholder={placeholder}
						sx={{
							"& .MuiOutlinedInput-root": {
								backgroundColor: "#EFEFEF", // Set the background color to grey
								border: "none", // Remove the border
								"& fieldset": {
									border: "none", // Ensure the outline border is also removed
								},
								"&:hover fieldset": {
									border: "none", // Remove border on hover as well
								},
								"&.Mui-focused fieldset": {
									border: "none", // Remove border when the input is focused
								},
							},
						}}
					/>
				)}
			/>
			{error && (
				<FormHelperText sx={{ color: "#dc2626" }}>
					{error}
				</FormHelperText>
			)}{" "}
		</FormControl>
	);
});

export const SwitchInput = forwardRef(function UI(
	{
		placeholder,
		type = "text",
		endAdornment = undefined,
		required,
		width = "100%",
		label = "",
		rows = 1,
		multiline = false,
	},
	ref /*{ getState: () => {}, setState: (newValue) => {} }*/
) {
	const [inputState, setInputState] = useState(true);

	const handleUpdateState = useCallback(function (newValue) {
		setInputState(newValue);
	}, []);

	const handleReturnState = useCallback(
		function () {
			return inputState;
		},
		[inputState]
	);

	const handleInputChange = useCallback(function (ev) {
		setInputState(ev.target.checked);
	}, []);

	useImperativeHandle(ref, () => ({
		setState: handleUpdateState,
		getState: handleReturnState,
	}));

	return (
		<FormControl sx={{ width: width, marginBottom: "10px" }}>
			{label !== "" && (
				<CustomText
					variant="body1"
					text={label}
					color="secondary"
					fontWeight="700"
				/>
			)}
			<Switch checked={inputState} onChange={handleInputChange} />
		</FormControl>
	);
});

const DAYS = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];

const TimeRangeInput = ({ day, initialData, onChange }) => {
	const [isOpen, setIsOpen] = useState(
		initialData.open !== "" || initialData.close !== ""
	);
	const [openTime, setOpenTime] = useState(
		initialData.open ? parse(initialData.open, "HH:mm", new Date()) : null
	);
	const [closeTime, setCloseTime] = useState(
		initialData.close ? parse(initialData.close, "HH:mm", new Date()) : null
	);

	const handleSwitchChange = (event) => {
		setIsOpen(event.target.checked);
		if (!event.target.checked) {
			onChange(day, { open: "", close: "" });
		} else if (openTime && closeTime) {
			onChange(day, {
				open: format(openTime, "HH:mm"),
				close: format(closeTime, "HH:mm"),
			});
		}
	};

	const handleOpenTimeChange = (newValue) => {
		setOpenTime(newValue);
		if (isOpen && newValue && closeTime) {
			onChange(day, {
				open: format(newValue, "HH:mm"),
				close: format(closeTime, "HH:mm"),
			});
		}
	};

	const handleCloseTimeChange = (newValue) => {
		setCloseTime(newValue);
		if (isOpen && openTime && newValue) {
			onChange(day, {
				open: format(openTime, "HH:mm"),
				close: format(newValue, "HH:mm"),
			});
		}
	};

	return (
		<Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
			<Switch checked={isOpen} onChange={handleSwitchChange} />
			<Typography sx={{ width: 50 }}>{day}</Typography>
			<TimePicker
				disabled={!isOpen}
				value={openTime}
				onChange={handleOpenTimeChange}
				renderInput={(params) => (
					<TextField {...params} size="small" sx={{ mr: 1 }} />
				)}
			/>
			<Typography sx={{ mx: 1 }}>-</Typography>
			<TimePicker
				disabled={!isOpen}
				value={closeTime}
				onChange={handleCloseTimeChange}
				renderInput={(params) => <TextField {...params} size="small" />}
			/>
		</Box>
	);
};

export const WorkingScheduleInput = forwardRef(function UI({ data = {} }, ref) {
	const [schedule, setSchedule] = useState(data);

	const handleScheduleChange = (day, times) => {
		setSchedule((prev) => ({ ...prev, [day]: times }));
	};

	useImperativeHandle(ref, () => ({
		getScheduleData: () => schedule,
	}));

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<Grid container spacing={2}>
				{DAYS.map((day) => (
					<Grid item xs={12} key={day}>
						<TimeRangeInput
							day={day}
							initialData={data?.[day] || { open: "", close: "" }}
							onChange={handleScheduleChange}
						/>
					</Grid>
				))}
			</Grid>
		</LocalizationProvider>
	);
});
