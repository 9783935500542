import React, { useRef } from 'react'
import { ModalContainer } from '../global/containers'
import AddRiderForm from '../forms/addRiderForm'
import { editRider } from '../../services/riderService'

export default function EditRiderModal({modalOpen = false,toggleModal,riderInfo}) {

  const handleSubmitRef = useRef()

  const handleSubmit = () => {
    const data = handleSubmitRef.current.getData()
    if(!data){
      return
    }
    editRiderDetails(data)
  }

  const editRiderDetails = (data) =>{
    editRider(data, data.id)
      .then((res) => {
        toggleModal()
      })
      .catch((err) => console.log(err))
  }

  return (
    <ModalContainer
        title='Edit Rider'
        modalOpen={modalOpen}
        height="auto"  
        actionButtonText = "Edit Rider"
        handleCloseModal={toggleModal}
        handleCancelClick={toggleModal}
        handleActionClick={handleSubmit}
      >
        <AddRiderForm ref={handleSubmitRef}  rider={riderInfo}/>
      </ModalContainer>
  )
}
