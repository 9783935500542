import React from 'react'
import { ColumnContainer, ModalContainer, RowContainer } from '../global/containers'
import InfoDetails from '../global/infoText'
import { formatDate } from '../../util/util'

export default function BikeTripModal({ modalOpen , toggleModal, tripdata}) {
  return (
    <ModalContainer
      title='Bike Trip'
      modalOpen={modalOpen}
      height="auto"
      width='40%' 
      type = "in-actionable"
    handleCloseModal={toggleModal}
    handleCancelClick={toggleModal}
    handleActionClick={toggleModal}
    >
      <RowContainer
        justifyContent='space-between'
        padding='0px'
        marginBottom='0px'
        extraSX={{

        }}
      >
        <ColumnContainer padding='0px' width='50' alignItems='left'>
          <InfoDetails label={'Trip Date'} value={formatDate(tripdata?.trip_date)} />
          <InfoDetails label={'Start Location'} value={tripdata?.start_location?.name} />
          <InfoDetails label={'Start Charge Level'} value={`${tripdata?.start_battery ?? "-"} %`} />
          <InfoDetails label={'Trip Duration'} value={tripdata?.total_time} />
          <InfoDetails label={'Total Energy Consumed'} value={`${tripdata?.battery_percentage_used ?? "-"} %`} />
        </ColumnContainer>
        <ColumnContainer width='50' alignItems='left' >
          <InfoDetails label={'Distance Covered'} value={`${tripdata?.distance ?? "-"} KMs`}  />
          <InfoDetails label={'End Location'} value={tripdata?.end_location?.name}  />
          <InfoDetails label={'End Charge Level'} value={`${tripdata?.end_battery ?? "-"} %`}  />
          <InfoDetails label={'Battery Efficiency'} value={tripdata?.battery_efficiency}  />
          <InfoDetails label={'Trip Energy Bill'} value={tripdata?.trip_cost}  />
        </ColumnContainer>

      </RowContainer>


    </ModalContainer>
  )
}
