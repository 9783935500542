import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import React, {
	forwardRef,
	useCallback,
	useImperativeHandle,
	useRef,
	useEffect,
} from "react";
import enzi_theme from "../../theme/theme";
import { Close, Error } from "@mui/icons-material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { CustomText } from "./text";

export const RowContainer = forwardRef(function (props, ref) {
	const {
		width = "100%",
		height = "auto",
		justifyContent = "flex-start",
		alignItems = "center",
		reversed = false,
		responsive = true,
		padding = "10px 20px",
		marginBottom = "10px",
		children = <></>,
		extraSX = {},
	} = props;
	const rowContainerRef = useRef(null);

	const getCurrentWidth = useCallback(
		function () {
			if (rowContainerRef !== null && rowContainerRef.current !== null) {
				return rowContainerRef.current.clientWidth;
			}
		},
		[rowContainerRef]
	);

	useImperativeHandle(ref, () => ({
		getCurrentWidth,
	}));
	ref = { rowContainerRef };

	return (
		<Box
			component={"div"}
			ref={rowContainerRef}
			sx={{
				display: "flex",
				flexDirection: reversed ? "row-reverse" : "row",
				flexWrap: responsive ? "wrap" : "nowrap",

				justifyContent,
				alignItems,

				width,
				height,

				padding,
				marginBottom,
				...extraSX,
			}}
		>
			{children}
		</Box>
	);
});

export const ColumnContainer = forwardRef(function UI(props, ref = null) {
	const {
		width = "100%",
		height = "auto",
		justifyContent = "flex-start",
		alignItems = "center",
		reversed = false,
		padding = "10px 20px",
		marginBottom = "10px",
		children = <></>,
		extraSX = {},
	} = props;
	const columnContainerRef = useRef(null);

	const getCurrentWidth = useCallback(
		function () {
			if (
				columnContainerRef !== null &&
				columnContainerRef.current !== null
			) {
				return columnContainerRef.current.clientWidth;
			}
		},
		[columnContainerRef]
	);

	useImperativeHandle(ref, () => ({
		getCurrentWidth,
	}));

	return (
		<Box
			component={"div"}
			ref={columnContainerRef}
			sx={{
				display: "flex",
				flexDirection: reversed ? "column-reverse" : "column",
				flexWrap: "nowrap",

				justifyContent,
				alignItems,

				width,
				height,

				padding,
				marginBottom,
				overflow: "hidden",
				...extraSX,
			}}
		>
			{children}
		</Box>
	);
});

export function ModalContainer({
	modalOpen,
	title = "Modal Title",
	type = "actionable",
	width = { xs: "80vw", lg: "47vw" },
	height = "50vh",
	actionButtonText = "Save",
	cancelButtonText = "Cancel",
	children = <></>,
	handleCloseModal = () => {},
	handleActionClick = () => {},
	handleCancelClick = () => {},
}) {

	return (
		<Modal
			open={modalOpen}
			onClose={handleCloseModal}
			aria-labelledby={`${title}-title`}
			aria-describedby={`${title}-description`}
			disableEnforceFocus={true}
			disableAutoFocus={true}
			disablePortal={true}
			disableRestoreFocus={true}
		>
			<div>
				<ColumnContainer
					width={width}
					height={height}
					marginBottom="0"
					justifyContent="center"
					alignItems="flex-start"
					responsive={false}
					extraSX={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						bgcolor: enzi_theme.palette.common.white,
						border: `2px solid ${enzi_theme.palette.grey[200]}`,
						borderRadius: "10px",

						boxShadow: 24,
						padding: "20px 20px",
						overflow: "hidden",
					}}
				>
					<RowContainer
						marginBottom="0"
						padding="10px 10px"
						justifyContent={
							type === "in-actionable"
								? "space-between"
								: "flex-start"
						}
						alignItems="center"
						responsive={false}
						extraSX={{ position: "relative", zIndex: 5 }}
					>
						<Typography
							variant="h3"
							color="secondary"
							fontWeight={700}
							textAlign={"start"}
							width={"80%"}
						>
							{title}
						</Typography>
						{type === "in-actionable" && (
							<IconButton
								onClick={handleCancelClick}
								sx={{
									backgroundColor:
										enzi_theme.palette.error.main,
									padding: "10px",
									borderRadius: "50%",
									width: "40px",
									height: "40px",
								}}
							>
								<Close
									sx={{
										color: enzi_theme.palette.common.white,
										":hover": {
											color: enzi_theme.palette.secondary
												.main,
										},
									}}
									fontSize="1rem"
								/>
							</IconButton>
						)}
					</RowContainer>
					<ColumnContainer
						width="100%"
						height={type === "actionable" ? "80%" : "100%"}
						marginBottom="0"
						justifyContent="start"
						alignItems="flex-start"
						responsive={false}
						padding="0 10px"
						extraSX={{
							overflowY: "auto",
							overX: "hidden",
							position: "relative",
							zIndex: 10,
						}}
					>
						{children}
					</ColumnContainer>
					{type === "actionable" && (
						<RowContainer
							width={"99%"}
							height={"auto"}
							marginBottom="0"
							justifyContent={"flex-end"}
							alignItems="center"
							responsive={false}
							extraSX={{
								paddingRight: "10px",
								zIndex: 5,
								position: "relative",
							}}
						>
							<Button
								variant="contained"
								color="primary"
								sx={{
									width: "200px",
									height: "50px",
									padding: "7px 10px",
									marginRight: "10px",
								}}
								onClick={handleActionClick}
							>
								{actionButtonText}
							</Button>
							<Button
								variant="contained"
								color="error"
								sx={{
									width: "200px",
									height: "50px",
									padding: "7px 10px",
									marginRight: "10px",
								}}
								onClick={handleCancelClick}
							>
								{cancelButtonText}
							</Button>
						</RowContainer>
					)}
					{/* here */}
					{type === "warning" && (
						<RowContainer
							width={"99%"}
							height={"auto"}
							marginBottom="0"
							justifyContent={"flex-end"}
							alignItems="center"
							responsive={false}
							extraSX={{ paddingRight: "10px" }}
						>
							<Button
								variant="outlined"
								color="error"
								sx={{
									width: "200px",
									height: "50px",
									padding: "7px 10px",
									marginRight: "10px",
								}}
								onClick={handleActionClick}
							>
								{actionButtonText}
							</Button>
							<Button
								variant="contained"
								color="error"
								sx={{
									width: "200px",
									height: "50px",
									padding: "7px 10px",
									marginRight: "10px",
								}}
								onClick={handleCancelClick}
							>
								{cancelButtonText}
							</Button>
						</RowContainer>
					)}
				</ColumnContainer>
			</div>
		</Modal>
	);
}

export function WarningTextContainer({ children }) {
	return (
		<ColumnContainer
			width="96%"
			alignItems="start"
			padding="10px"
			extraSX={{
				backgroundColor: enzi_theme.palette.error.background,
			}}
		>
			<RowContainer
				padding="10px"
				marginBottom="0px"
				alignItems="center"
				extraSX={{ gap: 1 }}
			>
				<Error color="error" />
				<CustomText
					marginBottom="0px"
					variant="h6"
					fontWeight="700"
					color={"error"}
					text="Warning"
				/>
			</RowContainer>
			{children}
		</ColumnContainer>
	);
}
export function CautionTextContainer({ children }) {
	return (
		<ColumnContainer
			width="96%"
			alignItems="start"
			padding="10px"
			extraSX={{
				backgroundColor: enzi_theme.palette.warning.background,
			}}
		>
			<RowContainer
				padding="10px"
				marginBottom="0px"
				alignItems="center"
				extraSX={{ gap: 1 }}
			>
				<Error color="error" />
				<CustomText
					marginBottom="0px"
					variant="h6"
					fontWeight="700"
					color={"error"}
					text="Warning"
				/>
			</RowContainer>
			{children}
		</ColumnContainer>
	);
}

export const CardContainer = forwardRef(function UI(props, ref) {
	const cardContainerRef = useRef(null);

	const {
		width = "95%",
		height = "400px",
		title = "Card Title",
		children = <></>,
		childrenContainerWidth = "100%",
		childrenContainerPadding = "10px",
		type = "in-actionable",
		actionableText = "See More",
		hasActionableComponent = false,
		actionableComponent = <></>,
		handleActionableClick = () => {},
		light = false,
		extraSX = {},
		titleActionSpacing = "20px", // Add this new prop
	} = props;

	const getContainerWidth = useCallback(
		function () {
			if (
				cardContainerRef !== null &&
				cardContainerRef.current !== null
			) {
				return cardContainerRef.current.getCurrentWidth();
			} else {
				return undefined;
			}
		},
		[cardContainerRef]
	);

	useImperativeHandle(ref, () => ({
		getContainerWidth,
	}));

	return (
		<ColumnContainer
			ref={cardContainerRef}
			width={width}
			height={height}
			padding="10px 5px"
			marginBottom="0"
			justifyContent="center"
			alignItems="flex-start"
			responsive={false}
			extraSX={{
				backgroundColor:
					light === true
						? enzi_theme.palette.common.white
						: enzi_theme.palette.background.default,
				borderRadius: "10px",
				overflow: "hidden",
				...extraSX,
			}}
		>
			<RowContainer
				marginBottom="0"
				justifyContent={
					type === "actionable" ? "space-between" : "flex-start"
				}
				alignItems="center"
				responsive={false}
				padding={
					type === "actionable"
						? `0px ${titleActionSpacing}`
						: "10px 20px"
				} // Use the new prop here
				width={childrenContainerWidth}
				height={"50px"}
			>
				<Typography
					variant="h3"
					color="secondary"
					fontWeight={700}
					textAlign={"start"}
					width={"auto"}
				>
					{title}
				</Typography>
				{type === "actionable" &&
					(hasActionableComponent ? (
						actionableComponent
					) : (
						<Button
							onClick={handleActionableClick}
							variant="text"
							color={"secondary"}
							sx={{
								width: "auto",
								height: "40px",
								margin: "0 10px",
								fontWeight: 700,
							}}
						>
							{actionableText}
							<ChevronRightIcon />
						</Button>
					))}
			</RowContainer>
			<ColumnContainer
				height={type === "actionable" ? "90%" : "100%"}
				width={childrenContainerWidth}
				marginBottom="0"
				alignItems="center"
				responsive={false}
				extraSX={{
					overflowY: "auto",
					overflowX: "hidden",
					margin: "0 auto",
					padding: childrenContainerPadding,
					backgroundColor: enzi_theme.palette.common.white,
					borderRadius: "10px",
				}}
			>
				{children}
			</ColumnContainer>
		</ColumnContainer>
	);
});
