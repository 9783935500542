import { Box, Button, Typography } from "@mui/material";
import backgroundImage from "../images/password_reset_reguest_background.jpg";
import enzi_logo from "../images/Enzi-logo-400px.png";
import { FormInput } from "../components/global/form";
import { CustomText } from "../components/global/text";
import { ColumnContainer, RowContainer } from "../components/global/containers";
import enzi_theme from "../theme/theme";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";

function ResetPasswordRequest() {
  const navigate = useNavigate();
  const emailRef = useRef();

  const handlePassReset = () =>{
    console.log('submit on this: ',emailRef.current.getState());
  } 

  function handleRouteToLogin() {
    navigate("/");
  }


  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",

        backgroundSize: "cover",
        backgroundImage: `url(${backgroundImage})`,
        alignItems: "center",
        justifyContent: "flex-end",
        paddingRight: "3rem",
      }}
    >
      <ColumnContainer
        width="33.3%"
        height="auto"
        alignItems="flex-end"
        padding="10px 20px"
        extraSX={{
          backgroundColor: enzi_theme.palette.common.white,
          borderRadius: "10px",
        }}
      >
        <RowContainer width="100%" height="auto" justifyContent="flex-end">
          <img
            alt="logo"
            style={{ float: "right" }}
            src={enzi_logo}
            width={75}
            height={38.87}
          />
        </RowContainer>

        <CustomText
          text={"Reset your Password"}
          variant={"h1"}
          color={"secondary"}
          fontWeight="800"
        />
        <CustomText
          text={
            "Please enter your account’s email to receive an authenticated password reset link."
          }
          variant={"body1"}
          color={"secondary"}
        />

        <ColumnContainer
          width="95%"
          alignItems="center"
          justifyContent="flex-start"
          marginBottom="0"
        >
          <FormInput
            placeholder={"Enter email address"}
            type="email"
            ref={emailRef}
            required={true}
          />
          <Button
            variant="contained"
            size="large"
            sx={{ width: 300, marginTop: 1 }}
            onClick={handlePassReset}
          >
            <Typography
              variant="body1"
              color={"secondary"}
              sx={{ fontWeight: 700 }}
            >
              Request Reset Link
            </Typography>
          </Button>
          <RowContainer
            width="100%"
            height="auto"
            justifyContent="center"
            responsive={false}
            marginBottom="0"
          >
            <CustomText
              width="auto"
              variant="body1"
              color={"secondary"}
              fontWeight="500"
              extraSX={{ margin: "auto 5px", fontSize: "1rem" }}
              text="Remember Password?"
            />
            <Button
              variant="text"
              color={"secondary"}
              sx={{
                margin: "auto 0",
                padding: 0,
                fontSize: "1rem",
                textTransform: "capitalize",
                ":hover": { cursor: "pointer" },
              }}
              onClick={handleRouteToLogin}
            >
              Go to Login
            </Button>
          </RowContainer>
        </ColumnContainer>
      </ColumnContainer>
    </Box>
  );
}

export default ResetPasswordRequest;
