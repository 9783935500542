import React, { useCallback, useEffect, useRef, useState } from "react";
import { CardContainer } from "../global/containers";
import { BarChartItem } from "../global/charts";

export default function RevenuePerformanceCard() {
  const cardContainerRef = useRef();

  const [cardContainerWidth, setCardContainerWidth] = useState(undefined);

  const setChartWidth = useCallback(
    function () {
      if (cardContainerRef !== null && cardContainerRef.current !== null) {
        const containerWidth = cardContainerRef.current.getContainerWidth();
        console.log("container width", cardContainerWidth);

        if (!isNaN(containerWidth)) {
          setCardContainerWidth(containerWidth * 0.85);
        }
      }
    },
    [cardContainerRef, cardContainerWidth]
  );

  useEffect(() => {
    setChartWidth();
  }, [setChartWidth]);

  return (
    <CardContainer
      ref={cardContainerRef}
      title="Revenue Performance"
      width={{ xs: "100%", lg: "66%" }}
      // height={"50vh"}
      type="actionable"
      actionableText="See more"
      childrenContainerWidth={"97.5%"}
    >
      <BarChartItem
        height={300}
        width={cardContainerWidth}
        // dataLabels={["Quarter 1", "Quarter 2", "Quarter 3", "Quarter 4"]}
        dataSeries={[
          {
            dataKey: "Q1",
            label: "Quarter 1",
            valueFormatter: (value) => `${value}k KES`,
          },
          {
            dataKey: "Q2",
            label: "Quarter 2",
            valueFormatter: (value) => `${value}k KES`,
          },
          {
            dataKey: "Q3",
            label: "Quarter 3",
            valueFormatter: (value) => `${value}k KES`,
          },
          {
            dataKey: "Q4",
            label: "Quarter 4",
            valueFormatter: (value) => `${value}k KES`,
          },
        ]}
        dataset={[
          { Q1: 231, Q2: 302, Q3: 936, Q4: 317, x: "2020" },
          { Q1: 120, Q2: 621, Q3: 133, Q4: 457, x: "2021" },
          { Q1: 510, Q2: 631, Q3: 741, Q4: 871, x: "2023" },
          { Q1: 1120, Q2: 1221, Q3: 1323, Q4: 1257, x: "2024" },
        ]}
        // dataSeries={[
        //   {
        //     data: [231, 302, 936, 317],
        //     color: enzi_theme.palette.secondary.main,
        //     valueFormatter: (value) => `${value}K KES`,
        //   },
        //   {
        //     data: [120, 621, 133, 457],
        //     color: enzi_theme.palette.primary.main,
        //     valueFormatter: (value) => `${value}K KES`,
        //   },
        // ]}
        chartLabel="Revenue Performance"
      />
    </CardContainer>
  );
}
