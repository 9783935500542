import { PRINT_DOCUMENTS } from "../constants/url.constants";
import { serviceAPI } from "../services/service";
import { issueErrorMessage, issueSuccessMessage } from "../util/util";

export const printDocument = async (SearchParams) => {
	try {
		const response = await serviceAPI.get(PRINT_DOCUMENTS+SearchParams,{ responseType: 'blob' });
		issueSuccessMessage("Document downloaded Successfully");
		return response.data;
	} catch (error) {
		issueErrorMessage("Document not downloaded");
        throw error;
	}
};