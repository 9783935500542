import React, { useRef } from 'react'
import { ModalContainer } from '../global/containers'
import AddUserForm from '../forms/addUserForm'
import { updateUser } from '../../services/userService';
import { issueSuccessMessage } from '../../util/util';

export default function EditUserModal({toggleModal,modalOpen = false, staffMember}) { 

  
  const submitRef = useRef();

  const handleSubmit = () => {
    const data = submitRef.current.getData();

    if(data === null){
      return;
    }
    updateStaff(data.id,data);
  }

  const updateStaff = (id,data) => {
    updateUser(id,data)
      .then(res => {
        toggleModal();
        issueSuccessMessage('Staff user update')
      })
      .catch(err => {
        
      })
  }

  return (
    <ModalContainer
        title='Edit User'
        modalOpen={modalOpen}
        height="auto"  
        actionButtonText = "Edit User"
        handleCloseModal={toggleModal}
        handleCancelClick={toggleModal}
        handleActionClick={handleSubmit}
      >
        <AddUserForm User={staffMember} ref={submitRef} />
      </ModalContainer>
  )
}
