import React from "react";
import { ColumnContainer } from "../components/global/containers";
import ChargeStationStatistics from "../components/cards/chargeStationStatistics";
import TopBar from "../components/global/topBar";
import BackButton from "../components/global/backButton";
import ChargeListCard from "../components/cards/chargeListCard";
import { FloatingActionButton } from "../components/global/button";
import { useNavigate } from "react-router-dom";

export default function ChargeStationListPage() {
	const navigate = useNavigate();
	return (
		<ColumnContainer>
			<TopBar>
				<BackButton />
			</TopBar>
			<ChargeStationStatistics />
			<ChargeListCard showSearch={true} showTimeRange={false} />
		</ColumnContainer>
	);
}
