import React from "react";
import {
  ColumnContainer,
  ModalContainer,
  RowContainer,
} from "../global/containers";
import InfoDetails from "../global/infoText";
import { formatDate } from "../../util/util";

export default function BatteryDeploymentModal({
  deployment,
  modalOpen,
  toggleModal,
}) {

  return (
    <ModalContainer
      title={`Battery Deployment - ${deployment?.id}`}
      modalOpen={modalOpen}
      height="auto"
      type="in-actionable"
      handleCloseModal={toggleModal}
      handleCancelClick={toggleModal}
      handleActionClick={toggleModal}
    >
      <RowContainer
        justifyContent="space-between"
        padding="0px"
        marginBottom="0px"
        alignItems="start"
      >
        <ColumnContainer padding="0px" width="50%" alignItems="left">
          <InfoDetails label={"Deployment Date"} value={formatDate(deployment?.date)} />
          <InfoDetails label={"Issuing Station"} value={deployment?.issuingStation} />
          <InfoDetails label={"Start Charge Level"} value={deployment?.startCharge + "%"} />
          <InfoDetails label={"Deployment Duration"} value={deployment?.duration + " Hrs"} />
          <InfoDetails label={"Total Energy Consumed"} value={deployment?.unitsConsumed + " kWh"} />
        </ColumnContainer>
        <ColumnContainer padding="0px" width="50%" alignItems="left" justify="start" >
          <InfoDetails label={"Paired Bike"} value={deployment?.ev} />
          <InfoDetails label={"Receiving Station"} value={deployment?.receivingStation} />
          <InfoDetails label={"End Charge Level"} value={deployment?.endCharge + "%"} />
          <InfoDetails label={"Deployment Energy Bill"} value={deployment?.consumptionBill + " KSH"} />
        </ColumnContainer>
      </RowContainer>
    </ModalContainer>
  );
}
