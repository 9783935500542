import React, { useCallback, useEffect, useState } from "react";
import { ColumnContainer, RowContainer } from "../components/global/containers";
import PairedBatteryInformationCard from "../components/cards/pairedBatteryInformationCard";
import RecentBikeTripcards from "../components/cards/recentBikeTripcards";
import BikeAssetBasicInfoCard from "../components/cards/bikeAssetBasicInfoCard";
import BikeAssetRegisteredRiderCard from "../components/cards/bikeAssetRegisteredRiderCard";
import TopBar from "../components/global/topBar";
import { useParams } from "react-router-dom";
import { getElectricVehiclesInfo } from "../services/electricVehicle";
import BackButton from "../components/global/backButton";

export default function MotorbikeAssetInformation() {

  const param = useParams()
  const [electricVehicleInfo, setElectricVehicleInfo] = useState()

  const fetchMotorBikeInfo = useCallback(async function () {
    getElectricVehiclesInfo(param.id)
      .then((res) => {
        setElectricVehicleInfo(res)
      })
      .catch((err) => {
        console.log(err);

      })
  }, [])

  useEffect(() => {
    fetchMotorBikeInfo()
  }, [fetchMotorBikeInfo])


  return (
    <ColumnContainer
      height="100%"
      marginBottom="0px"
      extraSX={{
        overflowY: "auto",
      }}
      alignItems="flex-start"
    >
      <TopBar>
      <BackButton/>
      </TopBar>
      <RowContainer
        justifyContent="space-between"
        height="37.5vh"
        padding="0px"
        extraSX={{
          gap: "10px",
          paddingBottom: "10px",

          flexWrap: { xs: "wrap", lg: "nowrap" },
        }}
      >
        <BikeAssetBasicInfoCard electricVehicleInfo={electricVehicleInfo}/>
        <PairedBatteryInformationCard  batteryInfo={electricVehicleInfo?.battery}/>
        <BikeAssetRegisteredRiderCard ownerInfo={electricVehicleInfo?.owner} />
      </RowContainer>
      <RowContainer
        justifyContent="center"
        height="70vh"
        padding="0px"
        extraSX={{
          gap: "10px",
          paddingTop: "10px",
          flexWrap: { xs: "wrap", lg: "nowrap" },
        }}
      >
        <RecentBikeTripcards />
      </RowContainer>
    </ColumnContainer>
  );
}
