import React, { useCallback, useEffect, useState } from 'react';
import { ColumnContainer, RowContainer } from '../components/global/containers';
import PairedBatteryInformationCard from '../components/cards/pairedBatteryInformationCard';
import VehicleAssetBasicInfoCard from '../components/cards/vehicleAssetBasicInfoCard';
import VehicleAssetRegisteredRiderCard from '../components/cards/vehicleAssetRegisteredRiderCard';
import TopBar from '../components/global/topBar';
import { useParams } from 'react-router-dom';
import { getElectricVehiclesInfo } from '../services/electricVehicle';
import RecentVehicleTripsCard from '../components/cards/recentVehicleTripsCard';

export default function ElectricVehicleAssetInformation() {
	const param = useParams();
	const [electricVehicleInfo, setElectricVehicleInfo] = useState();

	const fetchVehicleInfo = useCallback(
		async function () {
			getElectricVehiclesInfo(param.id)
				.then((res) => {
					setElectricVehicleInfo(res);
				})
				.catch((err) => {
					console.log(err);
				});
		},
		[param.id]
	);

	useEffect(() => {
		fetchVehicleInfo();
	}, [fetchVehicleInfo]);

	return (
		<ColumnContainer
			height="100%"
			marginBottom="0px"
			extraSX={{
				overflowY: 'auto',
			}}
			alignItems="flex-start"
		>
			<TopBar />
			<RowContainer
				justifyContent="space-between"
				height="37.5vh"
				padding="0px"
				extraSX={{
					gap: '10px',
					paddingBottom: '10px',

					flexWrap: { xs: 'wrap', lg: 'nowrap' },
				}}
			>
				<VehicleAssetBasicInfoCard
					electricVehicleInfo={electricVehicleInfo}
					setElectricVehicleInfo={setElectricVehicleInfo}
				/>
				<PairedBatteryInformationCard
					batteryInfo={electricVehicleInfo?.battery}
				/>
				<VehicleAssetRegisteredRiderCard
					ownerInfo={electricVehicleInfo?.owner}
				/>
			</RowContainer>
			<RowContainer
				justifyContent="center"
				height="70vh"
				padding="0px"
				extraSX={{
					gap: '10px',
					paddingTop: '10px',
					flexWrap: { xs: 'wrap', lg: 'nowrap' },
				}}
			>
				<RecentVehicleTripsCard />
			</RowContainer>
		</ColumnContainer>
	);
}
