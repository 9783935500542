import { ThemeProvider } from "@mui/material";
import "./App.css";
import enzi_theme from "./theme/theme";
import { Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import ResetPassword from "./pages/ResetPassword";
import ResetPasswordRequest from "./pages/ResetPasswordRequest";
import Docs from "./pages/docs";
import ProtectedLayout from "./components/layouts/protected";
import Dashboard from "./pages/dashboard";
import { Provider } from "react-redux";
import { persistor, store } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import LoadingOverlay from "./components/overlays/loading";
import ElectricVehicleAssetInformation from "./pages/vehicleAssetInformation";
import RiderInfo from "./pages/riderInfo";
import BatteryAssetInfo from "./pages/batteryAssetInfo";
import SwapStationInformation from "./pages/swapStationInformation";
import ChargeStation from "./pages/chargeStation";
import AssetMap from "./pages/assetMap";
import ElectricVehicleListPage from "./pages/vehiclesListPage";
import SwapStationsList from "./pages/swapStationsList";
import TrackerAssetPage from "./pages/trackerAssetPage";
import ChargeStationListPage from "./pages/chargeStationListPage";
import BatteryAssetsListPage from "./pages/batteryAssetsListPage";
import StaffManagement from "./pages/staffManagement";
import RiderListPage from "./pages/riderListPage";

function App() {

	return (
		<div className="App">
			<Provider store={store}>
				<PersistGate
					persistor={persistor}
					loading={
						<LoadingOverlay
							loadingMessage="Loading state..."
							height="100vh"
						/>
					}
				>
					<ThemeProvider theme={enzi_theme}>
						<Routes>
							<Route exact path="/" element={<Login />} />
							<Route
								exact
								path="/public/docs"
								element={<Docs />}
							/>
							<Route
								exact
								path="/password-reset"
								element={<ResetPassword />}
							/>
							<Route
								exact
								path="/password-reset-request"
								element={<ResetPasswordRequest />}
							/>
							<Route
								exact
								path="/dashboard"
								element={
									<ProtectedLayout>
										<Dashboard />
									</ProtectedLayout>
								}
							/>
							<Route
								exact
								path="/electric-vehicles/:id"
								element={
									<ProtectedLayout>
										<ElectricVehicleAssetInformation />
									</ProtectedLayout>
								}
							/>
							<Route
								exact
								path="/electric-vehicles"
								element={
									<ProtectedLayout>
										<ElectricVehicleListPage />
									</ProtectedLayout>
								}
							/>
							<Route
								exact
								path="/batteries/:bat_sn"
								element={
									<ProtectedLayout>
										<BatteryAssetInfo />
									</ProtectedLayout>
								}
							/>
							<Route
								exact
								path="/batteries"
								element={
									<ProtectedLayout>
										<BatteryAssetsListPage />
									</ProtectedLayout>
								}
							/>
							<Route
								exact
								path="/riders"
								element={
									<ProtectedLayout>
										<RiderListPage />
									</ProtectedLayout>
								}
							/>
							<Route
								exact
								path="/riders/:id"
								element={
									<ProtectedLayout>
										<RiderInfo />
									</ProtectedLayout>
								}
							/>

							<Route
								exact
								path="/trackers"
								element={
									<ProtectedLayout>
										<TrackerAssetPage />
									</ProtectedLayout>
								}
							/>
							<Route
								exact
								path="/swap-station/:id"
								element={
									<ProtectedLayout>
										<SwapStationInformation />
									</ProtectedLayout>
								}
							/>
							<Route
								exact
								path="/swap-station"
								element={
									<ProtectedLayout>
										<SwapStationsList />
									</ProtectedLayout>
								}
							/>
							<Route
								exact
								path="/charge-station/:id"
								element={
									<ProtectedLayout>
										<ChargeStation />
									</ProtectedLayout>
								}
							/>
							<Route
								exact
								path="/charge-station"
								element={
									<ProtectedLayout>
										<ChargeStationListPage />
									</ProtectedLayout>
								}
							/>
							<Route
								exact
								path="/assets"
								element={
									<ProtectedLayout>
										<AssetMap />
									</ProtectedLayout>
								}
							/>
							<Route
								exact
								path="/staff_management"
								element={
									<ProtectedLayout>
										<StaffManagement />
									</ProtectedLayout>
								}
							/>
						</Routes>
					</ThemeProvider>
				</PersistGate>
			</Provider>
		</div>
	);
}

export default App;
