import React from "react";
import { ColumnContainer } from "../components/global/containers";
import TopBar from "../components/global/topBar";
import SwapStationStatistics from "../components/cards/swapStationStatistics";
import SwapListCard from "../components/cards/swapListCard";
import BackButton from "../components/global/backButton";

export default function SwapStationsList() {
	return (
		<ColumnContainer>
			<TopBar>
				<BackButton />
			</TopBar>
			<SwapStationStatistics />
			<SwapListCard
				title="All Swap Stations"
				height="75vh"
				width="100%"
				showTimeRange={false}
				showAddButton={true}
				showSearch={true}
			/>
		</ColumnContainer>
	);
}
