import React, { useEffect, useState } from "react";
import { RowContainer } from "../global/containers";
import StatItem from "../global/statItem";
import { chargingStationMetrics } from "../../services/metricsService";

export default function ChargeStationStatistics() {
	const [loading, setLoading] = useState(false);
	const [stationInventoryCount, setStationInventoryCount] = useState("_");
	const [avgIdleBatteries, setAvgIdleBatteries] = useState("_");
	const [avgDailyPowerConsumption, setAvgDailyPowerConsumption] =
		useState("_");
	const [avgChargeStationRevenue, setAvgChargeStationRevenue] = useState("_");

	const cards = [
		{ title: "Batteries in Charge Stations", value: stationInventoryCount },
		{
			title: "Average Idle Batteries Per Station",
			value: avgIdleBatteries,
		},
		{
			title: "Avg Daily Power Consumption",
			value: avgDailyPowerConsumption,
		},
		{ title: "Avg Charge Station Revenue", value: avgChargeStationRevenue },
	];

	useEffect(() => {
		setLoading(true);
		chargingStationMetrics().then((response) => {
			setStationInventoryCount(response?.total_inventory_count);
			setAvgIdleBatteries(response?.average_idle_batteries);
			setAvgDailyPowerConsumption(response?.average_daily_power_consumed);
			setAvgChargeStationRevenue(response?.average_station_revenue);
		});
		setLoading(false);
	}, []);

	return (
		<RowContainer
			justifyContent="space-between"
			width="100%"
			extraSX={{ gap: 1, flexWrap: { xs: "wrap", lg: "nowrap" } }}
		>
			{cards.map((card, index) => (
				<StatItem
					key={index}
					title={card.title}
					loading={loading}
					width="25%"
					value={card.value}
				/>
			))}
		</RowContainer>
	);
}
