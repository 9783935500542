import React, { useState } from "react";
import {
  CardContainer,
  ColumnContainer,
  RowContainer,
} from "../global/containers";
import StatItem from "../global/statItem";
import ActionableComponent from "../global/actionableComponent";

export default function TrackersOverviewStatsCard() {
  const [timeRange, setTimeRange] = useState("day");

  const handleTimeRangeChange = (event) => {
    const newTimeRange = event.target.value;
    setTimeRange(newTimeRange);
    // TODO: Fetch stats
  };

  return (
    <CardContainer
      title="Tracker Overview Stats"
      width={{ xs: "100%", lg: "33%" }}
      height="auto"
      childrenContainerWidth="97%"
      hasActionableComponent={true}
      actionableComponent={
        <ActionableComponent
          showTimeRange={true}
          timeRange={timeRange}
          handleTimeRangeChange={handleTimeRangeChange}
        />
      }
    >
      <ColumnContainer
        width="100%"
        height="auto"
        padding="5px 10px"
        marginBottom="5px"
        extraSX={{
          gap: "10px",
        }}
      >
        <RowContainer
          justifyContent="space-between"
          alignItems="start"
          padding="0px"
          marginBottom="0px"
          responsive={false}
          extraSX={{
            gap: "10px",
          }}
        >
          <StatItem title={"Total Active Trackers "} value={1200} width="50%" />
          <StatItem
            title={"Month’s Tracker Sales Revenue"}
            value={1200}
            width="50%"
          />
        </RowContainer>
        <RowContainer
          justifyContent="space-between"
          alignItems="start"
          padding="0px"
          marginBottom="0px"
          responsive={false}
          extraSX={{
            gap: "10px",
          }}
        >
          <StatItem title={"Newly Enrolled Tracker"} value={1200} width="50%" />
          <StatItem title={"Suspended Trackers "} value={1200} width="50%" />
        </RowContainer>
      </ColumnContainer>
    </CardContainer>
  );
}
