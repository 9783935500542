import React, { useRef } from 'react'
import AddUserForm from '../forms/addUserForm'
import { ModalContainer } from '../global/containers'
import { addUser } from '../../services/userService';

export default function AddNewUserModal({modalOpen = false,toggleModal}) {

  const handleSubmitRef = useRef();

  const handleSubmit = ()=>{
    const data = handleSubmitRef.current.getData()
    
    if(data === null){
      return;
    }
    createuser(data)
  }

  const createuser = (data) => {
    addUser(data)
      .then(res => {
        toggleModal()
      })
      .catch(err => {
        console.log(err);        
      })
  }

  return (
    <ModalContainer
        title='Add New User'
        modalOpen={modalOpen}
        height="auto"  
        actionButtonText = "Add User"
        handleCloseModal={toggleModal}
        handleCancelClick={toggleModal}
        handleActionClick={handleSubmit}
      >
        <AddUserForm ref={handleSubmitRef} />
      </ModalContainer>
  )
}
