import React, { useCallback, useState } from "react";
import {
	CardContainer,
	ColumnContainer,
	RowContainer,
} from "../global/containers";
import InfoDetails from "../global/infoText";
import { FloatingActionButton } from "../global/button";
import EditStationModal from "../modals/editStationModal";
import { Edit } from "@mui/icons-material";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";

const formatWorkingHours = (workingHours) => {
	if (!workingHours || typeof workingHours !== "object") return [];

	const daysOrder = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
	return daysOrder.map((day) => {
		const hours = workingHours[day] || {};
		if (!hours.open && !hours.close) {
			return { day, hours: "Closed" };
		}
		return {
			day,
			hours: `${hours.open || "00:00"} - ${hours.close || "00:00"}`,
		};
	});
};

export default function StationBasicInfoCard({
	station,
	onStationUpdate,
	EditModal,
}) {
	const [modalOpen, setModalOpen] = useState(false);

	const toggleModal = useCallback(() => {
		setModalOpen((prevState) => !prevState);
	}, []);

	const handleEditSuccess = useCallback(
		(updatedStation) => {
			if (typeof onStationUpdate === "function") {
				onStationUpdate(updatedStation);
			}
		},
		[onStationUpdate]
	);

	const renderWorkingHours = () => {
		const formattedHours = formatWorkingHours(station?.working_hours);
		return (
			<Table
				size="small"
				sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
			>
				<TableBody>
					{formattedHours.map(({ day, hours }) => (
						<TableRow
							key={day}
							sx={{
								"&:last-child td, &:last-child th": {
									border: 0,
								},
							}}
						>
							<TableCell
								component="th"
								scope="row"
								sx={{
									fontWeight: "bold",
									padding: "8px",
									borderBottom:
										"1px solid rgba(224, 224, 224, 1)",
									borderRight:
										"1px solid rgba(224, 224, 224, 1)",
									whiteSpace: "nowrap",
									fontStyle: "inherit",
									fontFamily: "inherit",
									fontSize: "inherit",
									color: "inherit",
								}}
							>
								{day}
							</TableCell>
							<TableCell
								align="right"
								sx={{
									padding: "8px",
									borderBottom:
										"1px solid rgba(224, 224, 224, 1)",
									whiteSpace: "nowrap",
									fontStyle: "inherit",
									fontFamily: "inherit",
									fontSize: "inherit",
									color: "inherit",
								}}
							>
								{hours}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		);
	};

	return (
		<CardContainer
			title="Basic Information"
			width={{ xs: "100%", lg: "50%" }}
			height={{ xs: "auto", lg: "100%" }}
			childrenContainerWidth="97%"
			type="actionable"
			hasActionableComponent={true}
			actionableComponent={
				<FloatingActionButton
					icon={<Edit color="secondary" />}
					onclick={toggleModal}
				/>
			}
			titleActionSpacing="16px"
		>
			<RowContainer
				justifyContent="flex-start"
				padding="0px"
				marginBottom="0px"
				extraSX={{
					flexDirection: { xs: "column", md: "row", gap: 2 },
					alignItems: { xs: "center", md: "flex-start" },
				}}
			>
				<ColumnContainer
					padding="0px"
					width={{ xs: "100%", md: "48%" }}
					alignItems="flex-start"
				>
					<RowContainer justifyContent="flex-start">
						<InfoDetails
							label="Station Name"
							value={station?.name || "N/A"}
							valueProps={{
								sx: {
									wordWrap: "break-word",
									overflowWrap: "break-word",
									maxWidth: "100%",
								},
							}}
						/>
						<InfoDetails
							label="Status"
							value={station?.status || "N/A"}
							valueProps={{
								sx: {
									wordWrap: "break-word",
									overflowWrap: "break-word",
									maxWidth: "100%",
								},
							}}
						/>
					</RowContainer>
				</ColumnContainer>
				<ColumnContainer
					padding="0px"
					width={{ xs: "100%", md: "48%" }}
					alignItems="flex-start"
				>
					<RowContainer>
						<InfoDetails
							label="Physical Address"
							value={station?.address || "N/A"}
							valueProps={{
								sx: {
									wordWrap: "break-word",
									overflowWrap: "break-word",
									maxWidth: "100%",
								},
							}}
						/>
						<InfoDetails
							label="Working Hours"
							value={renderWorkingHours()}
						/>
					</RowContainer>
				</ColumnContainer>
			</RowContainer>
			<EditStationModal
				modalOpen={modalOpen}
				toggleModal={toggleModal}
				station={station}
				onSuccess={handleEditSuccess}
				type="swap"
			/>
		</CardContainer>
	);
}
