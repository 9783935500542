import React, { useRef } from 'react'
import { ModalContainer } from '../global/containers'
import AddBikeAssertForm from '../forms/addBikeAssertForm'
import { editElectricVehicles } from '../../services/electricVehicle'

export default function EditBikeAssetModal({modalOpen,toggleModal,bikeAsset}) {

  const handleSubmitRef = useRef()

  const handleSubmit = () => {
    const bikeData = handleSubmitRef.current.getData()
    onSubmit(bikeData)
  }

  const onSubmit = (data) => {
    editElectricVehicles(data,data.id)
      .then((res)=>{
        console.log(res);
        toggleModal()
      })
      .catch((err)=> console.log(err))
  }

  return (
    <ModalContainer
        title={`Edit Bike Asset #${bikeAsset?.serial_number}`}
        modalOpen={modalOpen}
        height="auto"
        width='50%'
        handleCloseModal={toggleModal}
        handleCancelClick={toggleModal}
        handleActionClick={handleSubmit}
      >
        <AddBikeAssertForm bikeAsset={bikeAsset} ref={handleSubmitRef} isEdit={true}/>
      </ModalContainer>
  )
}
