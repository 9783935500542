import { Alert, Snackbar } from '@mui/material'
import React from 'react'

const MessageAlert = ({showMessage, appMessage, handleCloseMessage}) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={showMessage}
      autoHideDuration={3000}
      onClose={() => handleCloseMessage()}
      sx={{
      }}
    >
      <Alert
        elevation={6}
        variant="filled"
        severity={appMessage.type}
        sx={{ width: '100%' }}
      >
        {appMessage.message}
      </Alert>
    </Snackbar>
  )
}

export default MessageAlert