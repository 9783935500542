import React from 'react';
import { ColumnContainer, RowContainer } from '../components/global/containers';
import BatteryInformationCard from '../components/cards/batteryInformationCard';
// import BatteryEfficiencyCard from "../components/cards/batteryEfficiencyCard";
// import BatteryPowerExpenditurecard from "../components/cards/batteryPowerExpenditurecard";
import LastInspectionCard from '../components/cards/lastInspectionCard';
import BatteryDeploymentHistoryCard from '../components/cards/batteryDeploymentHistoryCard';
import TopBar from '../components/global/topBar';
import { useParams } from 'react-router-dom';

export default function BatteryAssetInfo() {
	const { bat_sn } = useParams();

	return (
		<ColumnContainer
			height="100%"
			marginBottom="0px"
			extraSX={{
				overflowY: 'auto',
			}}
		>
			<TopBar />
			<RowContainer
				justifyContent="flex-start"
				marginBottom="10px"
				height="450px"
				width="100%"
				extraSX={{ gap: 1, flexWrap: { xs: 'flex', lg: 'nowrap' } }}
			>
				<BatteryInformationCard bat_sn={bat_sn} />
				<LastInspectionCard bat_sn={bat_sn} />
			</RowContainer>
			<RowContainer
				justifyContent="flex-start"
				marginBottom="10px"
				height="450px"
				widths="100%"
				extraSX={{ gap: 1, paddingBottom: '10px' }}
			>
				<BatteryDeploymentHistoryCard bat_sn={bat_sn} />
			</RowContainer>
		</ColumnContainer>
	);
}
