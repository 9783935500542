import {
	BATTERY_ASSETS_METRICS,
	CHARGING_STATION_METRICS,
	DASHBOARD_METRICS,
	SWAPPING_STATION_METRICS,
} from "../constants/url.constants";
import { handleErrorResponse } from "../util/util";
import { serviceAPI } from "./service";

export const dashboardMetrics = async () => {
	try {
		const response = await serviceAPI.get(DASHBOARD_METRICS);
		return response.data;
	} catch (error) {
		handleErrorResponse(error);
	}
};

export const batteryAssetsMetrics = async () => {
	try {
		const response = await serviceAPI.get(BATTERY_ASSETS_METRICS);
		return response.data;
	} catch (error) {
		handleErrorResponse(error);
	}
};

export const swappingStationMetrics = async (range) => {
	try {
		const response = await serviceAPI.get(SWAPPING_STATION_METRICS, {
			params: {
				time_range: range,
			},
		});
		return response.data;
	} catch (error) {
		handleErrorResponse(error);
	}
};

export const chargingStationMetrics = async (range) => {
	try {
		const response = await serviceAPI.get(CHARGING_STATION_METRICS, {
			params: {
				time_range: range,
			},
		});
		return response.data;
	} catch (error) {
		handleErrorResponse(error);
	}
};
