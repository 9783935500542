import React from 'react'
import { useNavigate } from 'react-router-dom';
import { FloatingActionButton } from './button';
import { ChevronLeft } from '@mui/icons-material';
import enzi_theme from '../../theme/theme';

export default function BackButton() {

    const navigator = useNavigate()

    return (
        <div>
            <FloatingActionButton
                padding='2px'
                icon={<ChevronLeft color='secondary' />}
                // backgroundColor={enzi_theme.palette.background.default}
                onclick={() => navigator(-1)} />
        </div>
    )
}
