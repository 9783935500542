import React from "react";
import { ColumnContainer } from "../global/containers";
import { CircularProgress } from "@mui/material";
import enzi_theme from "../../theme/theme";
import { CustomText } from "../global/text";

export default function LoadingOverlay({
  height = "auto",
  loadingMessage = "Loading...",
}) {
  return (
    <ColumnContainer
      width="100%"
      height={height}
      alignItems="center"
      justifyContent="center"
      extraSX={{ backgroundColor: enzi_theme.palette.common.white }}
    >
      <CircularProgress color="secondary" />
      <CustomText
        variant="caption"
        color="secondary"
        textAlign="center"
        text={loadingMessage}
        width="100%"
      />
    </ColumnContainer>
  );
}
