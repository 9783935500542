import React from "react";
import { CardContainer, RowContainer } from "../global/containers";
import InfoDetails from "../global/infoText";
import { formatDate } from "../../util/util";

export default function PairedBatteryInformationCard({batteryInfo ={}}) {
  const pairedDate = batteryInfo?.station_info?.paired_date ? formatDate(batteryInfo?.station_info?.paired_date) : "-"
  return (
    <CardContainer
      title="Paired Battery"
      width={{ xs: "100%", lg: "33%" }}
      height="100%"
      childrenContainerWidth="95%"
      extraSX={{
        borderRadius: "10px",
      }}
    >
      <RowContainer
        justifyContent="space-between"
        padding="0px"
        marginBottom="0px"
      >
        <InfoDetails label={"Battery VIN/ID"} width="35%" value={batteryInfo?.bat_sn} />
        <InfoDetails label={"Paired Date"} width="35%" value={pairedDate } alignItems={{ xs: "flex-start", lg: "end" }} />
      </RowContainer>
      <RowContainer
        justifyContent="space-between"
        padding="0px"
        marginBottom="0px"
      >
        <InfoDetails label={"Current Status"} width="35%" value={batteryInfo?.state} />
        <InfoDetails
          label={"Latest Payment"}
          width="35%"
          value={`${batteryInfo?.latest_payment ?? "-"} KES`}
          alignItems={{ xs: "flex-start", lg: "end" }}
        />
      </RowContainer>
      <RowContainer
        justifyContent="space-between"
        padding="0px"
        marginBottom="0px"
      >
        <InfoDetails label={"Paired Station"} width="35%" value={batteryInfo?.station_info?.paired_station} />
        <InfoDetails
          label={"Current Charge"}
          width="35%"
          value={`${batteryInfo?.data?.cap ?? "-" } %`}
          alignItems={{ xs: "flex-start", lg: "flex-end" }}
        />
      </RowContainer>
    </CardContainer>
  );
}
