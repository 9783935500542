import React, { useCallback, useEffect, useState } from 'react';
import { ColumnContainer } from '../components/global/containers';
import Map from '../components/global/map';
import TopBar from '../components/global/topBar';
import AssetMapForm from '../components/forms/assetMapForm';
import AssetsKeys from '../components/cards/assetsKeys';
import { Box } from '@mui/material';
import {
	getAssetsCoordinates,
	getLocationName,
	getVehicleLocations,
} from '../services/dashboardService';

export default function AssetMap() {
	const [markersList, setMarkersList] = useState([]);
	const [filteredMarkersList, setFilteredMarkersList] = useState([]);
	const [selectedMarker, setSelectedMarker] = useState(null);
	const [getLocation, setGetLocation] = useState(false);

	const updateVehicleLocations = useCallback(() => {
		getVehicleLocations().then((res) => {
			const newList = markersList.filter(
				(item) =>
					item.asset.toLowerCase() !== 'bike' &&
					item.asset.toLowerCase() !== 'tuk'
			);
			setMarkersList([...newList, ...res]);
			setFilteredMarkersList([...newList, ...res]);
		});
	}, [markersList, setMarkersList, setFilteredMarkersList]);

	useEffect(() => {
		const intervalId = setInterval(() => {
			updateVehicleLocations();
		}, 120 * 1000);
		return () => clearInterval(intervalId);
	}, [updateVehicleLocations]);

	const getMapData = useCallback(() => {
		Promise.all([getAssetsCoordinates(), getVehicleLocations()]).then(
			([assets, vehicles]) => {
				const list = [...assets, ...vehicles];
				setMarkersList(list);
				setFilteredMarkersList(list);
			}
		);
	}, []);

	useEffect(() => {
		getMapData();
	}, [getMapData]);

	useEffect(() => {
		if (selectedMarker && getLocation) {
			getLocationName(selectedMarker.lat, selectedMarker.lon)
				.then((res) => {
					setSelectedMarker({
						...selectedMarker,
						location: res,
					});
				})
				.finally(() => setGetLocation(false));
		}
	}, [getLocation, markersList, selectedMarker]);

	// const toggleModal = () => {
	//     setModalOpen((prev) => !prev)
	//     getMapData()
	//     setSelectedMarker(null)
	// }

	// const handleEditClick = () => {
	//     setEditData(null)
	//     switch(selectedMarker?.asset.toLowerCase()) {
	//         case 'battery':
	//             // get the battery data and populate the battery form
	//             getBatteryDetail(selectedMarker.id)
	//                 .then((res) => setEditData(res))
	//                 .finally(() => setModalOpen(true))
	//             break;
	//         case 'swapping station':
	//             // get the station data and populate the station form
	//             getSwapStationDetails(selectedMarker?.searchId)
	//                 .then((res) => setEditData(res))
	//                 .finally(() => setModalOpen(true))
	//             break;
	//         case 'charging station':
	//             // get the charge station data and populate the charge station form
	//             getChargeStationDetails(selectedMarker.searchId)
	//                 .then((res) => setEditData(res))
	//                 .finally(() => setModalOpen(true))
	//             break;
	//         case 'bike':
	//             // get the bike data and populate the bike form
	//             getElectricVehiclesInfo(selectedMarker.searchId)
	//                 .then((res) => setEditData(res))
	//                 .finally(() => setModalOpen(true))
	//             break;
	//         case 'tuk':
	//             // get the tuk data and populate the bike form
	//             getElectricVehiclesInfo(selectedMarker.searchId)
	//                 .then((res) => setEditData(res))
	//                 .finally(() => setModalOpen(true))
	//             break;
	//         default:
	//             setModalOpen(false)
	//             break;
	//     }
	// }

	// const handleDeleteClick = () => {
	//     setDeleteData(selectedMarker)
	//     setDeleteModalOpen(true)
	// }

	// const cleanUpAfterDelete = () => {
	//     setDeleteData(null)
	//     setDeleteModalOpen(false)
	//     setMarkersList(markersList.filter(marker => marker.searchId !== deleteData?.searchId))
	//     setFilteredMarkersList(markersList.filter(marker => marker.searchId !== deleteData?.searchId))
	// }

	// const deleteAsset = () => {
	//     switch(selectedMarker?.asset.toLowerCase()) {
	//         case 'battery':
	//             // delete battery
	//             deleteBattery(selectedMarker.searchId)
	//                 .then((res) => setSelectedMarker(null))
	//                 .finally(() => cleanUpAfterDelete())
	//             break;
	//         case 'swapping station':
	//             // delete swapping station
	//             deleteSwapStation(selectedMarker?.searchId)
	//                 .then((res) => setSelectedMarker(null))
	//                 .finally(() => cleanUpAfterDelete())
	//             break;
	//         case 'charging station':
	//             // delete charging station
	//             deleteChargeStation(selectedMarker?.searchId)
	//                 .then((res) => setSelectedMarker(null))
	//                 .finally(() => cleanUpAfterDelete())
	//             break;
	//         case 'bike':
	//             // delete bike
	//             deleteElectricVehicles(selectedMarker?.searchId)
	//                 .then((res) => setSelectedMarker(null))
	//                 .finally(() => cleanUpAfterDelete())
	//             break;
	//         case 'tuk':
	//             // delete tuk
	//             deleteElectricVehicles(selectedMarker?.searchId)
	//                 .then((res) => setSelectedMarker(null))
	//                 .finally(() => cleanUpAfterDelete())
	//             break;
	//         default:
	//             setDeleteModalOpen(false)
	//             break;
	//     }
	// }

	return (
		<ColumnContainer
			height="100%"
			marginBottom="0px"
			padding="0px"
			extraSX={{
				overflowY: 'auto',
				position: 'relative',
			}}
		>
			<TopBar marginBottom="0px">
				<AssetMapForm
					setFilteredMarkersList={setFilteredMarkersList}
					markersList={markersList}
				/>
			</TopBar>
			<Box
				zIndex={1}
				display={'flex'}
				alignItems={'end'}
				flexDirection={'column'}
				gap={1}
				position={'absolute'}
				bottom={'4%'}
				right={'5%'}
			>
				{/* {!getLocation && selectedMarker && 
                    <TrackerItemOverlay
                        marker={selectedMarker}
                        setSelectedMarker={setSelectedMarker}
                        handleEditClick={handleEditClick}
                        handleDeleteClick={handleDeleteClick}
                    />
                } */}
				<AssetsKeys />
			</Box>
			{filteredMarkersList && (
				<Map
					key={JSON.stringify(filteredMarkersList)}
					markers={filteredMarkersList}
					setSelectedMarker={setSelectedMarker}
					setGetLocation={setGetLocation}
				/>
			)}

			{/**

            {selectedMarker && selectedMarker.asset.toLowerCase() === 'battery' && <EditBatteryAssetInfoModal battery={editData} modalOpen={modalOpen} toggleModal={toggleModal} />}

            {selectedMarker && selectedMarker.asset.toLowerCase() === 'swapping station' && <EditStationModal swapStation={editData} modalOpen={modalOpen} toggleModal={toggleModal} />}

            {selectedMarker && selectedMarker.asset.toLowerCase() === 'charging station' && <EditChargeStation chargeStation={editData} modalOpen={modalOpen} toggleModal={toggleModal} />}

            {selectedMarker && ['bike', 'tuk'].includes(selectedMarker.asset.toLowerCase()) && <EditBikeAssetModal bikeAsset={editData} modalOpen={modalOpen} toggleModal={toggleModal} />}

            {selectedMarker && (
                <GenericDeleteModal
                    modalOpen={deleteModalOpen}
                    toggleModal={() => setDeleteModalOpen(false)}
                    itemToDelete={deleteData}
                    handleDeleteAction={deleteAsset}
                    deleteFunction={null}
                    itemType={selectedMarker.asset}
                    itemName={selectedMarker.id}
                />
            )}

            **/}
		</ColumnContainer>
	);
}
