import React from "react";
import {
  CardContainer,
  ColumnContainer,
  RowContainer,
} from "../global/containers";
import InfoDetails from "../global/infoText";
import { Avatar } from "@mui/material";
import { FloatingActionButton } from "../global/button";

export default function BikeAssetRegisteredRiderCard({ownerInfo ={}}) {
  
  return (
    <CardContainer
      title="Paired Rider Information"
      width={{ xs: "100%", lg: "33%" }}
      height={{ xs: "auto", lg: "100%" }}
      extraSX={{
        position: "relative",
      }}
      childrenContainerWidth="95%"
    >
      <RowContainer
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", lg: "center" }}
        padding="0px"
        marginBottom="0px"
        extraSX={{
          flexDirection: { xs: "column", lg: "row" },
          flexWrap: { xs: "wrap", lg: "nowrap" },
        }}
      >
        <Avatar sx={{ width: "200px", height: "200px" }} />
        <ColumnContainer
          justifyContent="flex-start"
          alignItems="flex-start"
          padding="0px"
          width={{ xs: "100%", lg: "50%" }}
        >
          <InfoDetails
            label={"Full Name"}
            value={`${ownerInfo?.first_name ?? ""} ${ownerInfo?.last_name ?? ""}`}
            alignItems="flex-start"
          />
          <InfoDetails
            label={"Contact Number"}
            value={ownerInfo?.phone_number}
            alignItems="flex-start"
          />
        </ColumnContainer>
      </RowContainer>
    </CardContainer>
  );
}
