import React from "react";
import { Box, Avatar, Typography } from "@mui/material";
import { Delete, Edit, Visibility } from "@mui/icons-material";
import { FloatingActionButton } from "../global/button";
import enzi_theme from "../../theme/theme";
import InfoDetails from "./infoText";

export default function StationAttendantItem({
	attendant,
	userType,
	viewAttendant,
	deleteModal,
	editAttendant,
}) {
	if (!attendant) return null;

	const { name = "N/A", contact_number = "N/A" } = attendant;

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				backgroundColor: enzi_theme.palette.background.default,
				borderRadius: "8px",
				boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
				padding: 2,
				marginBottom: 2,
			}}
		>
			{/* Top row: Avatar, Name, and Contact Number */}
			<Box
				sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}
			>
				<Avatar
					sx={{
						width: 60,
						height: 60,
						fontSize: "1.5rem",
						marginRight: 2,
						bgcolor: enzi_theme.palette.secondary.main,
					}}
				>
					{name.charAt(0)}
				</Avatar>
				<Box
					sx={{
						flexGrow: 1,
						display: "flex",
						justifyContent: "space-between",
					}}
				>
					<Box>
						<InfoDetails
							label="Attendant Name"
							value={name || "N/A"}
						/>
					</Box>
					<Box>
						<InfoDetails
							label="Contact Number"
							value={contact_number || "N/A"}
						/>
					</Box>
				</Box>
			</Box>

			{/* Bottom row: Role and Action Buttons */}
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<Box>
					<InfoDetails label="Role" value={userType || "N/A"} />
				</Box>
				<Box sx={{ display: "flex", gap: 1 }}>
					<FloatingActionButton
						icon={<Visibility sx={{ fontSize: "1rem" }} />}
						onclick={() => viewAttendant(attendant)}
						backgroundColor={enzi_theme.palette.info.main}
					/>
					<FloatingActionButton
						icon={<Edit sx={{ fontSize: "1rem" }} />}
						onclick={() => editAttendant(attendant)}
						backgroundColor={enzi_theme.palette.warning.main}
					/>
					<FloatingActionButton
						icon={<Delete sx={{ fontSize: "1rem" }} />}
						onclick={() => deleteModal(attendant)}
						backgroundColor={enzi_theme.palette.error.main}
					/>
				</Box>
			</Box>
		</Box>
	);
}
