import { createSlice } from '@reduxjs/toolkit';
const initialState = { type: null, message: null };

export const messageSlice = createSlice({
	name: 'message',
	initialState: initialState,
	reducers: {
		SET_MESSAGE: (state, action) => {
			return { ...state, ...action.payload };
		},
		CLEAR_MESSAGE: () => initialState,
	},
});

export const { SET_MESSAGE, CLEAR_MESSAGE } = messageSlice.actions;

export default messageSlice.reducer;
