import React, { useEffect, useState } from "react";
import { RowContainer } from "../global/containers";
import StatItem from "../global/statItem";
import { dashboardMetrics } from "../../services/metricsService";

export default function StatisticsCard() {
  const [loading, setIsLoading] = useState(false);
  const [activeBatteries, setActiveBatteries] = useState("_");
  const [dispatches, setDispatches] = useState("_");
  const [revenue, setRevenue] = useState("_");
  const [revPerBattery, setRevPerBattery] = useState("_");
  const cards = [
    {
      title: "Total Active Batteries",
      value: activeBatteries,
    },
    {
      title: "Month’s Completed Dispatches",
      value: dispatches,
    },
    {
      title: "Current Month’s Revenue",
      value: revenue,
    },
    {
      title: "Average Revenue per Battery",
      value: revPerBattery,
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    dashboardMetrics().then((response) => {
      setActiveBatteries(response?.activeBatteries ?? 0);
      setDispatches(response?.monthDispatches ?? 0);
      setRevenue(response?.monthRevenue ?? 0);
      setRevPerBattery(response?.monthRevPerBatt ?? 0);
    });
    setIsLoading(false);
  }, []);

  return (
    <RowContainer
      justifyContent="space-between"
      width="100%"
      responsive={false}
      extraSX={{ gap: 1 }}
    >
      {cards.map((card, index) => (
        <StatItem
          key={index}
          width="25%"
          title={card.title}
          loading={loading}
          value={card.value}
        />
      ))}
    </RowContainer>
  );
}
