import React, { useCallback, useEffect, useState } from 'react';
import {
	CardContainer,
	ColumnContainer,
	RowContainer,
} from '../global/containers';
import InfoDetails from '../global/infoText';
import { FloatingActionButton } from '../global/button';
import { Edit } from '@mui/icons-material';
import EditBatteryAssetInfoModal from '../modals/editBatteryAssetInfoModal';
import { getBatteryDetail } from '../../services/batteryService';
import LoadingOverlay from '../overlays/loading';

export default function BatteryInformationCard({ bat_sn }) {
	const [modalOpen, setModalOpen] = useState(false);

	const [isLoading, setIsLoading] = useState(false);
	const [isEmptyMessage] = useState('Loading...');
	const [selectedBattery, setSelectedBattery] = useState({});
	const [refresh, setRefresh] = useState(false);

	const toggleModal = () => {
		if (modalOpen) {
			setRefresh((prev) => !prev);
		}
		setModalOpen((prev) => !prev);
	};

	const fetchBatteryDetails = useCallback(() => {
		setIsLoading(true);
		getBatteryDetail(bat_sn)
			.then((response) => {
				setSelectedBattery(response);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [bat_sn, setIsLoading, setSelectedBattery]);

	useEffect(() => {
		if (refresh) {
			fetchBatteryDetails();
		}
		return () => {
			if (refresh) {
				fetchBatteryDetails();
			}
		};
	}, [bat_sn, fetchBatteryDetails, refresh]);

	useEffect(() => {
		fetchBatteryDetails();
	}, [fetchBatteryDetails]);

	return (
		<CardContainer
			title="Basic Battery Information"
			width={{ xs: '100%', lg: '40%' }}
			height={{ xs: 'auto', lg: '100%' }}
			extraSX={{
				borderRadius: '10px',
			}}
			childrenContainerWidth="97%"
		>
			{isLoading ? (
				<LoadingOverlay height="100%" message={isEmptyMessage} />
			) : (
				<>
					<ColumnContainer
						padding="0px"
						width="100%"
						alignItems="space-between"
						overflowY="auto"
						maxHeight="500px"
					>
						<RowContainer
							justifyContent="space-between"
							padding="0px"
							marginBottom="0px"
							alignItems="start"
							width="100%"
							extraSX={{ flexWrap: { xs: 'wrap', lg: 'nowrap' } }}
						>
							<InfoDetails
								label={'Battery Serial'}
								value={selectedBattery.bat_sn}
							/>
							<InfoDetails
								label={'Current Charge'}
								value={selectedBattery?.data?.soc}
								alignItems="flex-end"
							/>
						</RowContainer>
						<RowContainer
							justifyContent="space-between"
							padding="0px"
							marginBottom="0px"
							alignItems="start"
							width="100%"
							extraSX={{ flexWrap: { xs: 'wrap', lg: 'nowrap' } }}
						>
							<InfoDetails
								label={'Battery Model'}
								value={selectedBattery?.battery_model_name}
							/>
							<InfoDetails
								label={'Battery Type'}
								alignItems="flex-end"
								value={`${selectedBattery?.battery_type_name}`}
							/>
						</RowContainer>
						<RowContainer
							justifyContent="space-between"
							padding="0px"
							marginBottom="0px"
							alignItems="start"
							width="100%"
							extraSX={{ flexWrap: { xs: 'wrap', lg: 'nowrap' } }}
						>
							<InfoDetails
								label={'Battery Capacity'}
								value={`${selectedBattery?.battery_capacity} Kwh`}
							/>
							<InfoDetails
								label={'Current Deployment Status'}
								value={selectedBattery?.deployed ? 'Deployed' : 'Not Deployed'}
								alignItems="flex-end"
							/>
						</RowContainer>
						<RowContainer
							justifyContent="space-between"
							padding="0px"
							marginBottom="0px"
							alignItems="start"
							width="100%"
							extraSX={{ flexWrap: { xs: 'wrap', lg: 'nowrap' } }}
						>
							<InfoDetails
								label={'Completed Mileage'}
								value={`${selectedBattery?.distance_covered} KM`}
							/>
							<InfoDetails
								label={'Battery Cycle Count'}
								value={selectedBattery?.data?.cycles}
								alignItems="flex-end"
							/>
						</RowContainer>

						<RowContainer justifyContent="end" padding="0px" marginBottom="0px">
							<FloatingActionButton
								icon={<Edit color="secondary" />}
								onclick={toggleModal}
							/>
						</RowContainer>

						<EditBatteryAssetInfoModal
							battery={selectedBattery}
							modalOpen={modalOpen}
							toggleModal={toggleModal}
						/>
					</ColumnContainer>
				</>
			)}
		</CardContainer>
	);
}
