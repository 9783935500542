import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { ColumnContainer, RowContainer } from '../global/containers'
import { FormInput, FormSelect } from '../global/form'



const EV_TYPE = {
    Bike: 1,
    Tuk: 2,
};

function AddBikeAssertForm({ bikeAsset, isEdit=false }, ref) {

    const evTypeRef = useRef();
    const modelNoRef = useRef();
    const motorSerialRef = useRef();
    const registrationRef = useRef();
    const chasisNoRef = useRef()
    const ECISerialNoRef = useRef();

    const [errors, setErrors] = useState({});

    const validate = () => {
        const newErrors = {};
        
		if (!evTypeRef.current?.getState() ) {
            newErrors.evType = "Ev is required.";
		}
		if (!modelNoRef.current?.getState()) {
			newErrors.modelNo = "Model is required.";
		} 
		if (!isEdit && !motorSerialRef.current?.getState()) {
			newErrors.motorSerialNo = "Motor Serial is required.";
		} 
		if (!registrationRef.current?.getState()) {
			newErrors.registration = "Registration is required.";
		} 
        if (!chasisNoRef.current?.getState()){
            newErrors.chasis = "Chasis Number is required.";
        }
        if (!isEdit && !ECISerialNoRef.current?.getState()){
            newErrors.eci = "ECI Serial Number is required.";
        }
		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
    }

    const getData = () => {
        if(validate()){
            return {
                id: bikeAsset?.id,
                eci: bikeAsset?.eci?.id,
                ev_type: EV_TYPE[evTypeRef.current.getState()],
                Eci_sn: !isEdit ? ECISerialNoRef.current.getState() : bikeAsset.ECI_Sn,
                motor_model_number:  modelNoRef.current.getState(),
                chasis_number: chasisNoRef.current.getState(),
                serial_number: !isEdit ? motorSerialRef.current.getState() : bikeAsset?.serial_number,
                registration_number: registrationRef.current.getState()
            }
        }
        return null;
    }

    useImperativeHandle(ref, () => ({ getData }));

    return (
        <ColumnContainer
            justifyContent='start'
            padding='0px'
        >
            <RowContainer
                justifyContent='space-between'
                marginBottom='0px'
                padding='10px 0px'
            >
                <FormSelect
                    ref={evTypeRef}
                    required={true}
                    label='EV Asset *'
                    width={'49%'}
                    placeholder={'Select EV Asset'}
                    value={Object.keys(EV_TYPE).find(key => EV_TYPE[key] === bikeAsset?.ev_type)}
                    options={['Bike', 'Tuk']}
                    error={errors.evType}
                />
                <FormInput
                    ref={modelNoRef}
                    required={true}
                    label='Motor Model Number *' 
                    width={'49%'}
                    placeholder={"Enter Model Number"}
                    type="number"
                    value={bikeAsset?.motor_model_number}
                    error={errors.modelNo}
                />
            </RowContainer>
            <ColumnContainer
                alignItems='start'
            >
                <RowContainer
                    justifyContent='space-between'
                    marginBottom='0px'
                    padding='10px 0px'
                >

                    {!isEdit && <FormInput
                        ref={motorSerialRef}
                        required={true}
                        label='Motor Serial Number *'
                        placeholder={"Enter Serial Number"}
                        width={"49%"}
                        type="number"
                        value={bikeAsset?.serial_number}
                        error={errors.motorSerialNo}
                    />}
                    <FormInput
                        ref={registrationRef}
                        required={true}
                        width={isEdit ? "100%" : "49%"}
                        label='Registration Number *'
                        placeholder={"Enter Registration Number"}
                        type="text"
                        value={bikeAsset?.registration_number}
                        error={errors.registration}
                    />
                </RowContainer>

                <RowContainer
                    justifyContent='space-between'
                    marginBottom='0px'
                    padding='0px 0px'
                >
                    <FormInput
                        ref={chasisNoRef}
                        required={true}
                        width={isEdit ? "100%" : "49%"}
                        label='Chasis Number *'
                        placeholder={"Enter Chasis Number"}
                        type="text"
                        value={bikeAsset?.chasis_number}
                        error={errors.chasis}
                    />
                    {!isEdit && <FormInput
                        ref={ECISerialNoRef}
                        required={true}
                        width={"49%"}
                        label='ECI Serial Number *'
                        placeholder={"Enter ECI Serial Number"}
                        type="text"
                        value={bikeAsset?.eci?.ECI_Sn}
                        disabled={isEdit}
                        error={errors.eci}
                    />}
                </RowContainer>
            </ColumnContainer>
        </ColumnContainer>
    )
}

export default forwardRef(AddBikeAssertForm);
