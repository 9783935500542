import React, { useRef, useState } from 'react'
import { ModalContainer } from '../global/containers'
import AddNewBatteryAssetForm from '../forms/AddNewBatteryAssetForm'
import { editBattery } from '../../services/batteryService'

export default function EditBatteryAssetInfoModal({modalOpen,toggleModal,battery}) {
    const handleSubmitRef = useRef() 
    const [isError, setIsError] = useState(false)

    const handleSubmit = () => {
        const batteryData = handleSubmitRef.current.getData();
        if(validateEditData(batteryData)) {
            editBattery(batteryData,batteryData.id)
                .then((res)=>{
                    toggleModal()          
                })
        } else {
            setIsError(true)
            setTimeout(() => setIsError(false), 5000);
        }
    }

    const validateEditData = (data) => {
        if (data) {
            if(data.tel && data.bat_sn && data.battery_model && data.battery_type) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }

    return (
        <ModalContainer
            title={`Edit Battery Asset #${battery?.bat_sn}`}
            modalOpen={modalOpen}
            height="auto"
            width={{xs: '80vw', lg: '47vw'}}
            handleCloseModal={toggleModal}
            handleCancelClick={toggleModal}
            handleActionClick={handleSubmit}
        >
            {isError && <p style={{color: 'red'}}>Please fill out all required fields. (*)</p>}
            <AddNewBatteryAssetForm ref={handleSubmitRef} batteryData={battery} task="edit"/>
        </ModalContainer>
    )
}
