import React from "react";
import {
	ColumnContainer,
	ModalContainer,
	RowContainer,
} from "../global/containers";
import InfoDetails from "../global/infoText";

export default function DispatchOperationsModal({
	modalOpen,
	toggleModal,
	selectedDispatch,
	chargingStation, // Prop passed from one component
	swappingStation, // Prop passed from another component
}) {
	// Check if selectedDispatch is defined before rendering
	if (!selectedDispatch) {
		return null; // Or return a loading state if preferred
	}

	// Use either chargingStation or swappingStation
	const station = chargingStation || swappingStation;

	return (
		<ModalContainer
			title={`Dispatch Operation: #${selectedDispatch.id || "N/A"}`} // Use the ID from selectedDispatch
			modalOpen={modalOpen}
			height="auto"
			type="in-actionable"
			handleCloseModal={toggleModal}
			handleCancelClick={toggleModal}
			handleActionClick={toggleModal}
		>
			<RowContainer
				justifyContent="space-between"
				alignItems="flex-start"
				width="100%"
				style={{ flexWrap: "nowrap" }}
			>
				<ColumnContainer
					padding="0px"
					width="48%"
					alignItems="flex-start"
				>
					<InfoDetails
						label={"Dispatch Date"}
						value={selectedDispatch.operation_date || "N/A"}
					/>
					<InfoDetails
						label={"Received Batteries"}
						value={selectedDispatch.batteries_received || "N/A"}
					/>

					<InfoDetails
						label={"Returned Batteries"}
						value={selectedDispatch.batteries_sent || "N/A"}
					/>
				</ColumnContainer>
				<ColumnContainer
					padding="0px"
					width="48%"
					alignItems="flex-start"
				>
					<InfoDetails
						label={"Swap Station Attendant"}
						value={"John doe"}
					/>

					<InfoDetails
						label={"Charge Station Attendant"}
						value={"Mary doe"}
					/>

					<InfoDetails
						label={"Requested Charge Station"}
						value={station?.name || "N/A"}
					/>
				</ColumnContainer>
			</RowContainer>
		</ModalContainer>
	);
}
