import React from "react";
import { ColumnContainer } from "../components/global/containers";
import TopBar from "../components/global/topBar";
import BatteryStaticsCard from "../components/cards/batteryStaticsCard";
import BatteryListCard from "../components/cards/batteryListCard";
import BackButton from "../components/global/backButton";

export default function BatteryAssetsListPage() {
  return (
    <ColumnContainer>
      <TopBar>
        <BackButton/>
      </TopBar>
      <BatteryStaticsCard />
      <BatteryListCard />
    </ColumnContainer>
  );
}
