import { Typography } from "@mui/material";

export function CustomText({
  id = "custom-text",
  text = "Text Example",
  variant = "body1",
  color = "primary",
  fontWeight = "600",
  textAlign = "start",
  marginBottom = "10px",
  extraSX = {},
}) {
  return (
    <Typography
      id={id}
      variant={variant}
      color={color}
      fontWeight={fontWeight}
      textAlign={textAlign}
      marginBottom={marginBottom}
      sx={extraSX}
    >
      {text}
    </Typography>
  );
}
