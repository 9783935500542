//SWAP STATION ENDPOINTS
export const SWAP_STATION = `${process.env.REACT_APP_API_URL}/swapping_station/`;
export const SAVE_SWAP_STATION = `${process.env.REACT_APP_API_URL}/swapping_station/add_swap_station/`;
export const EDT_SWAP_STATION = `${process.env.REACT_APP_API_URL}/swapping_station/edt_swap_station/`;
export const DEL_SWAP_STATION = `${process.env.REACT_APP_API_URL}/swapping_station/del_swap_station/`;
export const LIST_SWAP_STATION = `${SWAP_STATION}list`;
export const SWAP_STATION_DETAILS = `${process.env.REACT_APP_API_URL}/swapping_station/users/`;
export const SWAP_STATION_ADD_USER = `${process.env.REACT_APP_API_URL}/swapping_station/add_user/`;
export const SWAP_STATION_EDT_USER = `${process.env.REACT_APP_API_URL}/swapping_station/edit_user/`;
export const SWAP_STATION_DEL_USER = `${process.env.REACT_APP_API_URL}/swapping_station/del_user/`;
export const SWAP_STATION_OPERATIONS = `${process.env.REACT_APP_API_URL}/swapping_station/operations/`;
//BATTERY ENDPOINTS
export const BATTERIES = `${process.env.REACT_APP_API_URL}/battery/`;
export const ADD_BATTERY = `${process.env.REACT_APP_API_URL}/battery/add_battery/`;
export const EDT_BATTERY = `${process.env.REACT_APP_API_URL}/battery/edt_battery/`;
export const DEL_BATTERY = `${process.env.REACT_APP_API_URL}/battery/del_battery/`;
export const BATTERY_LIST = `${BATTERIES}list`;
export const BATTERY_UNSIGNED_lIST = `${BATTERIES}battery_unassigned_list`;
export const BATTERY_DETAIL = BATTERIES + "detail/";
export const BATTERY_DEPLOYMENTS = BATTERIES + "deployments/";
export const LAST_BATTERY_SWAP_REPORT = `${process.env.REACT_APP_API_URL}/battery/last_swap/`;
export const DISABLE_BATTERY = BATTERIES + "kill_sms/";
export const GLOBAL_DISPATCH_OPERATIONS = BATTERIES +  "global_dispatch_operations";
//MAP ENDPOINTS
export const BATTERY_ASSETS_COORDINATES = BATTERIES + "map/";
export const GET_LOCATION_NAME = BATTERIES + "get_location_name/";
//CHARGE STATION ENDPOINTS
export const CHARGE_STATION = `${process.env.REACT_APP_API_URL}/charging_station/`;
export const CHARGE_STATION_DETAILS = `${process.env.REACT_APP_API_URL}/charging_station/users/`;
export const CHARGE_STATION_ADD = `${process.env.REACT_APP_API_URL}/charging_station/add_charge_station/`;
export const CHARGE_STATION_DEL = `${process.env.REACT_APP_API_URL}/charging_station/del_charge_station/`;
export const CHARGE_STATION_EDT = `${process.env.REACT_APP_API_URL}/charging_station/edt_charge_station/`;
export const CHARGE_STATION_ADD_USER = `${process.env.REACT_APP_API_URL}/charging_station/add_user/`;
export const CHARGE_STATION_EDT_USER = `${process.env.REACT_APP_API_URL}/charging_station/edit_user/`;
export const CHARGE_STATION_List = `${CHARGE_STATION}list`;
export const CHARGE_STATION_OPERATIONS = `${process.env.REACT_APP_API_URL}/charging_station/operations/`;
export const CHARGE_STATION_DEL_USER = `${process.env.REACT_APP_API_URL}/charging_station/del_user/`;
//ELECTRIC VEHICLES
export const ELECTRIC_VEHICLE = `${process.env.REACT_APP_API_URL}/electric_vehicle/`;
export const ELECTRIC_VEHICLE_LIST = `${ELECTRIC_VEHICLE}list`;
export const AVAILABLE_ELECTRIC_VEHICLE_LIST = `${ELECTRIC_VEHICLE}list`;
export const ADD_ELECTRIC_VEHICLE = `${process.env.REACT_APP_API_URL}/electric_vehicle/add_electric_vehicle/`;
export const EDT_ELECTRIC_VEHICLE = `${process.env.REACT_APP_API_URL}/electric_vehicle/edt_electric_vehicle/`;
export const DEL_ELECTRIC_VEHICLE = `${process.env.REACT_APP_API_URL}/electric_vehicle/del_electric_vehicle/`;
export const ELECTRIC_VEHICLE_STATS = `${ELECTRIC_VEHICLE}electric_vehicle_stats/`;
export const ELECTRIC_VEHICLE_INFO = `${ELECTRIC_VEHICLE}detail/`;
export const ELECTRIC_VEHICLE_TRIPS = `${ELECTRIC_VEHICLE}trips/`;
//  RIDERS
export const RIDERS = `${process.env.REACT_APP_API_URL}/driver/`;
export const RIDER_LIST = `${RIDERS}list/`;
export const RIDER_DETAILS = `${RIDERS}driver_details/`;
export const RIDER_TRANSACTION = `${RIDERS}transactions/`;
export const ADD_RIDER = `${RIDERS}add_driver/`
export const EDT_RIDER = `${RIDERS}edt_driver/`
export const RIDER_STATS = `${RIDERS}driver_stats/`
export const DEL_RIDER = `${RIDERS}del_driver/`

//METRICS
export const DASHBOARD_METRICS = `${process.env.REACT_APP_API_URL}/battery/dashboardMetrics`;
export const BATTERY_ASSETS_METRICS = `${process.env.REACT_APP_API_URL}/battery/batteryAssetsMetrics`;
export const CHARGING_STATION_METRICS = `${process.env.REACT_APP_API_URL}/charging_station/metrics`;
export const SWAPPING_STATION_METRICS = `${process.env.REACT_APP_API_URL}/swapping_station/metrics`;

//USER MANAGEMENT
export const USERS = `${process.env.REACT_APP_API_URL}/users/`;
export const CREATE_USER = `${USERS}create_user/`;
export const LIST_USER = `${USERS}user_list/`;
export const UPDATE_USER = `${USERS}update_user/`;
export const DELETE_USER = `${USERS}delete_user/`;


//ACCOUNTS
export const ACCOUNTS = `${process.env.REACT_APP_API_URL}/accounts/`;
export const PRINT_DOCUMENTS = `${ACCOUNTS}print_document`;
