import React, { useRef } from 'react'
import { ModalContainer } from '../global/containers'
import AddBikeAssertForm from '../forms/addBikeAssertForm'
import { addElectricVehicles } from '../../services/electricVehicle'

export default function AddBikeModal({ modalOpen = false, toggleModal }) {

  const handleSubmitRef = useRef()

  const handleSubmit = () => {
    const bikeData = handleSubmitRef.current.getData()
    onSubmit(bikeData)
  }

  const onSubmit = (data) => {
    if(data == null){
      return
    }
    addElectricVehicles(data)
      .then((res)=>{
        toggleModal()
      })
      .catch((err) => console.log(err))
  }

  return (
    <ModalContainer
      title='Add New Bike Asset'
      modalOpen={modalOpen}
      height="auto"
      actionButtonText="Add Bike"
      handleCloseModal={toggleModal}
      handleCancelClick={toggleModal}
      handleActionClick={handleSubmit}
    >
      <AddBikeAssertForm ref={handleSubmitRef} />
    </ModalContainer>
  )
}
