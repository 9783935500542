import React, { useCallback, useState } from "react";
import { ColumnContainer, ModalContainer } from "../global/containers";
import { Button } from "@mui/material";

export default function ModalContainerExample() {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = useCallback(
    async function () {
      setModalOpen(!modalOpen);
    },
    [modalOpen]
  );

  return (
    <ColumnContainer width="100%" height="auto">
      <Button
        variant="contained"
        color="secondary"
        onClick={toggleModal}
        sx={{ width: "300px", margin: "20px auto" }}
      >
        Trigger Modal
      </Button>
      <ModalContainer
        modalOpen={modalOpen}
        height="50vh"
        handleCloseModal={toggleModal}
        handleCancelClick={toggleModal}
        handleActionClick={toggleModal}
      ></ModalContainer>
    </ColumnContainer>
  );
}
