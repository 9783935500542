import React, { useCallback, useEffect, useState } from "react";
import { CardContainer, RowContainer } from "../global/containers";
import { SearchInput } from "../global/form";
import {
	CustomDataGrid,
	CustomDataGridActionsDropdownMenu,
} from "../global/customTable";
import { Add } from "@mui/icons-material";
import { FloatingActionButton } from "../global/button";
import AddNewUserModal from "../modals/addNewUserModal";
import EditUserModal from "../modals/editUserModal";
import { deleteUser, listUsers } from "../../services/userService";
import ConfirmationModal from "../modals/confirmationModal";
import { issueErrorMessage, issueSuccessMessage } from "../../util/util";

const USER_TYPES = [
	'Admin',
    'API USER',
    'Fleet Manager',
    'Fleet staff',
    'Security Manager',
    'Security staff',
    'Swap Station Franchisee',
    'Swap Station Attendant',
    'Bike Management',
    'Tuk Management',
    'Boda Driver',
    'Boda Owner',
    'Sacco Manager',
    'Finance',
    'Charge Station Franchisee',
    'Charge Station Attendant',
    'Manager'
]

export default function StaffManagmentList() {
	const userListColumns = [
		{
			field: "staffName",
			headerName: "Staff Name",
			width: 300,
		},
		{
			field: "userRole",
			headerName: "User Role",
			type: "singleSelect",
			valueOptions: USER_TYPES,	
			minWidth: 100,
			flex: 1,
		},
		{
			field: "email",
			headerName: "Email Address",
			minWidth: 100,
			flex: 1,
		},
		{
			field: "phoneNumber",
			headerName: "Phone Number",
			minWidth: 100,
			flex: 1,
		},
		{
			field: "is_staff",
			headerName: "Is Staff",
			type: "boolean",
			minWidth: 100,
			flex: 1,
		},
		{
			field: "actions",	
			headerName: "Actions",
			minWidth: 100,
			flex: 1,
			renderCell: (params) => (
				<CustomDataGridActionsDropdownMenu
					width="100px"
					menuOptions={[
						{
							label: "Edit",
							onClickHandler: () => handleEdit(params.row),
						},
						{
							label: "Delete",
							onClickHandler: () => handleDelete(params.row)
							
						}
					]}
				/>
			),
		},
	];

	const [AddmodalOpen, setAddModalOpen] = useState(false);
	const [editmodalOpen, setEditmodalOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [staffList, setStaffList] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
	const [staffToEdt, setStaffToEdt] = useState({})
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [userToDelete, setUserToDelete] = useState({})

	const toggleModalAddModal = useCallback(
		async function () {
			setAddModalOpen(!AddmodalOpen);
		},
		[AddmodalOpen]
	);

	const toggleModalEditModal = useCallback(
		async function () {
			setEditmodalOpen(!editmodalOpen);
		},
		[editmodalOpen]
	);

	const toggleDeleteModal = useCallback(
        async function () {
          setDeleteModalOpen(!deleteModalOpen);
        },
        [deleteModalOpen]
      );

	const fetchUserList = useCallback(async function () {
        setIsLoading(true);
        listUsers()
            .then((res) => {

                const formatedData = res.map((user) => {
                    const name = `${user?.first_name}  ${user?.last_name}`
                    return {
                        id: user.id,
						staffName: name,
						userRole: user?.user_type,
						email: user?.email_address,
						phoneNumber: user?.phone_number,
                        ...user
                    }
                })
                setStaffList(formatedData)
                setFilteredData(formatedData);

            })
            .catch((err) => {
                setStaffList([])
            })
            .finally(() => setIsLoading(false))
    }, []);

	const filterData = (ev) => {
        const params = ev.target.value.toLocaleLowerCase();   
    
        if (params === "") {
            return setFilteredData(staffList);
        }

        const filters = user => user?.first_name.toLowerCase().includes(params) || user?.last_name.toLowerCase().includes(params) || user?.email_address.toLowerCase().includes(params)
        const filteredData = staffList.filter(filters);
        setFilteredData(filteredData);
    }

	const handleEdit = (data) => {
		setStaffToEdt(data)
		toggleModalEditModal()
	}

	const handleDelete = (data) => {
		setUserToDelete(data)
		toggleDeleteModal()
	}

	const userDelete  = ( ) => {
		deleteUser(userToDelete.id)
			.then(res => {
				setStaffList(staffList.filter((value) => value.id !== userToDelete.id))
                setFilteredData(staffList.filter((value) => value.id !== userToDelete.id))
				issueSuccessMessage("User deleted succesfully");
				toggleDeleteModal()
			})
			.catch(err => {
				issueErrorMessage("User was not deleted");
			})
	}

    useEffect(() => {
        fetchUserList();
    }, [fetchUserList,AddmodalOpen,editmodalOpen]);

	return (
		<CardContainer
			title="All User"
			type="actionable"
			hasActionableComponent={true}
			height="100vh"
			width="100%"
			childrenContainerWidth="98%"
			actionableComponent={
				<RowContainer
					width={{ xs: "80%", sm: "50%", lg: "35%" }}
					padding="0px"
					marginBottom="8px"	
					justifyContent='flex-end'
				>
					<FloatingActionButton
						icon={<Add color="secondary" />}
						onclick={() => toggleModalAddModal()}
					/>
					<SearchInput
                        placeholder={"Search"}
                        filter={filterData}
                        type="text"
                        width={{ xs: "100%", md: "60%", lg: "70%" }}
                        backgroundColor="#FFFFFF"
                    />
				</RowContainer>
			}
		>
			<CustomDataGrid
				isLoading={isLoading}
				columns={userListColumns}
				rows={filteredData}
				height="100%"
				width="100%"
				isEmptyMessage="There are no Users assets to show"
			/>

			<AddNewUserModal
				modalOpen={AddmodalOpen}
				toggleModal={toggleModalAddModal}
			/>
			<EditUserModal
				toggleModal={toggleModalEditModal}
				modalOpen={editmodalOpen}
				staffMember={staffToEdt}
			/>
			<ConfirmationModal
				modalOpen={deleteModalOpen}
				toggleModal={toggleDeleteModal}
				onConfirmation={userDelete}
				itemType="User"
				itemName={ userToDelete?.first_name + " " + userToDelete?.last_name }
                confirmType="delete"
			/>
		</CardContainer>
	);
}
