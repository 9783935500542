import React, { useRef } from 'react';
import { ModalContainer } from '../global/containers';
import AddVehicleAssetForm from '../forms/addVehicleAssetForm';
import { editElectricVehicles } from '../../services/electricVehicle';

export default function EditVehicleAssetModal({
	modalOpen,
	toggleModal,
	vehicleAsset,
	setElectricVehicleInfo,
}) {
	const handleSubmitRef = useRef();

	const handleSubmit = () => {
		const vehicleData = handleSubmitRef.current.getData();
		onSubmit(vehicleData);
	};

	const onSubmit = (data) => {
		editElectricVehicles(data, data.id)
			.then((res) => {
				toggleModal();
				setElectricVehicleInfo(res);
			})
			.catch((err) => console.log(err));
	};

	
	return (
		<ModalContainer
			title={`Edit Electric Vehicle Asset #${vehicleAsset?.serial_number}`}
			modalOpen={modalOpen}
			height="auto"
			width="50%"
			handleCloseModal={toggleModal}
			handleCancelClick={toggleModal}
			handleActionClick={handleSubmit}
		>
			<AddVehicleAssetForm
				vehicleAsset={vehicleAsset}
				ref={handleSubmitRef}
				isEdit={true}
			/>
		</ModalContainer>
	);
}
