import React, { useCallback, useEffect, useState } from 'react'
import { CardContainer } from '../global/containers'
import { CustomDataGrid, CustomDataGridActionsDropdownMenu } from '../global/customTable'
import RiderTransactionModal from '../modals/riderTransactionModal'
import { useParams } from 'react-router-dom';
import { riderTransactions } from '../../services/riderService';
import { printDocument } from '../../services/accountsService';

export default function PastRiderTransactionsCard() {

  const pastRidertransactionListColumns = [
    {
      field: "amount",
      headerName: "Amount",
      minWidth: 100,
      flex: 1,
      valueFormatter: (value) => `${value} KES`
    },
    {
      field: "batteryVIN",
      headerName: "Battery SN",
      minWidth: 100,
      flex: 1
    },
    {
      field: "consumedEnergy",
      headerName: "Consumed Energy",
      minWidth: 100,
      flex: 1,
      valueFormatter: (value) => `${value} kwh`,
    },
    {
      field: "swapRecieve",
      headerName: "Swap Station (Received)",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "swapReturn",
      headerName: "Swap Station (Returned)",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <CustomDataGridActionsDropdownMenu
          menuOptions={[
            {
              label: "View",
              onClickHandler: () => viewData(params.row),
            },
            {
              label: "Download Invoice",
              onClickHandler: () => printInvoice(params.row),
            },
            {
              label: "Download Reciept",
              onClickHandler: () => printRecipt(params.row),
            }
          ]}
        />
      ),
    },
  ];
  const params = useParams()

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [isLoading, setIsLoading] = useState(false)
  const [history, setTransactionHistory] = useState([])

  const viewData = (data) => {
    setSelectedTransaction(data)
    toggleModal()
  }

  const fetchTransactions = useCallback(async function () {
    setIsLoading(true);
    riderTransactions(params.id)
      .then((res) => {
        const formatedData = res.map((transaction) => {
          return {
            id: Math.random(),
            amount: transaction?.bill_amount,
            batteryVIN: transaction?.battery_details?.bat_sn,
            consumedEnergy: transaction?.consumed_energy,
            swapRecieve: transaction?.received_by_swapping_station_details?.name,
            swapReturn: transaction?.issued_by_swapping_station_details?.name,
            ...transaction
          }
        })
        setTransactionHistory(formatedData);
      })
      .catch((err) => {
        setTransactionHistory([])
      })
      .finally(() => setIsLoading(false))
  }, []);

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions]);


  const toggleModal = useCallback(
    async function () {
      setModalOpen(!modalOpen);
    },
    [modalOpen]
  );

  const printInvoice = (data) => {
    const params = `?document_type=Invoice&document_id=${data.invoice.invoice_number}`
    printDocument(params)
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${data.invoice.invoice_number}.pdf`);  
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(err => {
        

      })
  }

  const printRecipt = (data) => {
    console.log(data.receipt.receipt_number);

    const params = `?document_type=Receipt&document_id=${data.receipt.receipt_number}`;
    printDocument(params)
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${data.receipt.receipt_number}.pdf`);  
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(err => {
        // console.log(err);

      })
  }

  return (
    <CardContainer
      title="Past Rider Transactions"
      width="97%"
      height='auto'
      extraSX={{ overflow: "Visible", }}
    >
      <CustomDataGrid
        isLoading={isLoading}
        columns={pastRidertransactionListColumns}
        rows={history}
        height="65vh"
        width="100%"
        isEmptyMessage="There are no battery assets to show"
      />
      <RiderTransactionModal data={selectedTransaction} toggleModal={toggleModal} modalOpen={modalOpen} />
    </CardContainer>
  )
}
