import React, { useCallback, useEffect, useState } from "react";
import { RowContainer } from "../global/containers";
import StatItem from "../global/statItem";
import { getElectricVehiclesStats } from "../../services/electricVehicle";

export default function BikeAssetStatistics() {

  const [stats,setStats] = useState()

  const fetchStats = useCallback( async function(){
    getElectricVehiclesStats()
      .then((res => {
        setStats(res)
      }))
  },[]);

  useEffect(() => {
    fetchStats()
  },[fetchStats])

  return (
    <RowContainer
      justifyContent="space-between"
      width="100%"
      extraSX={{ gap: 1, flexWrap: { xs: "wrap", lg: "nowrap" } }}
    >
      <StatItem title={"Total Active Bikes "} value={stats?.active_vehicles ?? "-"} />
      <StatItem title={"Average Daily Bike Mileage"} value={`${stats?.average_distance ?? "-"} KMs`} />
      <StatItem title={"Average Bike Consumption"} value={`${stats?.average_consumption  ?? "-"} %`} />
      <StatItem title={"Total Month’s Bikes Revenue"} value={`${stats?.months_revenue ?? "-"} KES`} />
    </RowContainer>
  );
}
