import React, {
	useCallback,
	useEffect,
	useState,
	useRef,
	useMemo,
} from "react";
import { CardContainer, RowContainer } from "../global/containers";
import { FloatingActionButton } from "../global/button";
import { FormInput, FormSelect } from "../global/form";
import { InputAdornment } from "@mui/material";
import { Add, Search } from "@mui/icons-material";
import {
	CustomDataGrid,
	CustomDataGridActionsDropdownMenu,
} from "../global/customTable";
import AddNewStationModal from "../modals/addNewStationModal";
import AddNewChargeStationForm from "../forms/addNewChargeStationForm";
import EditStationModal from "../modals/editStationModal";
import ConfirmationModal from "../modals/confirmationModal";
import { useNavigate } from "react-router-dom";
import {
	deleteChargeStation,
	addChargeStation,
} from "../../services/chargeStationService";
import { chargingStationMetrics } from "../../services/metricsService";
import { Typography } from "@mui/material";
import theme from "../../theme/theme";

export default function ChargeListCard({
	title = "All Charge Stations",
	height = "75vh",
	width = "100%",
	showTimeRange = true,
	showAddButton = true,
	showSearch = true,
	customColumns,
	onRowClick,
}) {
	const navigator = useNavigate();
	const searchRef = useRef();
	const [AddmodalOpen, setAddModalOpen] = useState(false);
	const [editModalOpen, setEditModalOpen] = useState(false);
	const [stationToEdit, setStationToEdit] = useState(null);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [stationToDelete, setStationToDelete] = useState(null);

	const [isLoading, setIsLoading] = useState(false);
	const [chargeStationList, setChargeStationList] = useState([]);
	const [filteredChargeStations, setFilteredChargeStations] = useState([]);
	const [timeRange, setTimeRange] = useState("month");

	const toggleModalAddModal = useCallback(() => {
		setAddModalOpen((prevState) => !prevState);
	}, []);

	const toggleModalEditModal = useCallback(() => {
		setEditModalOpen((prevState) => !prevState);
	}, []);

	const chargeStationListColumns = useMemo(
		() =>
			customColumns || [
				{
					field: "chargeStationName",
					headerName: "Charge Station Name",
					width: 300,
				},
				{
					field: "capacity",
					headerName: "Capacity",
					minWidth: 100,
					flex: 1,
				},
				{
					field: "monthsRevenue",
					headerName: `${
						timeRange.charAt(0).toUpperCase() + timeRange.slice(1)
					}'s Revenue`,
					minWidth: 100,
					flex: 1,
					valueFormatter: (value) => `${value} KES`,
				},
				{
					field: "batteries_dispatched",
					headerName: "Batteries Dispatched",
					minWidth: 100,
					flex: 1,
					valueFormatter: (value) => `${value} batteries`,
				},
				{
					field: "actions",
					headerName: "Actions",
					minWidth: 100,
					flex: 1,
					renderCell: (params) => (
						<CustomDataGridActionsDropdownMenu
							width="100px"
							menuOptions={[
								{
									label: "View",
									onClickHandler: () =>
										navigator(
											`/charge-station/${params.row.id}`,
											{
												state: {
													chargeStation: params.row,
												},
											}
										),
								},
								{
									label: "Edit",
									onClickHandler: () =>
										editBatteryInfo(params.row),
								},
								{
									label: "Delete",
									onClickHandler: () =>
										handleDelete(params.row),
								},
							]}
						/>
					),
				},
			]
	);

	const fetchChargeStationList = useCallback(async function (range) {
		setIsLoading(true);
		try {
			const res = await chargingStationMetrics(range);
			if (res && Array.isArray(res.stations)) {
				const formattedData = res.stations.map((station) => ({
					id: station.id,
					chargeStationName: station.name,
					dailySwapAverage: Number(
						(
							(station.g3_swaps + station.g5_swaps) /
							(range === "week" ? 7 : range === "day" ? 1 : 30)
						).toFixed(2)
					),
					monthsRevenue: parseFloat(station.revenue),
					battery_dispatched: station.battery_inventory_count,
					capacity: station.capacity,
					utilization: station.utilization_rate,
					powerConsumed: station.power_consumed,
					batteries_dispatched: station.batteries_dispatched,
					...station,
				}));
				setChargeStationList(formattedData);
			} else {
				setChargeStationList([]);
			}
		} catch (error) {
			console.error("Error fetching charging station metrics:", error);
			setChargeStationList([]);
		} finally {
			setIsLoading(false);
		}
	}, []);

	useEffect(() => {
		fetchChargeStationList(timeRange);
	}, [fetchChargeStationList, timeRange]);

	const editBatteryInfo = useCallback((data) => {
		setStationToEdit(data);
		setEditModalOpen(true);
	}, []);

	const handleDelete = useCallback((chargeStation) => {
		setStationToDelete(chargeStation);
		setDeleteModalOpen(true);
	}, []);

	const confirmDelete = useCallback(() => {
		if (stationToDelete) {
			deleteChargeStation(stationToDelete.id)
				.then((res) => {
					setChargeStationList((prevList) =>
						prevList.filter(
							(station) => station.id !== stationToDelete.id
						)
					);
					setDeleteModalOpen(false);
					setStationToDelete(null);
				})
				.catch((err) => {
					console.error("Error deleting charge station:", err);
				});
		}
	}, [stationToDelete]);

	const handleEditSuccess = useCallback(
		(updatedStation) => {
			setChargeStationList((prevList) =>
				prevList.map((station) =>
					station.id === updatedStation.id ? updatedStation : station
				)
			);
			fetchChargeStationList(timeRange);
		},
		[fetchChargeStationList, timeRange]
	);

	const handleAddSuccess = useCallback(() => {
		fetchChargeStationList(timeRange);
	}, [fetchChargeStationList, timeRange]);

	const filter = useCallback(
		(ev) => {
			const param = ev.target.value.toLowerCase();
			if (!param) {
				setFilteredChargeStations(chargeStationList);
				return;
			}
			const filters = (station) =>
				station.chargeStationName.toLowerCase().includes(param) ||
				station.capacity.toString().includes(param) ||
				station.monthsRevenue.toString().includes(param) ||
				station.batteries_dispatched.toString().includes(param);

			const formattedData = chargeStationList.filter(filters);
			setFilteredChargeStations(formattedData);
		},
		[chargeStationList]
	);

	useEffect(() => {
		setFilteredChargeStations(chargeStationList);
	}, [chargeStationList]);

	const handleTimeRangeChange = useCallback(
		(event) => {
			const newTimeRange = event.target.value;
			setTimeRange(newTimeRange);
			fetchChargeStationList(newTimeRange);
		},
		[fetchChargeStationList]
	);

	return (
		<CardContainer
			title={title}
			type="actionable"
			hasActionableComponent={true}
			height={height}
			width={width}
			actionableComponent={
				<RowContainer
					width={{ xs: "90%", sm: "70%", lg: "50%" }}
					padding="0px"
					marginBottom="0px"
					justifyContent="flex-end"
					alignItems="center"
					extraSX={{
						gap: "16px",
						marginRight: "16px",
					}}
				>
					{showTimeRange && (
						<RowContainer
							width="auto"
							padding="0px"
							marginBottom="0px"
							justifyContent="flex-end"
							alignItems="center"
							extraSX={{
								gap: "8px",
							}}
						>
							<Typography
								variant="body2"
								sx={{
									color: theme.palette.secondary.main,
									fontWeight: 600,
									fontSize: "0.875rem",
									whiteSpace: "nowrap",
								}}
							>
								Select Time Range:
							</Typography>
							<FormSelect
								value={timeRange}
								onChange={handleTimeRangeChange}
								options={["day", "week", "month"]}
								width="120px"
								placeholder="Select time range"
							/>
						</RowContainer>
					)}
					{showAddButton && (
						<FloatingActionButton
							icon={<Add color="secondary" />}
							onclick={toggleModalAddModal}
							extraSX={{
								marginLeft: "16px",
							}}
						/>
					)}
					{showSearch && (
						<FormInput
							ref={searchRef}
							placeholder={"Search Charge Station"}
							type="text"
							width={{
								xs:
									showTimeRange && showAddButton
										? "calc(100% - 176px)"
										: "100%",
								md:
									showTimeRange && showAddButton
										? "calc(80% - 176px)"
										: "80%",
							}}
							backgroundColor="#FFFFFF"
							endAdornment={
								<InputAdornment position="end">
									<Search color="#737373" />
								</InputAdornment>
							}
							extraSX={{
								marginRight: "16px",
							}}
							onChange={filter}
						/>
					)}
				</RowContainer>
			}
		>
			<CustomDataGrid
				columns={chargeStationListColumns}
				rows={filteredChargeStations}
				enableCheckboxSelection={false}
				isEmptyMessage="There are no charge stations to show"
				isLoading={isLoading}
				height="100%"
				width="100%"
				onRowClick={onRowClick}
			/>
			<AddNewStationModal
				toggleModal={toggleModalAddModal}
				modalOpen={AddmodalOpen}
				FormComponent={AddNewChargeStationForm}
				addStationService={addChargeStation}
				successMessageText="Charge Station added successfully!"
				title="Add New Charge Station"
				actionButtonText="Add Charge Station"
				onSuccess={handleAddSuccess}
			/>
			<EditStationModal
				toggleModal={toggleModalEditModal}
				modalOpen={editModalOpen}
				station={stationToEdit}
				onSuccess={handleEditSuccess}
				type="charge"
			/>
			<ConfirmationModal
				modalOpen={deleteModalOpen}
				toggleModal={() => setDeleteModalOpen(false)}
				itemType={"Charge Station"}
				itemName={
					stationToDelete?.chargeStationName || "Unknown Station"
				}
				onConfirmation={confirmDelete}
				confirmType={"delete"}
			/>
		</CardContainer>
	);
}
