import React from 'react'
import { CardContainer } from '../global/containers'

export default function BatteryEfficiencyCard() {
    return (
        <CardContainer
            title="Battery Efficiency Score"
            width="34%"
            height='-webkit-fill-available'
        >
            chart comes here
        </CardContainer>
    )
}
