import React, { useState } from 'react'
import { ColumnContainer, RowContainer } from '../global/containers'
import { CustomText } from '../global/text'
import { Box, IconButton } from '@mui/material'
import { Autorenew, Battery20, EvStation, KeyboardArrowDown, KeyboardArrowUp, MyLocation, TwoWheeler } from '@mui/icons-material'
import enzi_theme from '../../theme/theme'

export default function AssetsKeys() {
    const [hidden,setHidden] = useState(false)

    return (
        <Box> 
            <RowContainer width='280px' alignItems='center'  justifyContent='space-between' extraSX={{ 
                boxShadow:' 12px 6px 12px 0px #00000040',
                 borderRadius: '10px',
                 background: enzi_theme.palette.common.white,
                }}>
                <CustomText variant='body1' fontWeight='700' text='Map keys' color='secondary' />
                <IconButton onClick={() => setHidden(!hidden)}>
                    {hidden ? <KeyboardArrowDown /> : <KeyboardArrowUp/>}
                </IconButton>
            </RowContainer>

            <ColumnContainer  padding='0px' extraSX={{
                boxShadow:' 12px 6px 12px 0px #00000040', 
                borderRadius: '10px',
                display: hidden ?  'block': 'none',
                background: enzi_theme.palette.common.white,
                }}>
                <RowContainer alignItems='center' width='auto' justifyContent='space-between' marginBottom='0px'>
                    <CustomText variant='body1' text='Assets keys' color='secondary' />
                    <IconButton onClick={() => setHidden(!hidden)}>
                        {hidden ? <KeyboardArrowDown /> : <KeyboardArrowUp/>}
                    </IconButton>
                </RowContainer>
                <AssetItems icon={<TwoWheeler color='secondary'/>} title='Rider' />
                <AssetItems icon={<Battery20 color='secondary'/>} title='Detached Battery' />
                <AssetItems icon={<MyLocation color='secondary'/>} title='Tracker' />
                <AssetItems icon={<Autorenew color='secondary'/>} title='Swap Station' />
                <AssetItems icon={<EvStation color='secondary'/>} title='Charge Station' />

            </ColumnContainer>
        </Box>
    )
}



function AssetItems({ icon=null, title ='' }) {
    return (
        <RowContainer marginBottom='0px' width='auto' extraSX={{gap: 3}}>
            {icon}
            <CustomText variant='body1' text={title} color='secondary' />
        </RowContainer>
    )
}
