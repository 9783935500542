import React, { useEffect, useState } from "react";
import {
  CardContainer,
  ColumnContainer,
  RowContainer,
} from "../global/containers";
import InfoDetails from "../global/infoText";
import { getLastSwapReport } from "../../services/batteryService";
import { formatDate } from "../../util/util";
import LoadingOverlay from "../overlays/loading";
import { EmptyOverlays } from "../global/overlays";

export default function LastInspectionCard({ bat_sn }) {
  const [report, setReport] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isEmptyMessage, setIsEmptyMessage] = useState("Loading...")

  useEffect(() => {
    setIsLoading(true)
    getLastSwapReport(bat_sn)
    .then((response) => {
      if(response.date_created) { 
        const report = {
          issuing_station: response?.battery_swap_details?.issued_by_swapping_station_details?.name,
          receiving_station: response?.battery_swap_details?.received_by_swapping_station_details?.name,
          rider_name: response?.battery_swap_details?.received_by_name,
          swap_date: formatDate(response?.date_created),
          invoiced_amount: response?.amount,
        }
        setReport(report);
      } else {
        setIsEmptyMessage("No report found")
        setReport({})
      }
    })
    setIsLoading(false)
  }, [bat_sn]);

  return (
    <CardContainer
      // title="Last Inspection Report"
      title="Last Battery Swap Report"
      width={{ xs: "100%", lg: "60%" }}
      height={{ xs: "auto", lg: "100%" }}
      childrenContainerWidth="97%"
      extraSX={{
        borderRadius: "10px",
      }}
    >
      {isLoading ? <LoadingOverlay height="100%" message={isEmptyMessage} /> :
        (!isLoading && Object.keys(report).length === 0) ? <EmptyOverlays message={isEmptyMessage} /> :
        <RowContainer
          justifyContent="space-between"
          padding="0px"
          marginBottom="0px"
          alignItems="start"
        >
          <ColumnContainer padding="0px" width="50" alignItems="left">
            {/* <InfoDetails label={"Inspection Date"} value={"4/7/2023"} /> */}
            {/* <InfoDetails label={"Inspection Attendee"} value={"John Kamau"} /> */}
            {/* <InfoDetails label={"Inspection Comments"} value={"good condition"} /> */}
            <InfoDetails label={"Swap Date"} value={report?.swap_date} />
            <InfoDetails label={"Issued By Swapping Station"} value={report?.issuing_station} />
            <InfoDetails label={"Received By Swapping Station"} value={report?.receiving_station} />
          </ColumnContainer>
          <ColumnContainer
            width="50"
            padding="0px"
            alignItems={{ xs: "flex-start", lg: "end" }}
          >
            {/* <InfoDetails
              label={"Inspection Charge Station"}
              value={"Kikuyu"}
              alignItems={{ xs: "flex-start", lg: "end" }}
            />
            <InfoDetails
              label={"Inspection Grade"}
              value={"A"}
              alignItems={{ xs: "flex-start", lg: "end" }}
            /> */}
              <InfoDetails label={"Invoiced Amount"} value={report?.invoiced_amount} alignItems={{ xs: "flex-start", lg: "end" }} />
              <InfoDetails label={"Rider"} value={report?.rider_name} alignItems={{ xs: "flex-start", lg: "end" }} />
          </ColumnContainer>
        </RowContainer>
      }
    </CardContainer>
  );
}
