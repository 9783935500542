import React from 'react'
import { CardContainer } from '../global/containers'

export default function BatteryPowerExpenditurecard() {
    return (
        <CardContainer
            title="Battery Power Expenditure"
            width="47%"
            height='-webkit-fill-available'
        >
            chart comes here
        </CardContainer>
    )
}
