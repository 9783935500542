import { handleErrorResponse, issueSuccessMessage } from '../util/util';
import { ADD_BATTERY, BATTERY_ASSETS_COORDINATES, BATTERY_DEPLOYMENTS, BATTERY_DETAIL, BATTERY_UNSIGNED_lIST, BATTERY_LIST, DEL_BATTERY, EDT_BATTERY, GET_LOCATION_NAME, LAST_BATTERY_SWAP_REPORT, DISABLE_BATTERY } from '../constants/url.constants';
import { serviceAPI } from './service';

// export const getBattery = async (id) => {
// 	try {
// 		const response = await serviceAPI.get(BATTERIES + `${id}`);
// 		return response.data;
// 	} catch (error) {
// 		handleErrorResponse(error);
// 	}
// };

export const getBatteryDeployments = async (bat_sn) => {
	try {
		const response = await serviceAPI.get(BATTERY_DEPLOYMENTS + `${bat_sn}`);
		return response.data;
	} catch (error) {
		handleErrorResponse(error);
	}
}

export const getBatteryDetail = async (bat_sn) => {
	try {
		const response = await serviceAPI.get(BATTERY_DETAIL + `${bat_sn}`);
		return response.data;
	} catch (error) {
		handleErrorResponse(error);
	}
};

export const getLastSwapReport = async (bat_sn) => {
	try {
		const response = await serviceAPI.get(LAST_BATTERY_SWAP_REPORT + `${bat_sn}`);
		return response.data;
	} catch (error) {
		handleErrorResponse(error);
	}
}

export const listBatteries = async () => {
	try {
		const response = await serviceAPI.get(BATTERY_LIST);
		return response.data;
	} catch (error) {
		handleErrorResponse(error);
	}
};

export const listUnassignedBatteries = async () => {
	try {
		const response = await serviceAPI.get(BATTERY_UNSIGNED_lIST);
		return response.data;
	} catch (error) {
		handleErrorResponse(error);
		throw error;
	}
};

export const killBatterySMS = async (id) => {
	try {
		const response = await serviceAPI.get(DISABLE_BATTERY + `${id}`);
		issueSuccessMessage('Battery disabled successfully');
		return response.data;
	} catch (error) {
		handleErrorResponse(error);
	}
};

export const deleteBattery = async (id) => {
	try {
		const response = await serviceAPI.delete(DEL_BATTERY + `${id}`);
		issueSuccessMessage('Battery deleted successfully');
		return response.data;
	} catch (error) {
		handleErrorResponse(error);
	}
};

export const addBattery = async (data) => {
	try {
		const response = await serviceAPI.post(ADD_BATTERY, data);
		issueSuccessMessage('Battery added successfully');
		return response.data;
	} catch (error) {
		handleErrorResponse(error);
	}
};

export const editBattery = async (data, id) => {
	try {
		const response = await serviceAPI.put(EDT_BATTERY + `${id}`, data);
		issueSuccessMessage('Battery edited successfully');
		return response.data;
	} catch (error) {
		handleErrorResponse(error);
	}
};

export const getAssetsCoordinates = async () => {
	try {
		const response = await serviceAPI.get(BATTERY_ASSETS_COORDINATES);
		return response.data;
	} catch (error) {
		handleErrorResponse(error);
	}
}

export const getLocationName = async (lat, lon) => {
	try {
		const response = await serviceAPI.get(GET_LOCATION_NAME, {
			params: {
				lat,
				lon,
			},
		});
		return response.data;
	} catch (error) {
		handleErrorResponse(error);
	}
}
