import React, { useRef, useState } from "react";
import { ModalContainer } from "../global/containers";
import AttendantForm from "../forms/attendantForm";
import {
	addChargeStationUser,
	editChargeStationUser,
} from "../../services/chargeStationService";
import {
	addSwapStationUser,
	editSwapStationUser,
} from "../../services/swapStation";
import { Box } from "@mui/material";
import { issueSuccessMessage, issueErrorMessage } from "../../util/util";

export default function AttendantModal({
	modalOpen,
	toggleModal,
	station,
	attendant,
	onSuccess,
	isEditMode = false,
}) {
	const [formErrors, setFormErrors] = useState({});
	const handleSubmitRef = useRef();

	const userGroup =
		station?.station_type === "Charging Station"
			? "Charge Station User"
			: "Swap Station User";

	const userType =
		station?.station_type === "Charging Station"
			? "Charge Station Attendant"
			: "Swap Station Attendant";

	const franchiseeType =
		station?.station_type === "Charging Station"
			? "Charge Station Franchisee"
			: "Swap Station Franchisee";

	const handleSub = () => {
		if (handleSubmitRef.current) {
			const formData = handleSubmitRef.current.getData();
			if (formData && validateForm(formData)) {
				onSubmit(formData);
			}
		}
	};

	const validateForm = (data) => {
		const errors = {};
		if (!data.first_name) errors.first_name = "First name is required.";
		if (!data.last_name) errors.last_name = "Last name is required.";
		// Add more validation as needed

		setFormErrors(errors);
		return Object.keys(errors).length === 0;
	};

	const onSubmit = (data) => {
		const franchiseeUser = station.station_users?.find(
			(group) => group.user_type === franchiseeType
		)?.users?.[0] || { id: 1 };

		const currentDate = new Date();
		const oneYearLater = new Date(
			currentDate.setFullYear(currentDate.getFullYear() + 1)
		);

		const requestData = {
			...data,
			station_id: station?.id,
			effective_date: new Date().toISOString(),
			expiration_date: oneYearLater.toISOString(),
			created_by: franchiseeUser?.id,
		};

		let action;
		if (isEditMode) {
			const editFunction =
				station?.station_type === "Charging Station"
					? editChargeStationUser
					: editSwapStationUser;
			action = (data) => editFunction(data, attendant?.id);
		} else {
			action =
				station?.station_type === "Charging Station"
					? addChargeStationUser
					: addSwapStationUser;
		}

		action(requestData)
			.then((res) => {
				issueSuccessMessage(
					`${userType} successfully ${
						isEditMode ? "updated" : "added"
					}!`
				);
				onSuccess(res);
				toggleModal();
			})
			.catch((error) => {
				issueErrorMessage(
					`Error ${isEditMode ? "updating" : "adding"} ${userType}.`
				);
				console.error(error);
			});
	};

	return (
		<ModalContainer
			title={`${isEditMode ? "Edit" : "Add New"} ${userGroup}${
				isEditMode ? `: ${attendant?.name}` : ` for ${station?.name}`
			}`}
			modalOpen={modalOpen}
			height="60"
			width="60%"
			maxWidth="600px"
			actionButtonText={isEditMode ? "Save Changes" : "Add Attendant"}
			handleCloseModal={toggleModal}
			handleCancelClick={toggleModal}
			handleActionClick={handleSub}
		>
			<Box
				sx={{
					overflowY: "auto",
					padding: "20px",
					maxHeight: "calc(100vh - 200px)",
					width: "90%",
					"& > *": { width: "100%" },
				}}
			>
				<AttendantForm
					ref={handleSubmitRef}
					userType={userType}
					initialData={isEditMode ? attendant : {}}
					isEditMode={isEditMode}
				/>
				{Object.keys(formErrors).length > 0 && (
					<Box sx={{ color: "red", mt: 2, textAlign: "center" }}>
						{Object.values(formErrors).map((error, index) => (
							<p key={index}>{error}</p>
						))}
					</Box>
				)}
			</Box>
		</ModalContainer>
	);
}
