import { Typography } from "@mui/material";
import { ColumnContainer } from "./containers";

export default function InfoDetails({
	width = "100%",
	label,
	value,
	alignItems = "left",
	marginBottom = "10px",
}) {
	return (
		<ColumnContainer
			width={width}
			alignItems={alignItems}
			marginBottom={marginBottom}
		>
			<Typography
				variant="body1"
				color="secondary"
				fontWeight={700}
				textAlign={"start"}
				width={"auto"}
			>
				{label}
			</Typography>
			<Typography
				variant="body1"
				color="secondary"
				fontWeight={500}
				textAlign={"start"}
				width={"auto"}
			>
				{value}
			</Typography>
		</ColumnContainer>
	);
}
