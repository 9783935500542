import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import auth from "./slices/auth";
import persistReducer from "redux-persist/es/persistReducer";

import persistStore from "redux-persist/es/persistStore";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  auth: auth,
});

const persistedRootReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedRootReducer,
  devTools: process.env.NODE_ENV !== "production",
  //   middleware: [thunk],
});

const persistor = persistStore(store);

export { store, persistor };
