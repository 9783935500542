import React, { useCallback, useRef } from "react";
import { ModalContainer } from "../global/containers";
import { editChargeStation } from "../../services/chargeStationService";
import AddNewChargeStationForm from "../forms/addNewChargeStationForm";
import { Box } from "@mui/material";
import { issueSuccessMessage, issueErrorMessage } from "../../util/util";

export default function EditChargeStation({
	modalOpen,
	toggleModal,
	station,
	onSuccess,
}) {
	const handleSubmitRef = useRef();

	const handleSubmit = useCallback(() => {
		const chargeStationData = handleSubmitRef.current.getData();
		onSubmit(chargeStationData);
	}, []);

	const onSubmit = (data) => {
		editChargeStation(data, data.id)
			.then((res) => {
				issueSuccessMessage("Charge station updated successfully!");
				if (onSuccess) {
					onSuccess(res);
				}
				toggleModal();
			})
			.catch((error) => {
				issueErrorMessage("Error updating charge station.");
				console.error(error);
			});
	};

	return (
		<ModalContainer
			title={`Edit Charge Station: ${station?.name || ""}`}
			modalOpen={modalOpen}
			height="80%"
			width="80%"
			maxWidth="1000px"
			actionButtonText="Save"
			handleCloseModal={toggleModal}
			handleCancelClick={toggleModal}
			handleActionClick={handleSubmit}
		>
			<Box
				sx={{
					height: "calc(100% - 64px)",
					overflowY: "auto",
					padding: "16px",
				}}
			>
				<AddNewChargeStationForm
					chargeStation={station}
					ref={handleSubmitRef}
				/>
			</Box>
		</ModalContainer>
	);
}
