import React, { useCallback, useState } from "react";
import { CardContainer, RowContainer } from "../global/containers";
import InfoDetails from "../global/infoText";
import { FloatingActionButton } from "../global/button";
import { Edit } from "@mui/icons-material";
import EditBikeAssetModal from "../modals/editBikeAssetModal";

const ECI_STATES = {
  idd: 'idling',
  mv: 'moving',
  off: 'off'
}

export default function BikeAssetBasicInfoCard({electricVehicleInfo = {} }) {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = useCallback(
    async function () {
      setModalOpen(!modalOpen);
    },
    [modalOpen]
  );

  return (
    <CardContainer
      title="Basic Information"
      width={{ xs: "100%", lg: "33%" }}
      height="100%"
      extraSX={{
        borderRadius: "10px",
      }}
      childrenContainerWidth="95%"
    >
      <RowContainer
        justifyContent="space-between"
        padding="0px"
        marginBottom="0px"
        extraSX={{}}
      >
        <InfoDetails 
          width="35%"
          label={"Model number"} 
          value={`${electricVehicleInfo?.motor_model_number !== "motor model number" ? electricVehicleInfo?.motor_model_number : "-" }`} />
        <InfoDetails
          width="35%"
          label={"Reg Number"}
          value={electricVehicleInfo?.registration_number}
          alignItems={{ xs: "flex-start", lg: "end" }}
        />
      </RowContainer>
      <RowContainer
        justifyContent="space-between"
        padding="0px"
        marginBottom="0px"
        extraSX={{}}
      >
        <InfoDetails label={"Last Service "} width="35%"value={"-"} />

        <InfoDetails
          width="35%"
          label={"Chasis number"}
          value={`${electricVehicleInfo?.chasis_number !== "chasis number" ? electricVehicleInfo?.chasis_number : "-"}`}
          alignItems={{ xs: "flex-start", lg: "end" }}
        />
      </RowContainer>
      <RowContainer
        justifyContent="space-between"
        padding="0px"
        marginBottom="0px"
        extraSX={{}}
      >
        <InfoDetails
          label={"Current Mileage"}
          width="35%"
          value={`${electricVehicleInfo?.millage ?? "-"} KM`}
          alignItems={"flex-start"}
        />
        <InfoDetails
          label={"Current Status"}
          width="35%"
          value={ECI_STATES[electricVehicleInfo?.eci?.state]}
          alignItems={{ xs: "flex-start", lg: "end" }}
        />
      </RowContainer>
      <RowContainer justifyContent="end" padding="0px" marginBottom="0px">
        <FloatingActionButton
          icon={<Edit color="secondary" />}
          onclick={toggleModal}
        />
      </RowContainer>
      <EditBikeAssetModal toggleModal={toggleModal} modalOpen={modalOpen} bikeAsset={electricVehicleInfo}/>
    </CardContainer>
  );
}
