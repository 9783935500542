// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/charge-station-pin.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("assets/swap-station-pin.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("assets/location.pin.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("assets/motor-pin.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("assets/battery-pin.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body{
  margin: 0;
}

.figtree-Figtree {
  font-family: "Figtree", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
}

.charge-station-pin{
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  width: 50px;
  height: 55px;
  cursor: pointer;
}

.swap-station-pin{
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  background-size: cover;
  width: 50px;
  height: 55px;
  cursor: pointer;
}

.location-pin{
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
  background-size: cover;
  width: 50px;
  height: 55px;
  cursor: pointer;
}

.motor-pin{
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
  background-size: cover;
  width: 50px;
  height: 55px;
  cursor: pointer;
}

.battery-pin{
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_4___});
  background-size: cover;
  width: 50px;
  height: 55px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;AACX;;AAEA;EACE,kCAAkC;EAClC,yBAAyB;EACzB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,yDAAsD;EACtD,sBAAsB;EACtB,WAAW;EACX,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,yDAAoD;EACpD,sBAAsB;EACtB,WAAW;EACX,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,yDAAgD;EAChD,sBAAsB;EACtB,WAAW;EACX,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,yDAA6C;EAC7C,sBAAsB;EACtB,WAAW;EACX,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,yDAA+C;EAC/C,sBAAsB;EACtB,WAAW;EACX,YAAY;EACZ,eAAe;AACjB","sourcesContent":["body{\n  margin: 0;\n}\n\n.figtree-Figtree {\n  font-family: \"Figtree\", sans-serif;\n  font-optical-sizing: auto;\n  font-weight: 900;\n  font-style: normal;\n}\n\n.charge-station-pin{\n  background-image: url('assets/charge-station-pin.svg');\n  background-size: cover;\n  width: 50px;\n  height: 55px;\n  cursor: pointer;\n}\n\n.swap-station-pin{\n  background-image: url('assets/swap-station-pin.svg');\n  background-size: cover;\n  width: 50px;\n  height: 55px;\n  cursor: pointer;\n}\n\n.location-pin{\n  background-image: url('assets/location.pin.svg');\n  background-size: cover;\n  width: 50px;\n  height: 55px;\n  cursor: pointer;\n}\n\n.motor-pin{\n  background-image: url('assets/motor-pin.svg');\n  background-size: cover;\n  width: 50px;\n  height: 55px;\n  cursor: pointer;\n}\n\n.battery-pin{\n  background-image: url('assets/battery-pin.svg');\n  background-size: cover;\n  width: 50px;\n  height: 55px;\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
