import React from "react";
import {
  CardContainer,
  ColumnContainer,
  RowContainer,
} from "../global/containers";
import StatItem from "../global/statItem";

export default function TrackersOverviewStatsCard() {
  return (
    <CardContainer
      title="Tracker Overview Stats"
      width={{ xs: "100%", lg: "50%" }}
      height="auto"
      childrenContainerWidth="97%"
    >
      <ColumnContainer
        width="100%"
        height="auto"
        padding="5px 10px"
        marginBottom="5px"
        extraSX={{
          gap: "10px",
        }}
      >
        <RowContainer
          justifyContent="space-between"
          alignItems="start"
          padding="0px"
          marginBottom="0px"
          responsive={false}
          extraSX={{
            gap: "10px",
          }}
        >
          <StatItem title={"Total Active Trackers "} value={1200} width="50%" />
          <StatItem
            title={"Month’s Tracker Sales Revenue"}
            value={1200}
            width="50%"
          />
        </RowContainer>
        <RowContainer
          justifyContent="space-between"
          alignItems="start"
          padding="0px"
          marginBottom="0px"
          responsive={false}
          extraSX={{
            gap: "10px",
          }}
        >
          <StatItem title={"Newly Enrolled Tracker"} value={1200} width="50%" />
          <StatItem title={"Suspended Trackers "} value={1200} width="50%" />
        </RowContainer>
      </ColumnContainer>
    </CardContainer>
  );
}
