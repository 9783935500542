import React, { useState, useCallback, useEffect } from "react";
import { CardContainer } from "../global/containers";
import {
  CustomDataGrid,
  CustomDataGridActionsDropdownMenu,
} from "../global/customTable";
import BatteryDeploymentModal from "../modals/batteryDeploymentModal";
import { getBatteryDeployments } from "../../services/batteryService";

export default function BatteryDeploymentHistoryCard({ bat_sn }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [batteryDeploymentList, setBatteryDeploymentList] = useState([]);

  const toggleModal = useCallback(
    async function () {
      setModalOpen(!modalOpen);
    },
    [modalOpen]
  );

  const batteryDeploymentsColumns = [
    {
      field: "date",
      headerName: "Date",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "duration",
      headerName: "Duration",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "ev",
      headerName: "Reg No",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "distance",
      headerName: "Distance",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "unitsConsumed",
      headerName: "Units Used",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: (params) => (
        <CustomDataGridActionsDropdownMenu
          menuOptions={[
            {
              label: "View",
              onClickHandler: () => {
                setSelectedRow(params.row);
                setModalOpen(true);
              },
            },
          ]}
        />
      ),
    },
  ];

  const fetchBatteryDeploymentList = useCallback(async function () {
    setIsLoading(true);

    getBatteryDeployments(bat_sn)
    .then((response) => {
      setBatteryDeploymentList(response);
    })
    .finally(() => setIsLoading(false));
  }, [bat_sn]);

  useEffect(() => {
    fetchBatteryDeploymentList();
  }, [fetchBatteryDeploymentList]);

  return (
    <CardContainer
      title="Battery Deployments"
      width="100%"
      height="65vh"
      childrenContainerWidth="97%"
      extraSX={{ overflow: "visible" }}
    >
      <CustomDataGrid
        height="100%"
        width="100%"
        columns={batteryDeploymentsColumns}
        rows={batteryDeploymentList}
        isLoading={isLoading}
        isEmptyMessage="There are no battery deployments to show"
      />
      <BatteryDeploymentModal
        deployment={selectedRow}
        modalOpen={modalOpen}
        toggleModal={toggleModal}
      />
    </CardContainer>
  );
}
