import { Box, Button, IconButton, InputAdornment } from "@mui/material";
import backgroundImage from "../images/login-background.jpg";
import enzi_logo from "../images/Enzi-logo-400px.png";
import { useEffect, useRef, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { FormInput } from "../components/global/form";
import { CustomText } from "../components/global/text";
import { useNavigate } from "react-router-dom";
import { ColumnContainer, RowContainer } from "../components/global/containers";
import enzi_theme from "../theme/theme";
import { useDispatch, useSelector } from "react-redux";
import { AUTHENTICATE_USER } from "../state/slices/auth";

function Login() {
  const authState = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();

  const emailRef = useRef();
  const passwordRef = useRef();

  function handleForgotPassword() {
    navigate("/password-reset-request");
  }

  function handleAuthentication() {
    let username = "";
    let password = "";
    if (emailRef !== null && emailRef.current !== null) {
      username = emailRef.current.getState();
    }
    if (passwordRef !== null && passwordRef.current !== null) {
      password = passwordRef.current.getState();
    }

    console.log(username,password);

    if (password !== "" && username !== "") {
      dispatch(
        AUTHENTICATE_USER({
            isAuthenticated: true,
            user: {
                id: '1',
                name: 'John Doe',
                email: username,
                username: username,
                userType: 'Admin',
                phone: '0708084944',
                jwt: 'am1idXRoaWE6M2NwZ2UyRUI2SDdtTlha',
            },
        })
      );

    navigate("/dashboard");
    }
  }

  useEffect(() => {
    if (authState !== undefined && authState.isAuthenticated === true) {
      navigate("/dashboard");
    }
  }, [authState, navigate]);

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",

        backgroundSize: "cover",

        backgroundImage: `url(${backgroundImage})`,
        alignItems: "center",
        justifyContent: {xs: 'center', lg: "flex-end"},
        paddingRight: {xs: '0px', md: "3rem"}
      }}
    >
      <ColumnContainer
        width= {{xs : '80%',md: '60%', lg: "33.3%"}}
        height="auto"
        alignItems={{xs : 'center', lg: "flex-end"}}
        padding="10px 20px"
        extraSX={{
          backgroundColor: enzi_theme.palette.common.white,
          borderRadius: "10px",
        }}
      >
        <RowContainer width="100%" height="auto" justifyContent="flex-end">
          <img
            alt="logo"
            style={{ float: "right" }}
            src={enzi_logo}
            width={"100px"}
            height={"auto"}
          />
        </RowContainer>

        <CustomText
          text={"Welcome back"}
          variant={"h1"}
          color={"secondary"}
          fontWeight="800"
        />
        <CustomText
          text={
            "Please use the email address and password used to when creating your account to  login."
          }
          variant={"body1"}
          color={"secondary"}
          width="100%"
        />

        <ColumnContainer
          width="95%"
          alignItems="center"
          justifyContent="flex-start"
          marginBottom="0"
        >
          <FormInput
            ref={emailRef}
            required={true}
            placeholder={"Enter email address"}
            type="email"
          />
          <FormInput
            ref={passwordRef}
            required={true}
            placeholder={"Enter Password"}
            type={passwordVisible ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  sx={{ padding: 0 }}
                  onClick={() => setPasswordVisible(!passwordVisible)}
                >
                  {!passwordVisible ? (
                    <Visibility color="#737373" />
                  ) : (
                    <VisibilityOff color="#737373" />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
          <Button
            variant="contained"
            size="large"
            sx={{ width: "70%", marginTop: 1 }}
            onClick={handleAuthentication}
          >
            Login
          </Button>
          <RowContainer
            width="100%"
            height="auto"
            justifyContent="center"
            responsive={false}
            marginBottom="0"
          >
            <CustomText
              width="auto"
              variant="body1"
              color={"secondary"}
              fontWeight="500"
              extraSX={{ margin: "auto 5px", fontSize: "1rem" }}
              text="Forgot password?"
            />
            <Button
              variant="text"
              color={"secondary"}
              sx={{
                margin: "auto 0",
                padding: 0,
                fontSize: "1rem",
                textTransform: "capitalize",
                ":hover": { cursor: "pointer" },
              }}
              onClick={handleForgotPassword}
            >
              Click here
            </Button>
          </RowContainer>
        </ColumnContainer>
      </ColumnContainer>
    </Box>
  );
}

export default Login;
