import axios from 'axios';
import { store } from '../store';

const getSecureRequest = (request) => {
	const user = store.getState().auth.user;
	if (user?.jwt !== undefined) {
		request.headers = {
			Authorization: `Bearer ${user.jwt}`,
		};
	} else {
		delete serviceAPI.defaults.headers.common.Authorization;
	}
	return request;
};

export const serviceAPI = axios.create();
serviceAPI.interceptors.request.use(
	(request) => {
		return getSecureRequest(request);
	},
	(error) => Promise.reject(error)
);
serviceAPI.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		// console.error(JSON.stringify(error));
		return Promise.reject(error);
	}
);

const serviceAPIJSON = axios.create();
serviceAPIJSON.interceptors.request.use(
	(request) => {
		let secureRequest = getSecureRequest(request);
		secureRequest.headers = {
			...secureRequest.headers,
			Accept: 'application/json; charset=utf-8',
			'Content-Type': 'application/json; charset=utf-8',
		};
		return secureRequest;
	},
	(error) => {
		// console.error(JSON.stringify(error));
		return Promise.reject(error);
	}
);

serviceAPIJSON.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		// console.error(JSON.stringify(error));
		return Promise.reject(error);
	}
);
