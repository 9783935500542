import { ThemeProvider } from "@mui/material";
import "./App.css";
import enzi_theme from "./theme/theme";
import { Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import ResetPassword from "./pages/ResetPassword";
import ResetPasswordRequest from "./pages/ResetPasswordRequest";
import Docs from "./pages/docs";
import ProtectedLayout from "./components/layouts/protected";
import Dashboard from "./pages/dashboard";
import { Provider, useSelector } from "react-redux";
import { persistor, store } from "./state/store";
import { PersistGate } from "redux-persist/integration/react";
import LoadingOverlay from "./components/overlays/loading";
import MotorbikeAssetInformation from "./pages/MotorbikeAssetInformation";
import RiderInfo from "./pages/riderInfo";
import BatteryAssetInfo from "./pages/batteryAssetInfo";
import SwapStationInformation from "./pages/swapStationInformation";
import ChargeStation from "./pages/chargeStation";
import AssetMap from "./pages/assetMap";
import MotorBikeListPage from "./pages/motorBikeListPage";
import SwapStationsList from "./pages/swapStationsList";
import TrackerAssetPage from "./pages/trackerAssetPage";
import ChargeStationListPage from "./pages/chargeStationListPage";
import BatteryAssetsListPage from "./pages/batteryAssetsListPage";
import StaffManagement from "./pages/staffManagement";
import RiderListPage from "./pages/riderListPage";
import { CLEAR_MESSAGE } from "./state/slices/messages";
import { useCallback, useEffect, useState } from "react";
import MessageAlert from "./components/global/messageAlert";

function App() {
	const [showMessage, setShowMessage] = useState(false);
	const appMessage = useSelector((state) => state.message);

  const handleCloseMessage = useCallback(() => {
    setShowMessage(false);
    store.dispatch(CLEAR_MESSAGE());
  }, []);

	useEffect(() => {
		if (appMessage.message) {
			setShowMessage(true);
		}
	}, [appMessage.message]);

	return (
		<div className="App">
			<Provider store={store}>
				<PersistGate
					persistor={persistor}
					loading={
						<LoadingOverlay
							loadingMessage="Loading state..."
							height="100vh"
						/>
					}
				>
					<ThemeProvider theme={enzi_theme}>
						<Routes>
							<Route exact path="/" element={<Login />} />
							<Route
								exact
								path="/public/docs"
								element={<Docs />}
							/>
							<Route
								exact
								path="/password-reset"
								element={<ResetPassword />}
							/>
							<Route
								exact
								path="/password-reset-request"
								element={<ResetPasswordRequest />}
							/>
							<Route
								exact
								path="/dashboard"
								element={
									<ProtectedLayout>
										<Dashboard />
									</ProtectedLayout>
								}
							/>
							<Route
								exact
								path="/motobikes/:id"
								element={
									<ProtectedLayout>
										<MessageAlert
											showMessage={showMessage}
											appMessage={appMessage}
											handleCloseMessage={
												handleCloseMessage
											}
										/>
										<MotorbikeAssetInformation />
									</ProtectedLayout>
								}
							/>
							<Route
								exact
								path="/motobikes"
								element={
									<ProtectedLayout>
										<MessageAlert
											showMessage={showMessage}
											appMessage={appMessage}
											handleCloseMessage={
												handleCloseMessage
											}
										/>
										<MotorBikeListPage />
									</ProtectedLayout>
								}
							/>
							<Route
								exact
								path="/batteries/:bat_sn"
								element={
									<ProtectedLayout>
										<MessageAlert
											showMessage={showMessage}
											appMessage={appMessage}
											handleCloseMessage={
												handleCloseMessage
											}
										/>
										<BatteryAssetInfo />
									</ProtectedLayout>
								}
							/>
							<Route
								exact
								path="/batteries"
								element={
									<ProtectedLayout>
										<MessageAlert
											showMessage={showMessage}
											appMessage={appMessage}
											handleCloseMessage={
												handleCloseMessage
											}
										/>
										<BatteryAssetsListPage />
									</ProtectedLayout>
								}
							/>
							<Route
								exact
								path="/riders"
								element={
									<ProtectedLayout>
										<MessageAlert
											showMessage={showMessage}
											appMessage={appMessage}
											handleCloseMessage={
												handleCloseMessage
											}
										/>
										<RiderListPage />
									</ProtectedLayout>
								}
							/>
							<Route
								exact
								path="/riders/:id"
								element={
									<ProtectedLayout>
										<MessageAlert
											showMessage={showMessage}
											appMessage={appMessage}
											handleCloseMessage={
												handleCloseMessage
											}
										/>
										<RiderInfo />
									</ProtectedLayout>
								}
							/>

							<Route
								exact
								path="/trackers"
								element={
									<ProtectedLayout>
										<MessageAlert
											showMessage={showMessage}
											appMessage={appMessage}
											handleCloseMessage={
												handleCloseMessage
											}
										/>
										<TrackerAssetPage />
									</ProtectedLayout>
								}
							/>
							<Route
								exact
								path="/swap-station/:id"
								element={
									<ProtectedLayout>
										<MessageAlert
											showMessage={showMessage}
											appMessage={appMessage}
											handleCloseMessage={
												handleCloseMessage
											}
										/>
										<SwapStationInformation />
									</ProtectedLayout>
								}
							/>
							<Route
								exact
								path="/swap-station"
								element={
									<ProtectedLayout>
										<MessageAlert
											showMessage={showMessage}
											appMessage={appMessage}
											handleCloseMessage={
												handleCloseMessage
											}
										/>
										<SwapStationsList />
									</ProtectedLayout>
								}
							/>
							<Route
								exact
								path="/charge-station/:id"
								element={
									<ProtectedLayout>
										<MessageAlert
											showMessage={showMessage}
											appMessage={appMessage}
											handleCloseMessage={
												handleCloseMessage
											}
										/>
										<ChargeStation />
									</ProtectedLayout>
								}
							/>
							<Route
								exact
								path="/charge-station"
								element={
									<ProtectedLayout>
										<MessageAlert
											showMessage={showMessage}
											appMessage={appMessage}
											handleCloseMessage={
												handleCloseMessage
											}
										/>
										<ChargeStationListPage />
									</ProtectedLayout>
								}
							/>
							<Route
								exact
								path="/assets"
								element={
									<ProtectedLayout>
										<MessageAlert
											showMessage={showMessage}
											appMessage={appMessage}
											handleCloseMessage={
												handleCloseMessage
											}
										/>
										<AssetMap />
									</ProtectedLayout>
								}
							/>
							<Route
								exact
								path="/staff_management"
								element={
									<ProtectedLayout>
										<MessageAlert
											showMessage={showMessage}
											appMessage={appMessage}
											handleCloseMessage={
												handleCloseMessage
											}
										/>
										<StaffManagement />
									</ProtectedLayout>
								}
							/>
						</Routes>
					</ThemeProvider>
				</PersistGate>
			</Provider>
		</div>
	);
}

export default App;
