import React, { useCallback, useEffect, useState } from "react";
import { CardContainer, RowContainer } from "../global/containers";
import { FloatingActionButton } from "../global/button";
import { SearchInput } from "../global/form";
import {
	CustomDataGrid,
	CustomDataGridActionsDropdownMenu,
} from "../global/customTable";
import { Add } from "@mui/icons-material";
import AddBikeModal from "../modals/addBikeModal";
import { useNavigate } from "react-router-dom";
import {
	deleteElectricVehicles,
	listElectricVehicles,
} from "../../services/electricVehicle";
import EditBikeAssetModal from "../modals/editBikeAssetModal";
import ConfirmationModal from "../modals/confirmationModal";

const ECI_STATES = {
  mv: 'moving',
  idd: 'idling',
  off: 'off'
}


export default function BikeListCard() {

  const [filteredData, setFilteredData] =  useState()

  const navigator = useNavigate();
  const [addmodalOpen, setAddModalOpen] = useState(false);
  const [editmodalOpen, setEditmodalOpen] = useState(false);
  const [mototbikeToEdit, setmototbikeToEdit] = useState();
  const [motobikeList, setMototbikeList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [bikeToDelete, setBikeToDelete] = useState(null);

  const motoBikeColumns = [
    {
      field: "bikeSerial",
      headerName: "Bike Serial No",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "currentMilage",
      headerName: "Current Milage",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "riderName",
      headerName: "Rider Name",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "bikeRegistration",
      headerName: "Registration No",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "pairedBatterySn",
      headerName: "Paired Battery Sn",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "eciState",
      headerName: "State",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <CustomDataGridActionsDropdownMenu
          menuOptions={[
            {
              label: "View",
              onClickHandler: () => navigator(`/motobikes/${params.row.id}`),
            },
            {
              label: "Edit",
              onClickHandler: () => editMotorBike(params.row),
            },
            {
              label: "Delete",
              onClickHandler: () => handleDelete(params.row),
            },
          ]}
        />
      ),
    },
  ];

  const fetchBikeListData = useCallback(async function () {
    setIsLoading(true);
    listElectricVehicles()
      .then((electricVehicles) => {
        const formatedData = electricVehicles.map((vehicle) => {
          const riderName = `${vehicle?.rider?.first_name} ${vehicle?.rider?.last_name} `

          return {
            id: vehicle.id,
            bikeSerial: vehicle.serial_number,
            currentMilage: vehicle.millage ?? 0,
            riderName: riderName,
            bikeRegistration: vehicle?.registration_number,
            pairedBatterySn: vehicle.battery?.bat_sn ?? "-",
            eciState: ECI_STATES[vehicle?.eci?.state],
            ...vehicle
          }
        });
        setMototbikeList(formatedData)
        setFilteredData(formatedData)
      })
      .catch(() => {
        setMototbikeList([])
      })
      .finally(() => setIsLoading(false))
  }, []);



  const filter = (ev) => {
    const param = ev.target.value.toLocaleLowerCase()
    if(param === ""){
      return setFilteredData(motobikeList)
    }
    const filters = vehicle => vehicle?.registration_number.toLocaleLowerCase().includes(param) || vehicle?.rider?.first_name.toLocaleLowerCase().includes(param) || vehicle?.rider?.last_name.toLocaleLowerCase().includes(param) || vehicle?.pairedBatterySn.toLocaleLowerCase().includes(param)
    const formatedData = motobikeList.filter(filters)
    setFilteredData(formatedData)

  }

  const toggleAddModal = useCallback(
    async function () {
      setAddModalOpen(!addmodalOpen);
    },
    [addmodalOpen]
  );

  const toggleDeleteModal = useCallback(
    async function () {
      setDeleteModalOpen(!deleteModalOpen);
    },
    [deleteModalOpen]
  );

	const toggleEditModal = useCallback(
		async function () {
			setEditmodalOpen(!editmodalOpen);
		},
		[editmodalOpen]
	);

	useEffect(() => {
		fetchBikeListData();
	}, [fetchBikeListData, toggleAddModal, toggleEditModal]);

	const editMotorBike = (data) => {
		setmototbikeToEdit(data);
		toggleEditModal();
	};

  const handleDelete = (motorbike) => {
    setBikeToDelete(motorbike)
    toggleDeleteModal()
  }

  const deleteEv = () => {
    deleteElectricVehicles(bikeToDelete.id)
      .then((res) => {
        setMototbikeList(motobikeList.filter((value) => value.id !== bikeToDelete.id))
        setFilteredData(filteredData.filter((value) => value.id !== bikeToDelete.id))
      }).catch((err) => {
        console.log(err);
      })
      .finally(()=> toggleDeleteModal())
  }

  return (
    <CardContainer
      title="All Bike Assets"
      type="actionable"
      hasActionableComponent={true}
      height="auto"
      width="100%"
      childrenContainerWidth="97%"
      actionableComponent={
        <RowContainer
          width={{ xs: "80%", sm: "50%", lg: "30%" }}
          padding="0px"
          marginBottom="0px"
        >
          <FloatingActionButton
            icon={<Add color="secondary" />}
            onclick={() => toggleAddModal()}
          />
          <SearchInput
            placeholder={"Search"}
            filter={filter}
            type="text"
            width={{ xs: "100%", md: "60%", lg: "70%" }}
            backgroundColor="#FFFFFF"
          />
        </RowContainer>
      }
    >
      <CustomDataGrid
        columns={motoBikeColumns}
        rows={filteredData}
        isLoading={isLoading}
        isEmptyMessage="There are no bike assets to show"
        width="100%"
        height="65vh"
      />
      <AddBikeModal toggleModal={toggleAddModal} modalOpen={addmodalOpen} />
      <EditBikeAssetModal toggleModal={toggleEditModal} bikeAsset={mototbikeToEdit} modalOpen={editmodalOpen} />
      <ConfirmationModal
				modalOpen={deleteModalOpen}
				toggleModal={toggleDeleteModal}
				onConfirmation={deleteEv}
				itemType="Electric vehicle"
				itemName={bikeToDelete?.registration_number}
        confirmType="delete"
			/>
    </CardContainer>
  );
}
