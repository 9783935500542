import React, { useRef, useState } from 'react'
import { ModalContainer } from '../global/containers'
import AddNewBatteryAssetForm from '../forms/AddNewBatteryAssetForm'
import { addBattery } from '../../services/batteryService'

export default function AddNewBatteryAssetModal({ modalOpen = false, toggleModal }) {

  const handleSubmitRef = useRef()
  const [isError, setIsError] = useState(false)

  const handleSubmit = () => {
    const batteryData = handleSubmitRef.current?.getData()

    if(validateAddData(batteryData)) {
      onSubmit(batteryData)
    } else {
      setIsError(true)
      setTimeout(() => setIsError(false), 5000);
    }
  }

  function validateAddData(data) {
    if (!data) {
      return false
    }

    if (!data.bat_sn || !data.battery_type || !data.battery_model) {
      return false
    }
    setIsError(false)
    return true
  }

  function formatDate(date) {
    const pad = (number, length = 2) => String(number).padStart(length, '0');

    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());
    const milliseconds = pad(date.getMilliseconds(), 3);

    const timezoneOffset = -date.getTimezoneOffset();
    const sign = timezoneOffset >= 0 ? '+' : '-';
    const offsetHours = pad(Math.floor(Math.abs(timezoneOffset) / 60));
    const offsetMinutes = pad(Math.abs(timezoneOffset) % 60);

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${sign}${offsetHours}:${offsetMinutes}`;
  }

  const onSubmit = (data) => {
    const timestamp = new Date()
    const dataValue = {
      ...data,
      ts: formatDate(timestamp),
      data: {
        ip: "0.0.0.0",
        pct: 0,
        V: "0.00",
        C: "0.00",
        cap: "60.00",
        wf: "0.00",
        pf: "0.00",
        ff: "0.00",
        soc: "60.00",
        soh: "0.00",
        cycles: 0,
        cells: "0.00,0.00,0.00,0.00",
        Cell_T: "-61.40",
        Env_T: "0.00",
        fet_T1: "0.00",
        fet_T2: "0.00",
        model: "0",
        soft_ver: "1.0",
        hard_ver: "1.1",
        bms_sn: "0 ",
        ts: formatDate(timestamp),
        wlt: false
      }

    }
    addBattery(dataValue)
      .then((res) => {
        toggleModal()
      })
  }

  return (
    <ModalContainer
      title='Add New Battery Asset'
      modalOpen={modalOpen}
      height="auto"
      actionButtonText="Add Battery Asset"
      handleCloseModal={toggleModal}
      handleCancelClick={toggleModal}
      handleActionClick={handleSubmit}
    >
      {isError && <p style={{color: 'red'}}>Please meet all requirements. (*)</p>}
      <AddNewBatteryAssetForm ref={handleSubmitRef} />
    </ModalContainer>
  )
}
