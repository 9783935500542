import React, { useCallback, useEffect, useState } from "react";
import { CardContainer } from "../global/containers";
import {
  CustomDataGrid,
  CustomDataGridActionsDropdownMenu,
} from "../global/customTable";
import BikeTripModal from "../modals/bikeTripModal";
import { getElectricVehiclesTrips } from "../../services/electricVehicle";
import { formatDate } from "../../util/util";
import { useParams } from "react-router-dom";


export default function RecentBikeTripcards() {
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [bikeTripsList, setBikeTripsList] = useState([]);
  const [selectedTrip, setSelectedTrip] = useState({})
  const params = useParams()

  const bikeTripsColumns = [
    {
      field: "tripDate",
      headerName: "Trip Date",
      width: 100,
    },
    {
      field: "duration",
      headerName: "Duration",
      minWidth: 100,
      flex: 1,
      valueFormatter: (value) => `${value} Hrs`,
    },
    {
      field: "distance",
      headerName: "Distance",
      minWidth: 100,
      flex: 1,
      valueFormatter: (value) => `${value} Kms`,
    },
    {
      field: "energyConsumed",
      headerName: "Energy Consumed",
      minWidth: 100,
      flex: 1,
      valueFormatter: (value) => `${value} %`,
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <CustomDataGridActionsDropdownMenu
          width="100px"
          menuOptions={[
            {
              label: "View Trip",
              onClickHandler: () => {
                setModalOpen(true)
                setSelectedTrip(params.row)
              }
            },
          ]}
        />
      ),
    },
  ];

  const fetchBikeTrips = useCallback(async function () {
    setIsLoading(true);
    getElectricVehiclesTrips(params.id)
      .then((res) => {
        const trips = res.map((trip) => {
          return {
            ...trip,
            id: trip.trip_id,
            tripDate: formatDate(trip.trip_date),
            duration:  trip.total_time,
            distance: trip.distance,
            energyConsumed: trip.battery_percentage_used
          }
        })
        setBikeTripsList(trips)
      })
      .catch(err => {
        setBikeTripsList([])
      })
      .finally(() => setIsLoading(false))      
  }, []);

  const toggleModal = useCallback(
    async function () {
      setModalOpen(!modalOpen);
    },
    [modalOpen]
  );

  useEffect(() => {
    fetchBikeTrips();
  }, [fetchBikeTrips]);

  return (
    <CardContainer
      title="Recent Bike Trips"
      width="100%"
      height="100%"
      childrenContainerWidth="97%"
      extraSX={{ overflow: "Visible" }}
    >
      <CustomDataGrid
        rows={bikeTripsList}
        columns={bikeTripsColumns}
        height="100%"
        width="100%"
        isEmptyMessage="There are no trips completed on this bike."
        isLoading={isLoading}
      />
      <BikeTripModal toggleModal={toggleModal} modalOpen={modalOpen}  tripdata={selectedTrip}/>
    </CardContainer>
  );
}
