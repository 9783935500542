import React from 'react'
import { CardContainer, ColumnContainer, RowContainer } from '../global/containers'
import InfoDetails from '../global/infoText'
import { Avatar } from '@mui/material'
import { formatDate } from '../../util/util'
    
export default function RiderInfoCard ({riderInfo}) {
    const joinedDate = riderInfo?.date_joined ? formatDate(riderInfo?.date_joined) : "-"
  return (
    <CardContainer
            title='Basic information'
            width={{xs: '100%', lg: '57%'}}
            height={{xs: 'auto', lg: '95%'}}
            extraSX={{
                borderRadius: '10px',
            }}
        >
            <RowContainer
                justifyContent='space-between'
                padding='0px'
                marginBottom='0px'
                alignItems={{xs: 'flex-start',lg: 'center'}}
                extraSX={{
                    // gap: "10px",
                    flexDirection: {xs: 'column',lg: 'row'}
                }}
            >
                <ColumnContainer justifyContent='center' padding='0px' width={{xs: '100%',lg: '20%'}}>
                    <Avatar sx={{width: '126px', height: '126px'}}  />
                </ColumnContainer>
                <ColumnContainer padding='0px' width={{xs: '100%',lg: '37%'}} alignItems='left'>
                    <InfoDetails label={'Full Name'} value={`${riderInfo?.first_name ?? ""} ${riderInfo?.last_name ?? ""}`} />
                    <InfoDetails label={'Contact Number'} value={riderInfo?.phone_number} />
                </ColumnContainer>
                <ColumnContainer padding='0px' width={{xs: '100%',lg: '30%'}} alignItems='left'>
                    <InfoDetails width='80%' label={'Date Joined'} alignItems={{xs: 'flex-start',lg: 'end'}} value={joinedDate} />
                    <InfoDetails width='80%' label={'Home Address'} alignItems={{xs: 'flex-start',lg: 'end'}} value={riderInfo?.address} />
                </ColumnContainer>
            </RowContainer>

        </CardContainer>
  )
}
