import React, { useCallback, useState } from "react";
import { CardContainer, RowContainer } from "../global/containers";
import InfoDetails from "../global/infoText";
import { FloatingActionButton } from "../global/button";
import { Edit } from "@mui/icons-material";
import EditVehicleAssetModal from "../modals/editVehicleAssetModal";
import AddEditVehicleModal from '../modals/addEditVehicleModal';

const ECI_STATES = {
	idd: "idling",
	mv: "moving",
	off: "off",
};

export default function VehicleAssetBasicInfoCard({electricVehicleInfo = {}, setElectricVehicleInfo }) {
  const [modalOpen, setModalOpen] = useState(false);

	const toggleModal = useCallback(() => {
		setModalOpen((prev) => !prev);
	}, []);

	const {
		motor_model_number,
		registration_number,
		chasis_number,
		millage,
		eci,
	} = electricVehicleInfo;

	return (
		<CardContainer
			title="Basic Information"
			width={{ xs: "100%", lg: "33%" }}
			height="100%"
			extraSX={{ borderRadius: "10px" }}
			childrenContainerWidth="95%"
		>
			<RowContainer
				justifyContent="space-between"
				padding="0px"
				marginBottom="0px"
				width="100%"
			>
				<InfoDetails
					width="max-content"
					label="Model number"
					value={
						motor_model_number !== "motor model number"
							? motor_model_number
							: "-"
					}
				/>
				<InfoDetails
					width="max-content"
					label="Reg Number"
					value={registration_number}
					alignItems={{ xs: "flex-start", lg: "end" }}
				/>
			</RowContainer>
			<RowContainer
				justifyContent="space-between"
				padding="0px"
				marginBottom="0px"
				width="100%"
			>
				<InfoDetails
					label="Current Mileage"
					width="max-content"
					value={`${millage ?? "-"} KM`}
					alignItems="flex-start"
				/>
				<InfoDetails
					label="Current Status"
					width="max-content"
					value={ECI_STATES[eci?.state]}
					alignItems={{ xs: "flex-start", lg: "end" }}
				/>
			</RowContainer>

			<RowContainer
				justifyContent="space-between"
				padding="0px"
				marginBottom="0px"
			>
				<InfoDetails
					width="max-content"
					label="Chasis number"
					value={
						chasis_number !== "chasis number" ? chasis_number : "-"
					}
					alignItems={{ xs: "flex-start" }}
				/>

				<FloatingActionButton
					icon={<Edit color="secondary" />}
					onclick={toggleModal}
				/>
			</RowContainer>
			<AddEditVehicleModal
				toggleModal={toggleModal}
				modalOpen={modalOpen}
				vehicleAsset={electricVehicleInfo}
				setElectricVehicleInfo={setElectricVehicleInfo}
			/>
		</CardContainer>
	);
}
