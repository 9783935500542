import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { ColumnContainer, RowContainer } from "../components/global/containers";
import StationBasicInfoCard from "../components/cards/stationBasicInfoCard";
import EditChargeStation from "../components/modals/editChargeStation";
import SessionAttendantCard from "../components/cards/sessionAttendantCard";
import FulfilledDispatchOperationsCard from "../components/cards/fulfilledDispatchOperationsCard";
import StationCommisions from "../components/cards/stationCommisions";
import TopBar from "../components/global/topBar";
import { getChargeStationDetails } from "../services/chargeStationService";
import BackButton from "../components/global/backButton";

export default function ChargeStation() {
	const { id } = useParams();
	const [chargeStation, setChargeStation] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	const fetchChargeStationData = useCallback(async () => {
		setIsLoading(true);
		try {
			const data = await getChargeStationDetails(id);
			setChargeStation(data);
		} catch (error) {
			console.error("Error fetching charge station data:", error);
		} finally {
			setIsLoading(false);
		}
	}, [id]);

	useEffect(() => {
		fetchChargeStationData();
	}, [fetchChargeStationData]);

	const handleStationUpdate = useCallback((updatedStation) => {
		setChargeStation(updatedStation);
	}, []);

	useEffect(() => {}, [chargeStation]);

	if (isLoading) {
		return <div>Loading...</div>;
	}

	if (!chargeStation) {
		return <div>Error: Charge station not found</div>;
	}

	return (
		<ColumnContainer
			height="100%"
			marginBottom="0px"
			extraSX={{
				overflowY: "auto",
				gap: "10px",
			}}
		>
			<TopBar>
				<BackButton/>
			</TopBar>
			<RowContainer
				justifyContent="flex-start"
				height="330px"
				extraSX={{
					flexWrap: { xs: "wrap", lg: "nowrap" },
					gap: "10px",
				}}
			>
				<StationBasicInfoCard
					station={chargeStation}
					onStationUpdate={handleStationUpdate}
					EditModal={EditChargeStation}
				/>
				<SessionAttendantCard station={chargeStation} />
			</RowContainer>
			<RowContainer
				justifyContent="flex-start"
				height="60vh"
				extraSX={{
					flexWrap: { xs: "wrap", lg: "nowrap" },
					gap: "10px",
				}}
			>
				<FulfilledDispatchOperationsCard stationId={id} />
			</RowContainer>
			<RowContainer
				justifyContent="flex-start"
				height="auto"
				extraSX={{
					flexWrap: { xs: "wrap", lg: "nowrap" },
					gap: "10px",
				}}
			>
				<StationCommisions stationId={id} />
			</RowContainer>
		</ColumnContainer>
	);
}
