import React, { useCallback, useEffect, useState } from 'react';
import {
	CardContainer,
	ColumnContainer,
	RowContainer,
} from '../global/containers';
import StatItem from '../global/statItem';
import { getElectricVehiclesStats } from '../../services/electricVehicle';
import ActionableComponent from '../global/actionableComponent';
import { capitalizeFirstLetter } from '../../util/util';

export default function VehicleOverViewStatsCard() {
	const [stats, setStats] = useState();
	const [timeRange, setTimeRange] = useState('day');

	const fetchStats = useCallback(async function (data) {
		getElectricVehiclesStats(data).then((res) => {
			setStats(res);
		});
	}, []);

	const handleTimeRangeChange = (event) => {
		const newTimeRange = event.target.value;
		setTimeRange(newTimeRange);
		fetchStats({ timeRange: newTimeRange });
	};

	useEffect(() => {
		fetchStats();
	}, [fetchStats]);

	return (
		<CardContainer
			title="Vehicle Overview Stats"
			width={{ xs: '100%', lg: '33%' }}
			height="auto"
			childrenContainerWidth="97%"
			hasActionableComponent={true}
			actionableComponent={
				<ActionableComponent
					showTimeRange={true}
					timeRange={timeRange}
					handleTimeRangeChange={handleTimeRangeChange}
				/>
			}
		>
			<ColumnContainer
				width="100%"
				height="auto"
				padding="5px 10px"
				marginBottom="5px"
				extraSX={{
					gap: '10px',
				}}
			>
				<RowContainer
					justifyContent="space-between"
					alignItems="start"
					padding="0px"
					marginBottom="0px"
					responsive={false}
					extraSX={{
						gap: '10px',
					}}
				>
					<StatItem
						title={'Total Active Electric Vehicles '}
						value={stats?.active_vehicles ?? '-'}
					/>
					<StatItem
						title={'Average Vehicle Mileage'}
						value={`${stats?.average_distance ?? '-'} KMs`}
					/>
				</RowContainer>
				<RowContainer
					justifyContent="space-between"
					alignItems="start"
					padding="0px"
					marginBottom="0px"
					responsive={false}
					extraSX={{
						gap: '10px',
					}}
				>
					<StatItem
						title={'Avg Residual Power'}
						value={`${stats?.resudual_power ?? '-'} %`}
					/>
					<StatItem
						title={`Total ${capitalizeFirstLetter(
							timeRange
						)}’s Vehicles' Revenue`}
						value={`${stats?.months_revenue ?? '-'} Ksh`}
					/>
				</RowContainer>
			</ColumnContainer>
		</CardContainer>
	);
}
