import React, {
	forwardRef,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import { ColumnContainer, RowContainer } from "../global/containers";
import { FormInput, FormSelect } from "../global/form";

const AttendantForm = forwardRef((props, ref) => {
	const { initialData = {}, userType, isEditMode = false } = props;

	const user_typeRef = useRef();
	const first_nameRef = useRef();
	const last_nameRef = useRef();
	const phone_numberRef = useRef();
	const addressRef = useRef();
	const email_addressRef = useRef();

	const [errors, setErrors] = useState({});

	const validate = () => {
		const newErrors = {};
		const phoneRegex = /^(\+\d{1,3}[-\s]?)?\d{10,14}$/;
		if (!first_nameRef.current?.getState())
			newErrors.first_name = "First name is required.";
		if (!last_nameRef.current?.getState())
			newErrors.last_name = "Last name is required.";
		if (!user_typeRef.current?.getState())
			newErrors.user_type = "User type is required.";
		if (!phone_numberRef.current?.getState()) {
			newErrors.phone_number = "Phone number is required.";
		} else if (
			!phoneRegex.test(
				phone_numberRef.current.getState().replace(/\s|-/g, "")
			)
		) {
			newErrors.phone_number = "Invalid phone number format.";
		}
		if (
			email_addressRef.current?.getState() &&
			!/\S+@\S+\.\S+/.test(email_addressRef.current?.getState())
		) {
			newErrors.email_address = "Email address is invalid.";
		}
		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	useImperativeHandle(ref, () => ({
		getData: () => {
			if (validate()) {
				const data = {
					user_type: user_typeRef.current?.getState() || "",
					first_name: first_nameRef.current?.getState() || "",
					last_name: last_nameRef.current?.getState() || "",
					phone_number: phone_numberRef.current?.getState() || "",
					address: addressRef.current?.getState() || "",
					email_address: email_addressRef.current?.getState() || "",
				};
				if (isEditMode) {
					data.id = initialData.id;
				}
				return data;
			}
			return null;
		},
	}));

	const options =
		userType === "Swap Station Attendant"
			? ["Swap Station Franchisee", "Swap Station Attendant"]
			: ["Charge Station Franchisee", "Charge Station Attendant"];

	return (
		<ColumnContainer padding="0px" alignItems="start">
			<RowContainer
				justifyContent="space-between"
				marginBottom="10px"
				padding="0px"
			>
				<FormInput
					ref={first_nameRef}
					required
					label="First Name *"
					placeholder="Enter First Name"
					type="text"
					width="47%"
					value={
						isEditMode
							? initialData.name
								? initialData.name.split(" ")[0] || ""
								: ""
							: ""
					}
					error={errors.first_name}
				/>
				<FormInput
					ref={last_nameRef}
					required
					label="Last Name *"
					placeholder="Enter Last Name"
					type="text"
					width="47%"
					value={
						isEditMode
							? initialData.name
								? initialData.name.split(" ")[1] || ""
								: ""
							: ""
					}
					error={errors.last_name}
				/>
			</RowContainer>

			<RowContainer
				justifyContent="space-between"
				marginBottom="10px"
				padding="0px"
			>
				<FormInput
					ref={phone_numberRef}
					required
					label="Attendant Contact Number *"
					placeholder="Enter Contact Number"
					type="text"
					width="47%"
					value={isEditMode ? initialData.contact_number || "" : ""}
					error={errors.phone_number}
				/>
				<FormSelect
					ref={user_typeRef}
					required
					label="User Type *"
					width="47%"
					placeholder="Select User Type"
					options={options}
					value={
						isEditMode
							? initialData.user_type || userType
							: userType
					}
					error={errors.user_type}
				/>
			</RowContainer>

			<RowContainer
				justifyContent="space-between"
				marginBottom="10px"
				padding="0px"
			>
				<FormInput
					ref={email_addressRef}
					label="Attendant Contact Email"
					placeholder="Enter Contact Email"
					type="text"
					width="47%"
					value={isEditMode ? initialData.email_address || "" : ""}
					error={errors.email_address}
				/>
				<FormInput
					ref={addressRef}
					label="Address"
					placeholder="Enter Address"
					type="text"
					width="47%"
					value={isEditMode ? initialData.address || "" : ""}
					error={errors.address}
				/>
			</RowContainer>
		</ColumnContainer>
	);
});

export default AttendantForm;
