import React, { useEffect, useRef, useMemo } from 'react'
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import '../../index.css'
import MapboxDirections from '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions';
import "@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css";

export default function Map({markers, setSelectedMarker, setGetLocation}) {
  // TODO: clean this up
  let token = 'pk.eyJ1IjoiZW56aXRlY2giLCJhIjoiY2x4dnF3ZXFtMGJ5djJsc2F4MDFqc3JtbCJ9.bcGBDUXkmNPaiKmPDHhKzg'
  mapboxgl.accessToken = token;
  const mapContainer = useRef(null);
  const map = useRef(null);
  const lng = 36.81;
  const lat = -1.28;
  const zoom = 10;

  const effectCalled = useRef(false);

  const directions = useMemo(() => new MapboxDirections({
    accessToken: token,
    unit: 'metric',
    profile: 'mapbox/driving'
  }), [token]);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [lng, lat],
      zoom: zoom
    });
    // map.current.addControl(directions, 'top-left');
  }, [directions, lat ]);


  useEffect(() => {
    if (effectCalled.current) return;
    map.current.on('load', function () {
      directions.setOrigin([36.91916, -1.20328]);
      directions.setDestination([36.79606, -1.27673]);
      effectCalled.current = true;
    });
  }, [directions])
  
  // adding markers
  useEffect(() => {
    markers?.forEach((point) => {
      const el = document.createElement('div')
      el.className = point.type

      if (map.current !== null) {
        new mapboxgl.Marker({ element: el })
          .setLngLat([point.lon, point.lat])
          .setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<div>
            <br />
            <b>${point.asset}:</b> ${point.id} <br/>
            <b>Status: </b> ${point.status} <br/>
            </div>`))
          .addTo(map.current);
      }
    });

  }, [map, markers, setGetLocation, setSelectedMarker])


  return (
    <div style={{ border: '1px solid balck', width: '100%', height: '100%' }} ref={mapContainer} className="map-container" />
  )
}
