import React from "react";
import {
	ColumnContainer,
	ModalContainer,
	RowContainer,
} from "../global/containers";
import { Avatar, Typography, Box } from "@mui/material";
import InfoDetails from "../global/infoText";

export default function ViewStationAttendantModal({
	modalOpen,
	toggleModal,
	attendant = {},
}) {
	return (
		<ModalContainer
			title={`View Attendant: ${attendant?.name ?? "Unknown"}`}
			modalOpen={modalOpen}
			height="auto"
			type="in-actionable"
			handleCloseModal={toggleModal}
			handleCancelClick={toggleModal}
			handleActionClick={toggleModal}
		>
			<Box sx={{ padding: 2 }}>
				<RowContainer
					padding="0px"
					alignItems="flex-start"
					justifyContent="space-between"
					marginBottom={3}
				>
					<Avatar
						sx={{
							width: 120,
							height: 120,
							marginRight: 3,
							fontSize: "3rem",
						}}
						src={attendant?.avatarUrl}
					>
						{attendant?.name
							? attendant.name[0].toUpperCase()
							: "U"}
					</Avatar>
					<ColumnContainer padding="0px" width="calc(100% - 150px)">
						{attendant?.email_address && (
							<InfoDetails
								label="Contact Email"
								value={attendant.email_address}
								labelProps={{ sx: { fontWeight: "bold" } }}
								valueProps={{ sx: { wordBreak: "break-word" } }}
							/>
						)}
						<InfoDetails
							label="Contact Number"
							value={attendant?.contact_number ?? "--"}
							labelProps={{ sx: { fontWeight: "bold" } }}
						/>
						<InfoDetails
							label="Physical Address"
							value={attendant?.address ?? "--"}
							labelProps={{ sx: { fontWeight: "bold" } }}
							valueProps={{ sx: { wordBreak: "break-word" } }}
						/>
					</ColumnContainer>
				</RowContainer>
			</Box>
		</ModalContainer>
	);
}
