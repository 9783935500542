import React, { useCallback, useEffect, useState } from 'react'
import { CardContainer, RowContainer } from '../global/containers'
import { SearchInput } from '../global/form'
import { Add } from '@mui/icons-material'
import { CustomDataGrid, CustomDataGridActionsDropdownMenu } from '../global/customTable'
import { useNavigate } from 'react-router-dom'
import { deleteRider, listRiders } from '../../services/riderService'
import AddRiderModal from '../modals/addRiderModal'
import { FloatingActionButton } from '../global/button'
import EditRiderModal from '../modals/editRiderModal'
import ConfirmationModal from '../modals/confirmationModal'
import { issueSuccessMessage } from '../../util/util'


export default function RiderListCard() {

    const [riderList, setRiderList] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [AddmodalOpen, setAddModalOpen] = useState(false);
    const [editmodalOpen, setEditmodalOpen] = useState(false);
    const [riderToEdit, setRiderToEdit] = useState({})
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [riderToDelete, setDeleteRider] = useState({})

    const navigator = useNavigate()

    const riderListColumns = [
        {
            field: "riderName",
            headerName: "Rider Name",
            width: 100,
            flex: 1,
        },
        {
            field: "bikeRegNumber",
            headerName: "Bike Reg Number",
            minWidth: 120,
            flex: 0.5,
        },
        {
            field: "phoneNumber",
            headerName: "Phone Number",
            minWidth: 100,
            flex: 1,
        },
        {
            field: "paymentMethods",
            headerName: "Payment Method",
            minWidth: 100,
            flex: 1,
        },
        {
            field: "latestPayment",
            headerName: "Latest Payment",
            minWidth: 100,
            flex: 1,
            valueFormatter: (value) => `${value} KES`,
        },
        {
            field: "actions",
            headerName: "Actions",
            minWidth: 70,
            flex: 1,
            renderCell: (params) => (
                <CustomDataGridActionsDropdownMenu
                    menuOptions={[
                        {
                            label: "View",
                            onClickHandler: () => navigator(`/riders/${params.row.id}`),
                        },
                        {
                            label: "Edit",
                            onClickHandler: () => editRiderInfo(params.row),
                        },
                        {
                            label: "Delete",
                            onClickHandler: () => handleDelete(params.row),
                        }
                    ]}
                />
            ),
        },
    ];

    const toggleDeleteModal = useCallback(
        async function () {
          setDeleteModalOpen(!deleteModalOpen);
        },
        [deleteModalOpen]
      );

    const editRiderInfo = (data) => {
        setRiderToEdit(data);
        toggleModalEditModal();
    }

    const handleDelete = (data) => {
        setDeleteRider(data)
        toggleDeleteModal()
    }

    const riderDelete = () => {
        deleteRider(riderToDelete.id)
            .then((res) => {
                setRiderList(riderList.filter((value) => value.id !== riderToDelete.id))
                setFilteredData(filteredData.filter((value) => value.id !== riderToDelete.id))
                issueSuccessMessage("Rider deleted succesfully");
                toggleDeleteModal()
            })
    }

    const fetchRideList = useCallback(async function () {
        setIsLoading(true);
        listRiders()
            .then((res) => {

                const formatedData = res.map((rider) => {
                    const riderName = `${rider?.first_name}  ${rider?.last_name}`
                    return {
                        id: rider.id,
                        riderName: riderName,
                        bikeRegNumber: rider.bike_reg,
                        phoneNumber: rider.phone_number,
                        paymentMethods: rider.payment_method,
                        latestPayment: rider.latest_payment,
                        ...rider
                    }
                })
                setRiderList(formatedData)
                setFilteredData(formatedData);

            })
            .catch((err) => {
                setRiderList([])
            })
            .finally(() => setIsLoading(false))
    }, []);

    useEffect(() => {
        fetchRideList();
    }, [fetchRideList,AddmodalOpen,editmodalOpen]);

    const toggleModalAddModal = useCallback(
        async function () {
            setAddModalOpen(!AddmodalOpen);
        },
        [AddmodalOpen]
    );

    const toggleModalEditModal = useCallback(
        async function () {
            setEditmodalOpen(!editmodalOpen);
        },
        [editmodalOpen]
    );



    const filterData = (ev) => {
        const params = ev.target.value.toLocaleLowerCase();   
    
        if (params === "") {
            return setFilteredData(riderList);
        }

        const filters = rider => rider?.first_name.toLowerCase().includes(params) || rider?.last_name.toLowerCase().includes(params) || rider?.bike_reg.toLowerCase().includes(params)
        const filteredData = riderList.filter(filters);
        setFilteredData(filteredData);
    }


    return (
        <CardContainer
            title="All Riders"
            type="actionable"
            hasActionableComponent={true}
            height="75vh"
            width="100%"
            childrenContainerWidth="98%"
            actionableComponent={
                <RowContainer
                    width={{ xs: "80%", sm: "50%", lg: "30%" }}
                    padding="0px"
                    marginBottom="0px"
                >
                    <FloatingActionButton
                        icon={<Add color="secondary" />}
                        onclick={() => toggleModalAddModal()}
                    />
                    <SearchInput
                        placeholder={"Search"}
                        filter={filterData}
                        type="text"
                        width={{ xs: "100%", md: "60%", lg: "70%" }}
                        backgroundColor="#FFFFFF"
                    />
                </RowContainer>
            }
        >
            <CustomDataGrid
                isLoading={isLoading}
                columns={riderListColumns}
                rows={filteredData}
                height="65vh"
                width="100%"
                isEmptyMessage="There are no rider assets to show"
            />
            <AddRiderModal toggleModal={toggleModalAddModal} modalOpen={AddmodalOpen} />
            <EditRiderModal toggleModal={toggleModalEditModal} modalOpen={editmodalOpen} riderInfo={riderToEdit} />
            <ConfirmationModal
				modalOpen={deleteModalOpen}
				toggleModal={toggleDeleteModal}
				onConfirmation={riderDelete}
				itemType="Rider"
				itemName={ riderToDelete?.first_name + " " + riderToDelete?.last_name }
                confirmType="delete"
			/>
        </CardContainer>
    )
}
