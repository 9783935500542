import { ADD_RIDER, DEL_RIDER, EDT_RIDER, RIDER_DETAILS, RIDER_LIST, RIDER_STATS, RIDER_TRANSACTION } from "../constants/url.constants";
import { handleErrorResponse } from "../util/util";
import { serviceAPI } from "./service";

export const listRiders = async () => {
	try {
		const response = await serviceAPI.get(RIDER_LIST);
		return response.data;
	} catch (error) {
		handleErrorResponse(error);
        throw error
	}
};

export const riderDetails = async (id) => {
	try {
		const response = await serviceAPI.get(RIDER_DETAILS + id);
		return response.data;
	} catch (error) {
		handleErrorResponse(error);
        throw error
	}
};

export const riderTransactions = async (id) => {
	try {
		const response = await serviceAPI.post(RIDER_TRANSACTION, {
			user_id : id
		});
		return response.data;
	} catch (error) {
		handleErrorResponse(error);
        throw error
	}
};

export const addRider = async (data) => {
	try {
		const response = await serviceAPI.post(ADD_RIDER, data);
		return response.data;
	} catch (error) {
		handleErrorResponse(error);
        throw error
	}
};

export const riderStats = async (id) => {
	try {
		const response = await serviceAPI.get(RIDER_STATS);
		return response.data;
	} catch (error) {
		handleErrorResponse(error);
        throw error
	}
};


export const editRider = async (data, id) => {
	try {
		const response = await serviceAPI.put(EDT_RIDER + id, data);
		return response.data;
	} catch (error) {
		handleErrorResponse(error);
        throw error
	}
};

export const deleteRider = async (id) => {
	try {
		const response = await serviceAPI.delete(DEL_RIDER + id);
		return response.data;
	} catch (error) {
		handleErrorResponse(error);
        throw error
	}
};
