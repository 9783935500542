import { createTheme } from "@mui/material/styles";

const enzi_theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#00FCFF",
      light: "#EBFFFF",
    },
    secondary: {
      main: "#002152",
      light: "#ADCEFF",
    },
    success: {
      main: "#2A7948",
    },
    error: {
      main: "#DC2626",
      background: "#DC26261A",
    },
    warning: {
      main: "#ffb74d",
      background: "#ffefb2",
    },
    common: {
      white: "#FFFFFF",
      black: "#000000",
    },
    background: {
      default: "#F3F3F3",
      paper: "#DDDDDD",
    },
  },
  // shadows: {
  //   // blank
  // },
  typography: {
    fontFamily: "Figtree",
    fontWeightBold: 900,
    fontWeightMedium: 600,
    fontWeightRegular: 400,
    h1: {
      fontSize: "2rem",
      fontWeight: 800,
      color: "#000",
    },
    h2: {
      fontSize: "1.571rem",
      fontWeight: 600,
      color: "#000",
    },
    h3: {
      fontSize: "1.429rem",
      fontWeight: 600,
      color: "#000",
    },
    h4: {
      fontSize: "1.286rem",
      fontWeight: 600,
      color: "#000",
    },
    h5: {
      fontSize: "1.143rem",
      fontWeight: 600,
      color: "#000",
    },
    h6: {
      fontSize: "1rem",
      fontWeight: 600,
      color: "#000",
    },
    subtitle1: {
      fontSize: "1.143rem",
      fontWeight: 600,
      color: "#000",
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 4000,
      color: "#000",
    },
    body2: {
      fontSize: "0.857rem",
      fontWeight: 400,
      color: "#000",
    },
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          height: "auto",
        },
        listbox: {
          height: "150px",
        },
        paper: {
          minHeight: "150px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 700,
        },
        contained: {
          borderRadius: "10px",
          padding: "10px 20px",
        },
      },
    },
  },
});

export default enzi_theme;
